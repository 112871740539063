@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue/src/index.scss';
@import 'src/css/breakpoints.scss';
@import 'fonts.scss';
@import 'variables.scss';
@import 'svg.scss';
@import 'components/styles.scss';
@import "dhtmlxscheduler_material.css";
// from ~dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css
@import "animations.scss";

html {
  overflow-y: hidden;
}

body {
  overflow-y: hidden;
  font-family: $font-family-body;
  color: $grey-100;
}

html,
body,
#q-app {
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: lighten($grey-light, 10%);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $grey-light;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $green;
}

.text-black,
  // .general-notification has to always be black, override system class .text-white which also has !important
.general-notification.text-black {
  color: $grey-100 !important;
}

.text-blue {
  color: $blue !important;
}

.btn-white {
  border: 1px solid $grey-60;
  background: $white;
}

.btn-white-secondary {
  border: 1px solid $grey-70;
  background: $white;
}

.btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover {
  background-color: $primary-hover;
}

.btn-white:focus,
.btn-white.focus {
  background: $white !important;
}

.btn-secondary {
  color: $grey-90;
  background: $grey-50;
  border: none;
}

.btn-secondary:hover {
  color: $grey-90;
  background: rgba(5, 76, 219, 0.1);
}

.btn-secondary:disabled {
  color: $grey-90;
  background: rgba(244, 244, 246, 1);
}

.btn-tertiary {
  color: $grey-90 !important;
  border: none !important;
  font-weight: 500;
}

.btn-tertiary:disabled {
  color: $grey-90 !important;
  opacity: 0.5;
}

.btn-tertiary:hover {
  color: $grey-100 !important;
  border: none !important;
}

.btn-contact-prev-next {
  width: 28px;
  height: 28px;
  padding: 0 4px !important;
  line-height: 1;
  border-radius: 6px;

  i {
    font-size: 16px;
  }
}

.btn-contact-list-navigation {
  position: absolute;
  left: 300px;
}

.btn-inbox-list-navigation {
  position: absolute;
  left: 197px;
  outline: none !important;
}

.q-separator--horizontal-inset {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.row {
  &.q-field,
  &.q-field__control-container,
  &.q-field__control,
  &.q-field__control-container,
  &.q-notification__wrapper,
  &.q-notification__content,
  &.q-btn__wrapper,
  &.q-btn__content,
  &.q-avatar__content,
  &.q-item:not(.default),
  &.q-field__native,
  &.q-chip__content,
  .q-field__inner {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }

  &.q-input {
    padding: 0 0;

    .q-field__prepend {
      height: auto;

      & + .q-field__control-container {
        .q-field__native {
          padding-left: 10px !important;
        }
      }
    }

    .q-field__control {
      height: 100%;

      .q-field__native {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &.q-chip {
    padding-left: 5.6px !important;
    padding-right: 5.6px !important;
    margin-left: 0 !important;
    margin-right: 5px !important;
  }

  &.q-notification {
    margin-left: 0;
    margin-right: 15px;
  }

  .q-field__native,
  .q-field:not(.q-field--float) .q-field__label,
  .q-item__label {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .q-field__native.q-px-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .q-field__native.q-pa-md {
    padding: 16px 16px !important;
  }

  .q-item__section .q-item__label {
    padding: inherit !important;
  }

  .q-field.q-field--float .q-field__label {
    padding-right: 20px;
    padding-left: 20px;
  }

  .q-field__append {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 8px;

    &.q-field__marginal {
      height: auto !important;
    }
  }

  .q-field__bottom {
    padding-top: 3px;
  }

  .q-field__messages {
    flex: auto !important;
    width: 100% !important;
  }
}

.q-field--outlined {
  &.q-field--highlighted {
    &.required {
      .q-field__control:after {
        border-color: $danger
      }
    }
  }

  &.q-field--with-bottom {
    padding-bottom: 15px;
    height: initial !important;

    .q-field__bottom {
      min-height: 15px;
      padding-left: 0;

      .q-field__messages {
        padding-left: 10px;
      }
    }
  }
}

.q-notifications {
  .q-notification__message.col {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blink {
  animation: fade 1500ms infinite;
  -webkit-animation: fade 1500ms infinite;
}

.guest {
  .page-container {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
  }

  & .main-content {
    padding: 0 !important;
  }
}

.default-bg {
  background-color: $dark;
}

.banner-font {
  font-family: 'Poppins', sans-serif;
}

.text-banner-title {
  font-size: 2.47rem;
  font-weight: bold;
  line-height: 1.2;
}

.text-banner-subtitle {
  font-size: 1.25rem;
  font-weight: 300;
}

.ai-bg {
  background-image: url("../assets/pattern2_overlay.svg"), url("../assets/pattern2.jpg");
  background-repeat: no-repeat;
  background-size: 49vw, cover;
  background-position: center;
}

.xmas-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url("../assets/xmas-tree.jpg");
  background-color: #01132B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 992px) {
  .page-title {
    font-size: 22px !important;
  }

  .q-drawer--right.q-drawer--bordered {
    border-left: 0px !important;
  }
}

@media (min-width: 605px) {
  .contact-details-container {
    margin-left: 15px;
    margin-top: 15px;
  }
}

@media (min-width: 605px) and (max-width: 991px) {
  .paginated {
    .table-pagination {
      button {
        min-width: 2em !important;
      }

      .q-btn__wrapper {
        padding: 0 0 !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .dashboard {
    header {
      left: 64px;
    }

    .sidebar-wrapper,
    .sidebar-wrapper > aside {
      display: block !important;
    }

    .sidebar-wrapper {
      width: 64px;
    }

    &.sidebar-expanded .sidebar-wrapper {
      width: 250px;
    }

    .sidebar-wrapper > aside {
      transform: translateX(0px) !important;
      visibility: visible !important;
    }
  }
}

@media (max-width: 575px) {
  .page-header {
    left: 0 !important;
  }
  .sidebar-active {
    .page-container {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 480px) {
  .sidebar-wrapper-sm {
    max-width: 38px;
  }
}

.rectangle {
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: $white;

  &:not(.q-field--highlighted) {
    border: 1px solid $grey-light4;
  }

  input {
    color: #202125;
    font-family: $font-family-body;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.35px;
    line-height: 19px;
  }

  .q-field__label {
    opacity: 0.9;
    color: $grey-dark;
    font-family: $font-family-body;
    font-size: $f-size-body-xs;
    letter-spacing: 0;
    line-height: 16px;
  }

  .q-field__inner .q-field__control:before {
    border: none !important;
  }

  .q-field__inner .q-field__control:after {
    border-radius: 10px;
    top: -1px;
    height: 59px;
  }
}

.checkbox {
  input {
    cursor: pointer;
  }

  .q-checkbox__inner .q-checkbox__bg {
    border-radius: 4px;
  }

  .q-checkbox__inner:not(.text-positive) .q-checkbox__bg {
    border: 1px solid $grey-light4;
  }

  .q-checkbox__inner.text-positive .q-checkbox__bg {
    border: 1px solid $positive;
  }
}

.title {
  color: #202125;
  font-family: $font-family-body;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 36px;
}

.description {
  color: $grey-light3;
  font-family: $font-family-body;
  font-size: 14px;
  letter-spacing: 0.19px;
  line-height: 22px;
  text-align: center;
}

.description-sm {
  color: $grey-mid;
  font-family: $font-family-body;
  font-size: $f-size-body-xs;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;

  a {
    color: $positive;
  }
}

.text-regular {
  text-transform: none !important;
}

.small-text {
  color: #202125;
  font-family: $font-family-body;
  font-size: $f-size-body-xs;
  letter-spacing: 0.3px;
  line-height: 16px;

  .status-label {
    margin-top: -15px;
  }
}

.xs-text {
  font-family: $font-family-body;
  font-size: 10px;
  letter-spacing: 0.25px;
  line-height: 13px;
}

.link {
  height: 18px;
  width: 107px;
  color: $blue;
  font-family: $font-family-body;
  font-size: $f-size-body-sm;
  letter-spacing: 0;
  line-height: 18px;
}

.link-only {
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

.mark-as {
  color: $blue;
  font-family: $font-family-body;
  font-size: $f-size-body-sm;
  letter-spacing: -0.0025em;
  line-height: 16px;
}

.tag-text {
  font-weight: normal;
  font-size: $f-size-body-sm;
  letter-spacing: -0.0025em;
  line-height: 16px;
}

.details-contact-tags {
  .tag-text {
    max-width: 177px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.tag-items {
  padding: 1px 5px;
  border-radius: 5px !important;
}

.generic-multi-select {
  .list-wrapper {
    .is-dot {
      top: 40%;
      position: absolute;
    }
  }

  .edit-wrapper {
    .dot-wrapper {
      height: 16px;
      //top: 48%;
      //position: absolute;
    }

    & > .q-field__inner > .q-field__control {
      &:before {
        border: 0;
      }

      &:after {
        display: none;
      }
    }
  }

  .q-field > .q-field {
    .tag-items {
      max-width: 230px;
    }

    .tag-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .dropdown-select {
    max-height: 160px;
    height: 100%;

    .tag-items {
      .tag-text {
        padding-left: 4px;
      }
    }

    .select-option {
      &:hover {
        background-color: $grey-50;
        border-radius: 6px;
      }
    }

    .select-group {
      .tag-text {
        @include font-size($f-size-h5, 600, true);
      }
    }

    .tag-items {
      .is-dot {
        top: 43%;
        left: -8px;
      }
    }
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.remember-me {
  color: $grey-dark;
  font-family: $font-family-body;
  font-size: $f-size-body-sm;
  letter-spacing: 0;
  line-height: 18px;
}

.stats-page-btn {
  padding: 11px 29px 11px 28px !important;
  line-height: 18px;

  .q-btn__wrapper {
    padding: 0;
    min-height: auto !important;

    .q-btn__content {
      align-items: center;
      flex: auto;
    }
  }
}

.button {
  color: $white;
  font-family: $font-family-body;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px;

  .q-btn__wrapper,
  .q-btn__content {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .q-btn__wrapper:before {
    box-shadow: none;
  }
}

.thank-you-container {
  width: 380px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 2px 4px 20px 0 rgba(21, 22, 63, 0.2);

  .message {
    color: $grey-dark;
    font-family: $font-family-body;
    font-size: $f-size-body-sm;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.rounded-icon-opaque {
  border-radius: 32px;
  box-shadow: 0 0 0 8px rgba(0, 191, 74, 0.2);
}

.page-header {
  border-bottom: 1px solid #EBEBEB;
  width: auto;
  height: 62px;
  z-index: 4;

  & .q-item {
    padding: 0;
  }

  & .q-item > .q-btn {
    @extend .no-q-btn-focus;
  }

  & .q-icon {
    width: auto;
    height: auto;
  }

  & > div:first-child {
    min-width: 0;
    flex: 1;

    & > h1 {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.page-sidebar {
  height: 100%;
  background-color: $dark;

  .app-logo {
    -webkit-appearance: none;

    .q-btn__wrapper:before {
      box-shadow: none;
    }
  }

  .nav-icons {
    width: 48px;
    height: 52px;
    -webkit-appearance: none;

    &:hover {
      text-decoration: none;
    }

    @extend .no-q-btn-focus;

    .q-icon {
      width: auto;
      height: auto;
    }

    &.custom-rotate-90 .q-icon {
      transform: rotate(90deg);
      width: 24px !important;
      height: 24px !important;
    }

    &.bordered-menu-icon .q-icon {
      border: 1px solid #9797AE;
      border-radius: 6px;
    }

    .q-btn__wrapper:before {
      box-shadow: none;
    }
  }

  .q-badge--floating {
    top: 8px;
    right: 15px;
  }
}

.sidebar-expanded .page-sidebar .app-logo {
  .q-icon {
    width: auto !important;
  }
}

.sidebar-expanded .app-logo {
  padding-left: 17px !important;
}

.page-footer {
  .tab-icons {
    padding-top: 8px !important;
    padding-bottom: 2px !important;

    .tab-icon {
      display: flex;
      align-items: center;
      height: 20px;
      margin-bottom: 7px;
      color: $grey-30;

      .q-badge--floating {
        top: 2px;
        right: 14px;
      }
    }

    font-family: $font-family-body;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
  }

  .q-tab {
    padding: 0 0 0 0;
    text-decoration: none;
  }

  .q-tabs {
    margin-left: 0;
    margin-right: 0;
  }

  .separator {
    width: 134px;
  }

  .dialpad {
    .q-tab__icon {
      width: 40px;
      height: 40px;
      color: $white;
    }
  }

  .q-router-link--active {
    &.q-tab--inactive .q-tab__content:not(.tab-inactive),
    .q-tab__content.tab-inactive {
      color: $grey-30
    }
  }

  .q-tab__content.tab-active {
    color: $blue-90;
  }
}

.sidebar-wrapper,
.sidebar-wrapper > aside {
  max-width: 64px;
  position: absolute !important;
  top: 0;
}

.sidebar-expanded .sidebar-wrapper,
.sidebar-expanded .sidebar-wrapper > aside {
  max-width: 250px;
}

.separator-blur {
  opacity: 0.2;
}

.page-icon,
.mobile-menu {
  display: none;
}

.profile-menu {
  padding: 0 5px !important;
}

.no-results {
  padding: 0 10px;
}

.avatar {
  border-radius: 17px;
  width: 34px;
  height: 34px;
  display: inline-block;
  font-size: $f-size-body-sm;

  & > span {
    vertical-align: middle;
  }
}

.inactive {
  color: $inactive;
}

.b-compact-dropdown-button {
  height: 24px;

  button {
    padding: 0 0.5rem;
    font-size: 14px;
  }

  .dropdown-item {
    font-size: 14px;
  }
}

.badge-tag {
  background: transparent;
  font-weight: 500;
  border: 1px solid $grey-60;
  margin-right: 5px;
}

.badge-tag-primary {
  color: $primary;
}

.btn-bg-transparent {
  background: transparent;
}

.btn-b-0 {
  border: none;
}

.dropdown.bg-transparent > button.dropdown-toggle {
  background: transparent;
}

.dropdown.b-0 > button.dropdown-toggle {
  border: none;
}

.dropdown.bg-transparent > button.dropdown-toggle:hover,
.dropdown.b-0 > button.dropdown-toggle:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.w-px__250 {
  width: 250px;
}

.popover {
  font-family: $font-family-body !important;
}

.ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.badge-tag {
  font-size: $f-size-body-sm;
  max-width: 250px;
  padding-right: 8px;
  text-align: left;
}

.custom-link {
  font-size: $f-size-body-sm;
  cursor: pointer;
  color: $blue;

  i {
    margin-top: -1px;
  }
}

.contact-edit-popovers {
  left: -298px !important;
  width: 300px;
}

.message {
  display: flex;
  justify-content: flex-start;

  .text-xxs {
    font-size: $f-size-body-xs;
    color: $grey-90 !important;
  }

  .contact-audit {
    font-size: $f-size-body-xs;
    color: $grey-90 !important;
  }

  &:hover {
    .mark-as {
      display: block !important;
    }
  }
}

.q-custom-select {
  .q-field__control-container {
    border: 1px solid $primary;
    border-radius: 5px;
  }

  .q-field__append {
    display: none;
  }

  .q-chip {
    height: auto;
    border: 1px solid $grey-60;
    border-radius: 6px;
    background: transparent;
  }
}

.q-menu {
  max-height: 50vh !important;
  font-size: 90%;

  .q-virtual-scroll__content {
    padding: 5px;

    .q-item.q-item-type {
      padding: 5px;
      border-radius: 6px;
      //max-width: 240px !important;
      min-height: 32px !important;
      margin: 2px 0;

      .q-item__label--header {
        padding: 4px !important;
      }
    }

    .q-item__label {
      padding-left: 0 !important;
    }

    .q-item__section--avatar {
      min-width: 10px !important;
    }
  }
}

i.option-separator {
  font-size: 6px;
  top: -1px;
  position: relative;
}

.inline-select {
  padding-top: 10px;
}

.inline-select.q-select--without-input {
  padding-top: 0;
}

.inline-select.q-select--with-input {
  padding-top: 0;
  min-width: 245px;

  .q-field__input {
    display: none;
  }

  .q-field__native {
    margin-top: 0;
  }
}

.inline-select.q-field--disabled .q-field__control > div {
  opacity: 1 !important;
}

.inline-select.show-caret__always {
  .q-field__marginal {
    opacity: 1 !important;
  }
}

.inline-select.caret__grey-90 {
  .q-select__dropdown-icon {
    color: $grey-90;
  }
}

.inline-select {
  margin-top: 0;
  width: 100%;

  .q-field__control,
  .q-field__native {
    height: 29px !important;
    min-height: 1px !important;
  }

  .q-field__native, .q-field__label, .q-field__input {
    padding-left: 0 !important;
  }

  .q-field__marginal {
    height: 29px !important;
    opacity: 0;
  }

  .q-field__control:before {
    border-bottom: none !important;
  }

  .q-select__dropdown-icon {
    color: $primary;
  }

  .q-anchor--skip {
    font-size: 100% !important;
    color: $primary !important;
  }

  //.selected-option-container {
  //  position: relative;
  //max-width: 200px;
  //text-overflow: ellipsis;
  //overflow: hidden;
  //white-space: nowrap;

  .selected-option-separator {
    color: $grey-70;
    font-size: 40%;
    position: absolute;
    top: 13px;
  }

  .selected-option-title {
    color: $grey-70;
    font-size: $f-size-body-sm;
    font-weight: 500;
    margin-left: 10px;
    position: relative;
    top: -4px;
  }

  .selected-option {
    color: $grey-100;
    font-size: $f-size-body-sm;
    font-weight: 500;

    max-width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block !important;
    margin-top: -1px;
  }

  .q-field__input {
    font-size: $f-size-body-sm;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin-top: -4px;
  }
}

//}

.inline-select:focus-within {
  .q-field__control:before {
    border-bottom: 0.5px solid $primary !important;
  }

  .q-field__marginal {
    opacity: 1;
  }
}

.inline-select.q-select--with-input {
  .q-field__input {
    display: none;
  }
}

.inline-select.q-select--with-input:focus-within {
  .q-field__input {
    display: block;
  }
}

.inline-select:hover {
  .q-field__control:before {
    border-bottom: 0.5px solid $primary !important;
  }

  .q-field__marginal {
    opacity: 1;
  }
}

.inline-input:focus-within {
  .q-field__append.q-field__marginal {
    opacity: 0;
  }
}

.inline-input {
  margin-top: -2px;
  width: 100%;

  .q-field__native {
    padding-left: 0 !important;
    height: 29px !important;
  }

  .q-field__marginal {
    height: 29px !important;
    opacity: 0;
  }

  .q-field__control:before {
    border-bottom: none !important;
  }

  .input-edit-icon {
    color: $primary;
  }

  .q-anchor--skip {
    font-size: 100% !important;
    color: $primary !important;
  }
}

.inline-input:focus-within {
  .q-field__control:before {
    border-bottom: 0.5px solid $primary !important;
  }

  .q-field__marginal {
    opacity: 1;
  }
}

.inline-input:hover {
  .q-field__control:before {
    border-bottom: 0.5px solid $primary !important;
  }

  .q-field__marginal {
    opacity: 1;
  }
}

.q-input-borderless {
  textarea {
    min-height: 500px;
  }

  .q-field__control:before {
    border: none !important;
  }
}

.fax-file-wrapper {
  height: 80px;
  width: 100%;
  border-radius: 4px;
  padding: 10px !important;
  border: 1px dashed $grey-70;

  #fileInput {
    position: relative;
  }

  .uploader-label {
    position: absolute;
    width: 100%;
    top: 3px;
    font-size: 14px;
    font-weight: 500;
  }

  .uploading-label {
    position: absolute;
    top: 28px;
    width: 100%;

    p {
      font-size: $f-size-body-xs;
    }
  }

  form {
    height: 6vh;
  }

  #fileInput.dragdrop .custom-file,
  #fileInput.dragdrop .custom-file-input {
    height: 100px;
  }

  #fileInput.dragdrop .custom-file-label {
    height: 80px;
    line-height: 90px;
    text-align: center;
    padding: 0;
    border: 1px dashed $grey-70;
    background: transparent;
  }

  #fileInput.dragdrop .custom-file-label span {
    visibility: hidden;
  }

  #fileInput.dragdrop label.custom-file-label:after {
    display: none !important;
  }
}

.q-field__native.q-input-pl-0 {
  padding-left: 0 !important;
}

.q-field__native.q-input-pr-0 {
  padding-right: 0 !important;
}

.q-field__native.q-input-pl-4 {
  padding-left: 4px !important;
}

.q-field__native.q-input-pr-4 {
  padding-right: 4px !important;
}

.q-input-composer {
  max-height: 31vh;

  ::-webkit-scrollbar {
    display: none;
  }
}

.q-input-composer textarea {
  resize: none !important;
  max-height: 31vh;
  overflow-y: auto !important;
}

.handle-whitespace {
  white-space: pre-line;
}

.break-word {
  word-break: break-word;
}

.q-input-composer .q-field__native,
.q-input-composer .q-field__control {
  min-height: 18px;
}

.contact-activity.activity-expanded {
  background: $white !important;
}

.contact-activity {
  transition: transform 270ms cubic-bezier(0.79, 0.14, 0.15, 0.86) 0ms, border-color 270ms linear 0ms;

  .q-item {
    min-height: 41px;
  }

  &.activity-expanded {
    &.q-expansion-item {
      border: 1px solid $grey-60;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &.q-expansion-item,
    &.q-expansion-item .q-expansion-item__container > .q-item,
    &.q-expansion-item .q-expansion-item__container > .q-item > .q-focus-helper {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &.q-expansion-item .q-expansion-item__container > .q-item {
      border-bottom: 1px solid $grey-60;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      width: 100%;
    }
  }

  &.q-expansion-item--collapsed {
    .q-expansion-item__container {
      & > .q-item {
        background: linear-gradient(0deg, $white, $white), rgba(37, 110, 255, 0.1);
        box-sizing: border-box;
        border-radius: 12px;
        border-bottom: 1px solid transparent;
      }
    }
  }

  &.expand-animation-finished {
    &.q-expansion-item {
      border-radius: 12px;
    }
  }

  &.activity-unexpanded {
    & > .q-expansion-item__container > .q-item {
      border: 1px solid $grey-60;
    }
  }
}

.communication-info {
  display: block;
  font-size: 14px;
  line-height: 18px;

  .communication-header {
    display: flex;
    align-items: center;
  }

  .form-control-label {
    color: $grey-90;
    font-size: $f-size-body-xs;
    line-height: 15px;
    padding-left: 0px;
    vertical-align: top;
    white-space: initial;
  }

  .q-item__section--side {
    padding-left: 0px;
    padding-right: 0.5rem !important;
  }

  .more-details {
    color: $blue;
  }
}

.contact-activity-sender {
  border: 1px solid rgba(47, 128, 237, 0.25);
}

.font-weight-light-bold {
  font-weight: 500;
}

.play-button {
  width: 26px;
  height: 26px;
  background: $blue;
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 10.83px;
    color: $white;
  }
}

.waveform-container {
  z-index: 0;
}

.waveform-selector {
  font-size: $f-size-body-xxs;
  line-height: 13px;
  letter-spacing: 0.004em;
  color: $grey-90;
}

.waveform-timeline {
  font-size: $f-size-body-xxs;
  line-height: 13px;
  letter-spacing: 0.004em;
  color: $grey-90;
}

.waveform-controls {
  height: 24px;
  overflow: hidden;
}

.audio-player {
  background: $grey-50;
  border-radius: 8px;
  min-height: 42px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.border-half-rounded {
  border-radius: 6px;

  &.q-field--rounded {
    .q-field__control {
      border-radius: 6px;
    }
  }
}

.border-rounded {
  border-radius: 8px;
}

.border-full-rounded {
  border-radius: 14px;
}

.sms-activity {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 11.2px 21.5px;
}

.message-composer-dropdown {
  .dropdown-menu {
    font-size: $f-size-body-sm;
  }
}

.q-btn-toggle {
  display: flex;
  border-radius: 6px !important;

  .q-btn__wrapper {
    padding: 0;
  }

  button {
    font-size: $f-size-body-sm;
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
  }

  button.text-primary {
    color: #000 !important;
  }

  button:nth-child(3) {
    border-left: 1px solid $grey-70;
    border-right: 1px solid $grey-70;
  }
}

.custom-multi-select.always-open {
  .multiselect__content-wrapper {
    display: block !important;
    position: relative;
  }
}

.custom-multi-select.multiselect--above.multiselect--active .multiselect__current,
.custom-multi-select.multiselect--above.multiselect--active .multiselect__input,
.custom-multi-select.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.custom-multi-select {
  .multiselect__content-wrapper {
    margin-top: 4px;
    position: absolute;
    //border: none !important;
  }

  .multiselect__tags {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .multiselect__option {
    padding: 5px !important;
    border-radius: 8px;
    min-height: 28px !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .multiselect__option--highlight {
    background: $grey-50;
    color: $grey-100;
    border-radius: 8px;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: $grey-50;
    color: $grey-100;
  }

  .multiselect__placeholder {
    margin-bottom: 8px !important;
    padding-top: 0 !important;
    font-size: $f-size-body-sm;
  }

  .multiselect__element {
    //margin-right: 4px;
  }
}

.badge-phone-info {
  font-size: 10px;
  font-weight: normal;
  color: $white;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 13px !important;
}

//======= SCHEDULED MESSAGES SECTION
.scheduled-messages-modal {
  .modal-body {
    //padding: 0 !important;
    overflow-x: hidden !important;
    font-size: $f-size-body-sm;
  }
}

.t2-badge {
  color: $white;
}

.badge-primary {
  background: $primary;
}

.badge-warning {
  background: $warning;
}

.badge-danger {
  background: $danger;
}

.badge-grey {
  background: $grey;
}

.badge-grey-80 {
  background: $grey-80;
}

.badge-green-3 {
  background: $green-3;
}

.badge-yellow-1 {
  background: $yellow-1;
}

.badge-blue-3 {
  background: $blue-3;
}

.badge-purple-2 {
  background: $purple-2;
}

.fs-45 {
  font-size: 45px !important;
}


.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-14 {
  font-size: $f-size-h5 !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.multiselect.border-blue {
  .multiselect__tags {
    border: 1px solid $blue
  }
}

.multiselect.chip__clear-blue:hover,
.multiselect.chip__clear-blue:active {
  .multiselect__tags {
    //border: 2px solid $blue;
  }
}

.multiselect.always-open {
  .multiselect__content-wrapper {
    display: block !important;
    position: relative;
  }
}

.multiselect.options__no-border {
  .multiselect__content-wrapper {
    border: none !important;
  }
}

.multiselect.chip__clear-blue {
  .multiselect__tag {
    color: $blue;
    background: transparent;
    border: 1px solid $grey-60;
  }

  .multiselect__tag-icon {
    height: 15px;
    line-height: 11px;
    border-radius: 10px;
    border: 1px solid $grey-90;
    right: 3px;
    top: 3px;
    width: 15px;
  }

  .multiselect.shrink-options {
    .multiselect__option {
      padding: 5px !important;
      border-radius: 8px;
      min-height: 28px !important;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }

  .multiselect__content-wrapper {
    border: 1px solid $blue !important;
  }

}

.multiselect.options-list__tall {
  .multiselect__content-wrapper {
    max-height: 300px !important;
  }
}

.multiselect.options__relative {
  .multiselect__content-wrapper {
    position: relative !important;
  }
}

.multiselect__option--selected {
  background: $grey-60 !important;
  color: $grey-100 !important;
  font-weight: 500 !important;
}

.multiselect__option--highlight {
  background: $grey-50 !important;
  color: $grey-90 !important;
  outline: none !important;

}

.multiselect__option--highlight.multiselect__option--selected {
  background: $grey-50 !important;
  color: $grey-90 !important;
}

.multiselect__option {
  padding: 9px !important;
  border-radius: 8px;
  min-height: 32px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multiselect__content-wrapper {
  padding-left: 5px !important;
  padding-right: 5px !important;

  ul.multiselect__content {
    li.multiselect__element {
      span.multiselect__option {
        span {
          display: inline-block;
          width: inherit;
          white-space: normal;
        }
      }
    }
  }
}

.multiselect {
  .multiselect__content-wrapper::-webkit-scrollbar-track {
    display: none !important;
  }

  //.multiselect__tags {
  //  padding-bottom: 8px;
  //}
}

.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__current,
.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__input,
.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.multiselect--active {
  .multiselect__tags {
    border: 1px solid $blue !important;
  }
}

.contact-audit {
  width: 100%;
}

.is-dot {
  min-height: 5px;
  min-width: 4.8px;
  height: 5px;
  width: 4.8px;
  padding: 0;
  margin: 0;

  &.unread-dot {
    transform: translateX(-14px);
  }
}

.grey-light {
  background: $grey-light;
}

.blue {
  background: $blue;
}

.contact-avatar {
  margin: 4.2px 0;
}

.activity-bottom-info {
  height: 30px;

  span {
    white-space: nowrap;
  }
}

//VUECTK DATETIMEPICKER

.date-time-picker {
  .field-input {
    font-size: 13px !important;
    font-family: $font-family-body !important;
  }
}

.time-picker-column::-webkit-scrollbar {
  display: block !important;
}

.time-picker-column[data-v-5bc85983]::-webkit-scrollbar {
  display: block
}

.date-time-picker {
  font-family: $font-family-body !important;
  font-size: 13px !important;
}


.form-control:focus {
  color: $grey-90;
  background-color: $white;
  border-color: $blue !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(25 118 210 / 25%);
}


//AT MENTIONABLE
.atwho-wrap {
  .atwho-ul {
    margin: 0;
    max-height: 235px;

    .atwho-li {
      height: 38px !important;
      margin: 10px;
      border-radius: 8px;

      .contact-avatar {
        font-weight: 600;

        span {
          overflow: inherit !important;
        }
      }

    }

    .atwho-cur {
      background: $grey-50;
      color: $grey-100;
    }
  }

  .atwho-view {
    max-width: 100% !important;
    min-width: 386px !important;
    max-height: 245px;
  }
}


.tippy-content {
  button.item {
    height: 38px !important;
    margin: 10px;
    border-radius: 8px;
  }
}


[contenteditable=true] {
  font-size: 14px;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.9;
}

[contenteditable=true]:focus-visible {
  outline: none !important;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: $grey-40;
  pointer-events: none;
  height: 0;
}

.bottom-radius {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.collapsed-can-expand {
  &.no-expansion,
  .q-item {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 0 !important;
  }
}

.scrollbar-white {
  &::-webkit-scrollbar-track {
    background-color: $white
  }

  &::-webkit-scrollbar {
    background-color: $white;
  }
}

.text-left-align {
  text-align: left;
}

.text-center-align {
  text-align: center;
}

.text-right-align {
  text-align: right;
}

.no-box-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/*
 Main layout
 */
.main-content {
  background: $white;

  .contacts {
    .main {
      width: 100%;
      overflow: hidden;

      .table-header {
        > div {
          > .row {
            > div:first-child > div:nth-child(2) label.disabled,
            > div:nth-child(2) > div:nth-child(2) label.disabled {
              color: inherit !important;
            }
          }
        }
      }
    }
  }
}

/*
 Contact page
 */
.contact-list-sidebar-wrapper {
  height: 100%;
  border-right: 1px solid #e4e4e4;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .router-link-exact-active {
    margin-top: 0 !important;
    @extend .active;
  }

  .sidebar-toggle {
    position: absolute;
    top: 12px;
    right: -10px;
    z-index: 1;
    border: 1px solid $grey-60;
    background: $white;
    padding: 0.15rem;
    line-height: 0.5;
    border-radius: 6px;
  }

  .card {
    overflow: auto;
    position: relative;
    width: 300px;

    .list-group-item {
      padding: 0 0.5rem;
      height: 65px;

      > div {
        > div:nth-child(2),
        > div:nth-child(3) {
          height: 65px !important;
        }

        div:nth-child(3) {
          border-bottom: 1px solid $grey-60;

          .badge {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .list-group-item.active,
    .list-group-item:hover {
      background: $grey-50;
      color: $grey-100;
      border-radius: 10px;
    }

    .contact-details {
      border-bottom: 1px solid $grey-50;
      padding-top: 13px;
      min-width: 0;
      flex: 1;

      .contact-name {
        font-size: $f-size-14;
        color: $grey-100;
        font-weight: 500;
        display: inline-block;
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .contact-phone {
        font-size: $f-size-body-sm;
        font-weight: 400;
        color: $grey-80;
      }

      .contact-badge {
        height: 20px;
        width: 20px;
        padding: 5px 0 !important;
        text-align: center;
      }
    }
  }

  .contact-avatar {
    background-color: $grey-80 !important;
    color: $white !important;
    font-weight: 600;
  }

  .t-menu {
    max-height: 208px;
    height: 100%;
  }
}

.contact-list-sidebar-container.width-0 {
  margin-left: 15px;
}

.contact-details-wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  .details-component-container {
    width: 100%;
    overflow: auto;

    .card {
      width: 100%;
      margin: 0 auto 10px auto;
    }
  }
}

.contact-view-wrapper {
  overflow: hidden;
  position: relative;
  background: $grey-50;
  height: 100%;

  .contact-activity-wrapper.w-less-630px {
    width: calc(100% - 630px)
  }

  .contact-activity-wrapper.w-less-345px {
    width: calc(100% - 345px)
  }

  .contact-activity-wrapper.w-less-330px {
    width: calc(100% - 330px)
  }

  .prev-activities {
    .q-btn__wrapper {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

//CONTACT INFO
.contact-info-wrapper:hover {
  .btn-edit-contact-info {
    opacity: 1;
  }
}

.contact-info-wrapper {
  display: flex;
  min-width: 0;

  .contact-name-wrapper {
    display: flex;
    min-width: 0;
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;

    .contact-name {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .q-item__section--avatar {
    padding-right: 0 !important;
    min-width: 0 !important;
  }

  .contact-phone {
    font-size: $f-size-body-xs;
    margin-top: -5px;
    right: 0;

    .contact-primary-phone {
      position: relative;
      top: 0.5px;
      margin-right: 2px;
    }

    .copy-phone-number {
      text-decoration: none;
      color: $grey-90;
    }
  }

  .btn-edit-contact-info {
    position: absolute;
    right: -7px;
    top: -9px;
    opacity: 0;
  }

  .contact-action-button {
    button:not(:last-child) {
      margin-right: 8px;
    }

    .custom-action-button {
      background: $grey-50;
      color: $grey-90;
      border: none;
      line-height: 1;
      width: 32px;
      height: 32px;
    }
  }
}

.edit-form-popover {
  left: -330px !important;
  width: 300px;
}

//APPOINTMENT MODAL
#appointment-modal {
  .time-picker-column::-webkit-scrollbar {
    display: block;
  }

  .appointment-form {
    font-size: $f-size-body-xs;

    .form-title {
      font-size: $f-size-body-xxs;
    }

    .sms-reminder-template-variables {
      cursor: pointer;
    }

    .sms-reminder-template-variables:hover {
      color: $red-100 !important;
    }

    .checkbox-wrapper .custom-control-label {
      padding-top: 0;
    }

    span.sms-reminder-label {
      display: block;
    }
  }
}

// CONTACT ACTIVITY WRAPPER
.contact-activity-counts-wrapper {
  .data-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: $black;
  }

  .data-label {
    @include font-size($f-size-body-sm, 400);
    color: $grey-90;
  }
}

// CONTACT PHONE NUMBER WRAPPER
.phone-number-wrapper:hover {
  div.options {
    opacity: 1;
  }
}

.phone-number-wrapper {
  .phone-number {
    font-size: $f-size-14;
  }

  .phone-number-title {
    font-size: $f-size-12;
    font-weight: normal;
    color: $white;
    display: inline-block;
    top: 0;
    position: relative;
  }

  div.options {
    margin-top: -3px;
    opacity: 0;

    > *:not(:last-child) {
      margin-right: 4px;
    }

    button {
      padding: 0.10rem 0.40rem !important;
      line-height: 12px !important;

      svg, i {
        margin-top: -2px;
      }
    }
  }

  .phone-actions {
    font-size: 80%;

    i, svg {
      margin-top: -3px;
    }
  }
}

.contact-phone-popover {
  left: -330px !important;
  width: 300px;
}

//ABOUT THIS CONTACT
.contact-about-wrapper {
  padding-bottom: 10px;
}

.information-container {
  display: flex;
  flex-direction: column;
  max-height: 225px;
  padding: 0 !important;
  margin-bottom: 12px;
  transition: max-height 0.3s ease;

  .custom-input-label {
    font-size: $f-size-body-xs;
  }
}

.information-container.auto-height {
  max-height: 10000px;
}

.expand-toggle {
  background: transparent;
  border: 1px solid $grey-60;
  position: absolute;
  padding: 0 0 0 1px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;

  & .material-icons {
    margin: auto auto;
    display: block;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tags-wrapper {
  .badge-tag {
    background: transparent;
    font-weight: 500;
    border: 1px solid $grey-60;
    margin-right: 5px;
  }

  .custom-badge-primary {
    color: $blue;
  }

  .custom-badge-secondary {
    color: $grey-mid;
  }

  .custom-badge-success {
    color: $success;
  }

  .custom-badge-danger {
    color: $danger;
  }

  .custom-badge-warning {
    color: $warning;
  }

  .custom-badge-info {
    color: $info;
  }

  .custom-badge-light {
    color: $light-grey;
  }

  .custom-badge-dark {
    color: $dark;
  }

  &--dense {
    .q-field__native.row {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .q-field__native.q-placeholder.input-text-sm {
      padding-left: 0 !important;
    }

    .with-value {
      padding-top: 10px !important;
    }
  }

  &.q-field--with-bottom {
    max-width: 100px !important;
  }
}

.tags-filter-wrapper {
  .card-body {
    padding: 0 !important;
  }
}

//CONTACT NOTES
.contact-notes-wrapper {
  .notes {
    font-size: 14px;
  }

  .contact-notes {
    border: none;
    opacity: 0.5;
  }

  .contact-notes:hover {
    border: 1px solid $primary;
  }

  .notes-empty-placeholder {
    font-size: $f-size-body-sm;
    opacity: 0.5;
  }
}

//CONTACT INTEGRATIONS
.contact-integration-wrapper {
  .integration-title {
    font-size: 16px;
    font-weight: 500;
    color: #303133;
  }
}

.integration-wrapper {
  margin-bottom: 10px;

  .integration-card {
    box-shadow: 0 2px 10px rgb(4 4 4 / 10%) !important;
  }

  a {
    text-decoration: none;
    margin-left: 12px;
  }

  .name-wrapper {
    font-size: 14px;
    font-weight: 500;
  }

  p {
    font-size: 14px;

    .data-icon-label {
      color: $grey-90;
    }
  }

  .deals {
    background: $grey-50;
    border: none;
    border-radius: 6px !important;

    .data-value {
      max-width: 156px !important;
      top: 0 !important;
    }

    .deals-title {
      font-size: 0.88rem;
      font-weight: 500;
      color: #303133;
    }
  }

  .hubspot-icon {
    font-size: 24px;
    border-radius: 7px;
    background: #FF7A59;
    color: #FFFFFF;
    border: 5px solid #FF7A59;
    margin-right: 10px;
  }
  .integration-title {
    font-weight: 500;
    color: #303133;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
  .guesty-icon {
    content: url(/integrations/guesty-icon.png);
    line-height: 48px;
    width: 32px;
    height: 32px;
  }
  .zoho-icon {
    content: url(/integrations/zoho-icon.svg);
    line-height: 48px;
    width: 32px;
    height: 32px;
  }

  .salesforce-icon {
    font-size: 32px;
    margin-right: 10px;
    margin-top: 3px;
    color: #0d9dda;
  }

  .pipedrive-btn {
    content: url(/integrations/pipedrive-icon.svg);
    margin-right: 20px;
    line-height: 48px;
    width: 32px;
    height: 32px;
  }

  .gohighlevel-btn {
    content: url(/integrations/ghl-icon.png);
    margin-right: 20px;
    line-height: 48px;
    width: 32px;
    height: 32px;
  }

  .data-value {
    max-width: 177px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    top: 5px;
    font-weight: 500;
  }

  .data-icon-label {
    font-weight: 400;
  }

  .integrations-logo {
    background-size: contain !important;
    width: 32px;
    height: 32px;
    margin-top: 3px;
    display: inline-block;
  }
}

.integration-wrapper-generic {
  .integration-jit-card-header {
    margin-bottom: 4px;

    .integration-icon-in-header {
      margin-right: 10px;
    }
  }

  .integration-title {
    top: -9px;
  }
}

.workflow-enroll-popover {
  left: -340px !important;
  width: 300px;
}

.contact-crm-integrations-wrapper {
  .integrations-link:first-child {
    margin-top: 5px;
  }

  .integrations-link:not(:last-child) {
    margin-bottom: 10px;
  }

  .integrations-link {
    text-decoration: none;
    color: $grey-100;
    height: 52px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(4, 4, 4, 0.04);
    padding: 16px;
  }
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.mx-w-100 {
  max-width: 100% !important;
}

.contact-activity-container {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  position: relative;

  .contact-activities {
    height: 100%;
    display: table-row;
    flex-direction: column;
    flex-grow: 1;

    .inner-1 {
      position: relative;
      height: 100%;
      width: 100%;

      .inner-2 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .composer-container-wrapper {
    width: 100%;
  }
}

// MESSAGE COMPOSER
.composer-container {
  padding: 10px;
  background: $white;

  .composer-wrapper {
    position: relative;
    border: 1px solid $grey-70;
    border-radius: 8px;
    padding: 10px 12px;


    .message-composer-send-button {

      > button {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        //border-color: #1976D2 !important;
        //border-right: 1px solid white;
        //border-right-color: white !important; ;
      }

      .message-composer-dropdown {
        button {
          //border-color: #1976D2 !important;
        }
      }

    }
  }

  .composer-footer {
    font-size: $f-size-body-sm;
  }
}

.tab-links {
  font-weight: 600;
  font-size: $f-size-body-sm;

  a {
    text-decoration: none;
    color: $grey-90;
  }

  a:hover {
    border-bottom: 2px solid $primary;
    color: $primary;
  }

  a.active {
    border-bottom: 2px solid $primary;
    color: $primary;
  }

  a:not(:last-child) {
    margin-right: 16px;
  }
}

// MESSAGE COMPOSER TEXT
.message-composer-text-wrapper {
  .file-dropper.position-absolute {
    width: 100%;
    left: 0;
    top: 32px;
    height: 100%;
    z-index: 0;
  }

  .b-textarea, .b-textarea:focus {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .message-options {
    padding-top: 6px;
    z-index: 1;
  }

  .message-options a:focus,
  .message-options a:focus-visible {
    outline: none !important;
    outline-offset: 0;
  }

  .message-options a:not(:first-child) {
    margin-left: 10px;
  }

  .popover {
    max-width: 100%;
  }

  .b-dropdown a.dropdown-item.disabled,
  .b-dropdown a.dropdown-item:disabled,
  .b-dropdown a.dropdown-item {
    font-size: 80%;
  }

  .media-preview-wrapper div:not(:first-child) {
    margin-left: 10px;
  }

  .media-preview-wrapper {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
    padding-right: 1px;

    .media-preview {
      position: relative;

      .img-preview {
        height: 76px;
        width: 76px;
        border: 1px solid $grey-60;;
        border-radius: 6px;
        padding: 2px;
      }

      .btn-remove-attachments,
      .btn-remove-file {
        position: absolute;
        top: 6px;
        right: 6px;
        font-size: 12px;
        height: 20px;
        width: 20.5px;
        padding: 0;
      }

      .btn-remove-attachments:hover {
        background: #62666e;
        color: white;
      }

      .btn-remove-file {

        &:hover {
          background: white;
        }

        i {
          color: $grey-90;
          //font-size: 12px;
        }
      }

      .btn-remove-file:focus {
        outline: none;
        border-color: transparent !important;
        box-shadow: none !important;
      }

      .btn-remove-attachments {
        opacity: 0;
      }

      .pdf-thumbnail-wrapper {
        padding: 4px;
        width: 100px;
        height: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;

        i.media-icon {
          color: #FE2216;
        }

        p {
          font-size: 10px;
          max-width: 90px;
        }

        .pdf-preview {
          overflow: hidden !important;
        }
      }

      .audio-thumbnail-wrapper {
        padding: 4px;
        width: 100px;
        height: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;

        p {
          font-size: 10px;
          max-width: 90px;
        }
      }

      .video-thumbnail-wrapper {
        height: 76px;
        width: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;
        padding: 4px;

        .embed-responsive {
          height: 100%;
        }

        .btn-play {
          position: absolute;
          top: 29%;
          left: 31%;
          color: $grey-90;
          cursor: inherit;
        }
      }

    }

    .media-preview:hover {
      .btn-remove-attachments {
        opacity: 1;
      }
    }

    .media-icon-wrapper {
      height: 42px;
      width: 42px;
      background: $grey-60;
      border-radius: 6px;
      text-align: center;
      margin: auto;

      i.media-icon {
        margin-top: 10px;
        font-size: 20px;
        color: #B5B7BB;;
      }
    }
  }
}

.attachment-wrapper {
  height: 209px;
  width: 362px;
  padding: 10px;

  #fileInput {
    position: relative;
  }

  .uploader-label {
    position: absolute;
    width: 100%;
    height: 128px;
    font-size: 14px;
    font-weight: 500;
    border: 1px dashed $grey-70;
    border-radius: 4px;
    padding-top: 30px;
  }

  form {
    height: 130px;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
  }

  .attachment-upload-progress {
    margin: 100px auto 0 auto;
    width: 90%;
  }

  #fileInput.dragdrop .custom-file,
  #fileInput.dragdrop .custom-file-input {
    height: 100px;
  }

  #fileInput.dragdrop .custom-file-label {
    height: 100px;
    line-height: 90px;
    text-align: center;
    padding: 0;
    border: none !important;
    background: transparent;
  }

  #fileInput.dragdrop .custom-file-label span {
    visibility: hidden;
  }

  #fileInput.dragdrop .custom-file-label::after {
    display: none;
  }

  .notice {
    font-size: 12px;
  }

  [v-cloak] {
    display: none;
  }

  .error-notice {
    text-align: center;
    color: #721c24;
    font-size: 80%;
    margin-top: 100px;
  }
}

.file-uploader-wrapper {
  width: 100% !important;
  padding: 0 !important;
}

// MESSAGE COMPOSER FAX
.message-composer-fax-wrapper {
  .file-info {
    margin-bottom: 0 !important;
  }

  .custom-progress-bar {
    width: 97% !important;
    height: 4px !important;
    margin-top: 10px;

    div.progress-bar {
      background-color: $grey-70 !important;
    }
  }

  .btn-remove-file {
    color: $grey-90;
  }

  .media-preview-wrapper {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0;
    padding-right: 1px;

    .media-preview {
      position: relative;

      .btn-remove-attachments {
        position: absolute;
        top: 6px;
        right: 6px;
        padding: 0.015rem 0.2rem;
        font-size: 50%;
        opacity: 0;
      }

      .pdf-thumbnail-wrapper {
        padding: 4px;
        width: 100px;
        height: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;

        i.media-icon {
          color: #FE2216;
        }

        p {
          font-size: 10px;
          max-width: 90px;
        }

        .pdf-preview {
          overflow: hidden !important;
        }
      }
    }

    .media-preview:hover {
      .btn-remove-attachments {
        opacity: 1;
      }
    }

    .media-icon-wrapper {
      height: 42px;
      width: 42px;
      background: $grey-60;
      border-radius: 6px;
      text-align: center;
      margin: auto;

      i.media-icon {
        margin-top: 10px;
        font-size: 20px;
        color: #B5B7BB;;
      }
    }
  }
}

//MESSAGE COMPOSER EMAIL
.message-composer-email {
  .b-textarea, .b-textarea:focus {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .message-options {
    padding-top: 6px;
  }

  .message-options a:focus,
  .message-options a:focus-visible {
    outline: none !important;
    outline-offset: 0;
  }

  .message-options a:not(:first-child) {
    margin-left: 10px;
  }

  .popover {
    max-width: 100%;
  }

  .email-subject-input {
    padding: 0 !important;
  }
}

// MESSAGE COMPOSER NOTE
.message-composer-note-wrapper {
  .b-textarea, .b-textarea:focus {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .message-options {
    padding-top: 6px;
  }

  .message-options a:focus,
  .message-options a:focus-visible {
    outline: none !important;
    outline-offset: 0;
  }

  .message-options a:not(:first-child) {
    margin-left: 10px;
  }

  .popover {
    max-width: 100%;
  }

  .email-subject-input {
    padding: 0 !important;
  }

  .atwho-wrap {
    textarea {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      overflow: hidden !important;
      font-size: 14px;
    }
  }

  .at-custom-text {
    margin-left: 36px;
    font-size: $f-size-body-sm;
    line-height: 1;
    height: 27px;
    margin-top: 2px;
  }

  [contenteditable=true] {
    font-size: 14px;
    display: block;
    outline: none;
    word-wrap: break-word;
  }

  [contenteditable=true]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
    opacity: 0.9;
  }

  [contenteditable=true]:focus-visible {
    outline: none;
  }
}

// SCHEDULED MESSAGES MODAL
.scheduled-messages-modal {
  .preview.file-name {
    max-width: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  span.row-summary {
    display: block;
    margin-top: 8px;
  }

  .media-preview-wrapper div:not(:first-child) {
    margin-left: 10px;
  }

  .media-preview-wrapper {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
    padding-right: 1px;

    .media-preview {
      position: relative;

      .img-preview {
        height: 40px;
        width: 40px;
        border: 1px solid $grey-60;;
        border-radius: 6px;
        padding: 2px;
      }

      .pdf-thumbnail-wrapper {
        padding: 4px;
        width: 50px;
        height: 39px;
        border-radius: 8px;
        border: 1px solid $grey-60;

        i.media-icon {
          color: #FE2216;
        }

        p {
          font-size: 10px;
          max-width: 90px;
        }

        .pdf-preview {
          overflow: hidden !important;
        }
      }

      .audio-thumbnail-wrapper {
        padding: 4px;
        width: 100px;
        height: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;

        p {
          font-size: 10px;
          max-width: 90px;
        }
      }

      .video-thumbnail-wrapper {
        height: 76px;
        width: 76px;
        border-radius: 8px;
        border: 1px solid $grey-60;
        padding: 4px;

        .embed-responsive {
          height: 100%;
        }

        .btn-play {
          position: absolute;
          top: 29%;
          left: 31%;
          color: $grey-90;
          cursor: inherit;
        }
      }

    }

    .media-preview:hover {
      .btn-remove-attachments {
        opacity: 1;
      }
    }

    .media-icon-wrapper {
      height: 18px;
      width: 42px;
      border-radius: 6px;
      text-align: center;
      margin: auto;

      i.media-icon {
        font-size: $f-size-body-xs;
        color: #B5B7BB;;
      }
    }
  }
}

/*
 Bootstrap Vue
 */

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: $primary;
}

.btn-outline-dark {
  border: 1px solid $grey-70;

  &:active, &:hover, &:focus {
    background-color: $white;
    border-color: $grey-70;
    color: $grey-100;
    box-shadow: none !important;
  }

  i {
    font-size: $f-size-body-xl;
    margin-top: -2px;
  }
}

.btn-outline-dark-primary {
  color: $primary;
  @extend .btn-outline-dark;
}

/*
 Quasar related
 */
.q-menu {
  @extend .border-rounded;
}

.no-q-btn-focus {
  &:hover, &:focus {
    & .q-focus-helper {
      display: none !important;
    }
  }
}

.q-select__dropdown-icon {
  font-size: 16px;
}

.q-pa-sm {
  padding: 10px;
}

.q-pa-md {
  padding: 12px;
}

.q-btn.icon-btn {
  & .q-icon {
    font-size: inherit;
  }
}

.row {
  margin-left: inherit;
  margin-right: inherit;
}

.q-select .q-field__input {
  padding-left: 0;
  padding-right: 0;
}

.tab-dropdown {
  & .q-focus-helper {
    display: none;
  }

  & .q-btn-dropdown__arrow {
    font-size: 16px;
  }

  & .availability-status {
    top: -2px;
    right: -2px;

    @extend .rounded-badge;
    @extend .bordered;
  }
}

.rounded-badge {
  height: 8px;
  width: 12px;
  border-radius: 50%;
  padding: 0;

  &.small {
    height: 6px !important;
    width: 6px !important;
    min-height: unset;
  }

  &.bordered {
    border: 2px solid #FFFFFF;
  }

  &.bordered-grey {
    border: 2px solid $grey-light;
  }
}

.tab-dropdown-list {
  .q-item {
    padding: 0 8px;

    .user-status-name {
      top: 7px;
      margin-left: -3px;
      position: absolute;
    }

    .rounded-badge {
      margin-top: 8px;
      margin-left: 0;
    }
  }

  .q-separator {
    margin-left: 18px !important;
    margin-right: 18px !important;
    background: $grey-50 !important;
  }
}

.q-menu {
  @extend .text-sm;
}

/*
 Utilities
 */
.flex-even {
  flex: 1;
}

.break-all {
  word-break: break-all;
}

.text-word-wrap {
  overflow-wrap: break-word;
}

.auto-size {
  width: auto;
  height: auto;
}

.height-0 {
  height: 0;
}

.height-12 {
  height: 12px;
}

.height-16 {
  height: 16px;
}

.height-22 {
  height: 22px;
}

.height-24 {
  height: 24px;
}

.height-28 {
  height: 28px;
}

.height-32 {
  height: 32px;
}

.height-34 {
  height: 34px;
}

.height-35 {
  height: 35px;
}

.height-36 {
  height: 36px;
}

.height-52 {
  height: 52px;
}

.height-56 {
  height: 56px;
}

.height-140 {
  height: 140px;
}

.height-200 {
  height: 200px;
}

.height-240 {
  height: 240px;
}

.height-410 {
  height: 410px;
}

.height-445 {
  height: 445px;
}

.width-32 {
  width: 32px;
}

.width-40 {
  width: 40px;
}

.width-65 {
  width: 65px;
}

.width-190 {
  width: 190px;

  &.important {
    width: 190px !important;
  }
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.width-260 {
  width: 260px;
}

.min-width-260 {
  min-width: 260px;
}

.width-290 {
  width: 290px;
}

.width-300 {
  width: 300px;
}

.width-330 {
  width: 330px;
}

.width-380 {
  width: 380px;
}

.max-width-380 {
  max-width: 380px;
  width: 100%;
}

.max-w-75 {
  max-width: 75% !important;
}

.max-w-85 {
  max-width: 85% !important;
}

.max-width-266 {
  max-width: 266px;
}

.width-0 {
  width: 0;
}

.font-size-0 {
  font-size: 0;
}

.margin-auto {
  margin: auto auto;
}

.no-select {
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  & ::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }

  & ::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }
}

.allow-select {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome and Opera */

  & ::selection {
    background: rgba($primary, 0.2) !important; /* WebKit/Blink Browsers */
  }

  & ::-moz-selection {
    background: rgba($primary, 0.2) !important; /* Gecko Browsers */
  }
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
}

.page-side-menubar {
  max-width: 508px;
  background-color: $white;
  //border: solid 1px $grey-light3;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;

  @include screen('md') {
    width: auto;
  }

  &__nav {
    padding-top: 10px;
  }

  &__left {
    max-width: 200px;
    width: 100%;
    overflow: hidden;

    &--closed {
      min-width: 40px;
    }

    @include screen('lg') {
      &--closed {
        min-width: 40px;
      }
    }
  }

  &__left.settings {
    max-width: 300px;
  }

  &__right {
    max-width: 308px;
    width: 100%;
    flex-grow: 1;
    border-left: solid 1px $grey-light3;
    overflow: hidden;
    height: 100%;

    @include screen('lg') {
    }
  }

  .header {
    height: 45px;
    display: flex;
    align-items: center;
    //border-bottom: solid 1px $grey-light3;

    &__label {
      padding-left: 20px;
      font-weight: bold;
      font-size: $f-size-body-xs;
      letter-spacing: 0.3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .form-control-search {
      font-size: 14px !important;

      .q-field__native {
        padding-left: 15px !important;
      }
    }
  }
}

.inbox-side {
  max-width: 558px;
  &.no-max-width {
    max-width: unset!important;
  }
  width: 100% !important;
  background-color: $white;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;

  @include screen('md') {
    width: auto;
  }

  &__nav {
    padding-top: 10px;

    .nav-item-separator {
      margin: 15px;
      border-color: $grey-60 !important;
    }
  }

  &__left {
    max-width: 250px;
    width: 100%;
    overflow: hidden;

    &--closed {
      min-width: 40px;
    }

    @include screen('lg') {
      &--closed {
        min-width: 40px;
      }
    }
  }

  &__right {
    width: 100%;
    flex-grow: 1;
    border-left: solid 1px $grey-light3;
    overflow: hidden;
    height: 100%;

    @include screen('lg') {
    }
  }

  .header {
    height: 45px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-bottom: solid 1px $grey-light3;
    padding-left: 6px;

    &__label {
      padding-left: 20px;
      font-weight: bold;
      font-size: $f-size-body-xs;
      letter-spacing: 0.3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.inbox-details {
  border: solid 1px $grey-light3;
  border-top: 0;
}

.inbox-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inbox-end {
  background-color: $white;
  position: relative;
  overflow: hidden;
  max-width: 330px;
  width: 100%;
  display: none;
  @include screen('lg') {
    display: flex;
  }
}

.form-control-search {
  height: 32px !important;
  font-size: $f-size-body-xs;
  margin-bottom: 0;
  border: 1px solid $grey-70;

  input {
    line-height: 30px;
  }

  &__icon {
    font-size: 12.5px;
    color: $grey-mid;
  }

  .q-field__prepend {
    padding-left: 15px !important;
    padding-right: 0;
  }

  .q-field__focusable-action {
    @extend .text-size-rg;
  }
}

.form-control-search::-webkit-input-placeholder {
  font-size: $f-size-body-xs;
}

.task-item.active {
  background: $grey-50;
}

.task-item {
  .contact-name {
    @include font-size(13px, 600);
    line-height: 17px;
    color: $grey-10;

    &.truncated-text {
      padding-right: 5px;
    }
  }

  .avatar-wrapper {
    min-width: 64px;
  }

  .avatar {
    max-width: 64px;
    width: 100%;
  }

  .comm-label {
    @include font-size(12px, normal);
    line-height: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    svg.item-identifier-icon {
      margin-top: -2px;
    }
  }

  .comm-label.mention-content > span {
    width: 165px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .campaign-name {
    @include font-size(10px, normal);
    line-height: 12px;
    color: $grey-10 !important;
  }

  .time-passed {
    @include font-size(12px, normal);
    line-height: 15px;
  }
}

.current-tasks {
  .options {
    @include font-size(12px, 500);
  }
}

.call-active {
  margin-bottom: 15px;
  @include screen('lg') {
    display: none;
  }
}

.inbox {
  height: 100%;
  width: 100%;
  display: flex;

  .not-in-inbox-label {
    padding: 0 12px;

    span.badge {
      margin-top: -2px;
      position: relative;
    }
  }

  .custom-toggle-button {
    &.q-btn-group--spread {
      & > .q-btn-item {
        flex: inherit;
        flex-grow: 1;
      }

      & > .q-btn-item:nth-child(2) {
        flex-grow: 1;
        min-width: 100px;
      }
    }
  }

  .task-list {
    .task-item {
      padding-right: 14px;
    }
  }
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-05 {
  opacity: 0.5 !important;
}

.btn-header-nav-back {
  color: $grey-90;
}

.custom-switch .custom-control-input ~ .custom-control-label::before {
  background: $grey-70 !important;
  border: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: $primary !important;
  border: none !important;
}

.custom-switch.switch-success .custom-control-input:checked ~ .custom-control-label::before {
  background: $success !important;
  border: none !important;
}

.custom-switch.switch-primary .custom-control-input:checked ~ .custom-control-label::before {
  background: $primary !important;
  border: none !important;
}

.custom-switch .custom-control-input ~ .custom-control-label::after {
  background: $white !important;
  border: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: white !important;
}

.custom-checkbox-w-50 .custom-control {
  width: 50% !important;
}

.custom-checkbox-w-100 .custom-control {
  width: 100% !important;
}

.dialer {
  & .invalid-feedback {
    width: inherit;
    margin-top: 0;
  }
}

.vbt-autocomplete-list {
  z-index: 6001 !important;
  position: fixed !important;

  & .list-group-item {
    &.active {
      background: #E5EEFF;
      border: 1px solid #D3E2FF;
    }
  }
}

.dialer-contact-info {
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 9px;
  margin-top: 0 !important;
}

.search-form {
  &.no-prepend {
    .form-control {
      border-width: 1px !important;
      border-radius: 6px;
    }
  }

  .form-control {
    font-size: $f-size-body-xs;
    padding-left: 8px;
    border-width: 1px !important;
    color: $grey-100;
    border-radius: 6px;

    &::-webkit-input-placeholder {
      font-size: $f-size-body-xs;
      color: $grey-80;
    }

    &:focus {
      border-color: $grey-70 !important;
      box-shadow: none !important;
    }
  }

  .input-group-prepend {
    margin-right: 0;

    .input-group-text {
      width: 100%;
      text-align: left;

      @extend .justify-content-start;
    }
  }

  .input-group-text {
    background: $white;
    border-color: $grey-70;
    border-style: solid;
    border-width: 1px 0 1px 1px !important;
    padding-left: 8px;
    padding-right: 0;
    border-radius: 6px;

    @extend .text-size-xs;
    @extend .text-grey-80;
  }
}

.dialer-tabs {
  .col-auto {
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-right: 0.5px solid $grey-60;
  }

  .nav-pills .nav-link {
    text-align: center;
    @extend .no-select;
  }

  textarea {
    color: $grey-100 !important;

    &::-webkit-input-placeholder {
      color: $grey-80;
    }
  }

  textarea:focus {
    border-color: $grey-70 !important;
    border-style: solid;
    border-width: 1px 0 1px 1px !important;
    box-shadow: none !important;
    overflow: hidden;
  }
}

.generic-selector {
  margin-bottom: 0;
  height: 40px !important;
  font-size: $f-size-body-xs !important;

  .q-field__control {
    min-height: 40px !important;
    height: 40px !important;
    border-radius: 6px;

    &:before {
      border: 1px solid $grey-70 !important;
      border-radius: 6px;
    }
  }

  .q-field__prepend {
    padding-left: 10px;
    height: 40px !important;
  }

  .q-field__native {
    padding-left: 10px !important;
    padding-right: 10px !important;

    span {
      position: relative;
      overflow: hidden;
      /*width: 100%;*/
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span:empty {
      display: none !important;
    }
  }

  &.with-prepend {
    .q-field__native {
      padding-left: 0 !important;
    }
  }

  .q-field__control-container {
    height: 40px !important;
  }

  .q-field__native {
    min-height: 40px !important;
    height: 40px !important;
    line-height: 16px !important;
  }

  .q-field__input {
    min-height: 16px;
    line-height: 16px;
  }

  .q-field__append {
    height: 40px !important;
  }
}

.active-call {
  background: $dark;
  border-radius: 7px;
  width: 190px;
  @extend .text-white;

  &.wrap-up {
    background: $grey-80;
  }

  .call-status {
    font-size: 11px !important;
    @extend .text-light-grey;
  }
}

.parked-call {
  background: $purple-10;
  border: 1px solid $purple-100;
  border-radius: 7px;
  width: 190px;
  @extend .text-grey-100;

  &:hover {
    background: $purple-40 !important;

    .q-focus-helper {
      background: unset !important;
    }
  }

  .call-status {
    font-size: 11px !important;
    @extend .text-grey-100;
  }
}

.mobile-parked-call {
  background: $purple-9;
  height: 59px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;

  .parked-call-content {
    padding-left: 2px;
    display: grid;
    grid-template-columns: 33.69px 1fr;
    font-size: 20px;
    font-weight: 600;
    color: $grey-80;
    min-width: 0;

    svg {
      margin-right: 14.31px;
    }
  }

  button {
    height: 28px;
    color: #9B51E0;

    .q-btn__wrapper {
      padding: 0 10px !important;
    }
  }
}

.phone {
  position: fixed;
  top: 110px;
  right: 5px;
  width: 300px;
  height: 522px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.08), 0px 10px 20px -4px rgba(4, 4, 4, 0.2);
  overflow: hidden;
  z-index: 1060;

  .phone-header {
    width: 100%;
    height: 36px;
    background: $dark;
    padding-left: 12px;
    padding-right: 12px;
    flex-shrink: 0;

    @extend .no-select;
  }

  .tab-dropdown {
    &.q-btn {
      line-height: 1;
    }
  }

  .no-arrow {
    .q-btn-dropdown__arrow {
      display: none;
    }
  }

  #signal-strength {
    display: inline-flex;
    height: 12px;
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0;

    & li {
      display: inline-block;
      width: 3px;
      float: left;
      height: 100%;
      margin-right: 1px;
    }

    & li.pretty-strong {
      padding-top: 0px;
    }

    & li.strong {
      padding-top: 3px;
    }

    & li.weak {
      padding-top: 6px;
    }

    & li.very-weak {
      padding-top: 9px;
    }

    & li div {
      height: 100%;
      border: 1px solid $success;
    }

    & li div.active {
      background: $success;
    }
  }

  .phone-notice {
    width: 264px;
    height: 46px;

    .q-banner__avatar {
      padding-left: 0;
      padding-right: 0;
      margin: auto;

      & > .q-icon {
        font-size: inherit;
      }
    }

    .q-banner__content {
      line-height: 16px;
    }

    .q-btn .q-icon {
      font-size: inherit;
    }

    .q-banner__actions {
      padding-left: 0;
      padding-right: 0;
      width: 14px;
      height: 14px;
      margin: auto;

      .q-btn__content {
        margin-top: -6px;
      }
    }
  }

  .phone-info {
    width: 200px;
    height: 150px;

    .add-status {
      margin-left: 23px;
      font-size: $f-size-body-xs;
      color: $grey-90;
    }

    &.small {
      height: 66px;
    }

    .q-btn:not(.merge-btn) {
      & .q-btn__wrapper {
        min-width: 14px;
        min-height: 14px;
      }

      & .q-btn__content {
        padding: 0;
        height: auto;
      }

      & .q-icon {
        font-size: inherit;
      }
    }

    .merge-btn {
      min-width: 82px;

      & .q-btn__content {
        padding: 0 9px;
        font-size: $f-size-body;
        line-height: 1;
      }
    }
  }

  .phone-status {
    width: 180px;
    height: 17px;
    color: $white;
  }

  .phone-cta {
    width: 180px;
    height: 150px;
    color: $white;
  }

  .ripple {
    .q-btn__content {
      &::after {
        @include rings(3s, 0s);
      }

      &::before {
        @include rings(3s, 0.5s);
      }
    }
  }

  .phone-body {
    background: $dark;
  }

  .phone-main {
    width: 100%;
    height: 100%;
    background: $white;

    .phone-avatar {
      margin-top: -25px;

      .contact-avatar {
        font-size: $f-size-body-xxxl;
        margin: 0;
      }
    }

    .border-bottom {
      border-bottom: 1px solid $grey-60 !important;
      border-radius: 0;
    }
  }

  .phone-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    padding: 0;

    &:focus, &.focus {
      box-shadow: none;
    }

    span {
      margin-top: 10px;
      color: $grey-90;
      font-size: $f-size-body-xxs;
      font-weight: 500;
    }

    &.elevated {
      background: $white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 3px 10px rgba(4, 4, 4, 0.12);
      border-radius: 10px;
      width: 74px;
      height: 74px;
      padding: 0;

      span {
        color: $grey-90;
        font-size: $f-size-body;
        font-weight: 600;
      }
    }
  }

  .phone-digits {
    border: 0;
    padding: 0;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $grey-100;
  }

  .dialpad {
    padding-left: 34px;
    padding-right: 34px;

    .number-btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      padding: 0;
      border: 1px solid #EBEBEB;
      border-radius: 50px;
      overflow: hidden;

      &:focus, &.focus {
        box-shadow: none;
      }

      .number-text {
        font-size: 24px;
        line-height: 29px;
        font-weight: 400;
      }

      .number-text-sub {
        font-size: 8px;
        line-height: 10px;
        text-align: center;
        font-weight: 400;
        color: $grey-90;
      }
    }
  }

  .contact-name {
    font-weight: 600;
    font-size: $f-size-body-xl;
    line-height: 19px;

    .q-btn {
      & .q-btn__wrapper {
        min-width: 14px;
        min-height: 14px;
      }

      & .q-btn__content {
        padding: 0;
        height: auto;
      }

      & .q-icon {
        font-size: inherit;
      }
    }
  }

  .contact-phone {
    font-size: $f-size-body-sm;
    line-height: 16px;
  }

  .phone-wrap-up {
    max-height: 435px;
    font-size: $f-size-body-sm;

    @extend .phone-main;
  }

  .phone-footer-buttons {
    z-index: 3;
    height: 52px;
    box-shadow: 0px 0px 10px rgba(4, 4, 4, 0.2);
    background: $white;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    justify-content: space-between;

    .btn {
      font-weight: 500;
      font-size: $f-size-body;
      line-height: 16px;
      border-radius: 6px;

      .b-icon {
        font-size: $f-size-body-sm;
        vertical-align: -0.15em;
      }
    }

    .btn-primary {
      &:active, &:hover, &:focus {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        box-shadow: none !important;
      }
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .btn-sm, .btn-group-sm > .btn {
    @extend .border-half-rounded;
  }

  .phone-notes {
    .q-field__inner {
      justify-content: flex-start !important;
    }
  }

  .phone-expansion {
    background: transparent;

    .q-item__label {
      @extend .no-select;
    }

    .q-expansion-item {
      box-shadow: 0px 0px 8px rgba(4, 4, 4, 0.12);
      border-radius: 12px;

      .q-item {
        padding-left: 16px;
        padding-right: 16px;
      }

      .q-focus-helper {
        display: none !important;
      }

      &:hover, &:focus {
        & .q-focus-helper {
          display: none !important;
        }
      }

      .q-expansion-item__toggle-icon {
        transform: rotate(180deg);
        width: 18px;
        height: 18px;
        font-size: 18px;
      }

      .q-expansion-item__toggle-icon--rotated {
        transform: rotate(0deg);
      }

      .q-expansion-item__content > .q-card > .q-card__section {
        padding: 0;
        overflow-y: auto;
      }
    }

    .integrations-card {
      .card-body {
        @extend .q-pa-sm;
      }
    }
  }
}

.phone-widget {
  position: relative;
  top: 0;
  right: 0;
}

.phone-settings {
  width: 210px;
  @extend .q-pa-md;

  .q-field__native {
    padding: 2px 0;
    font-size: $f-size-body;
    overflow: hidden;
  }

  .btn:focus, .btn.focus {
    box-shadow: none;
    text-decoration: none;
  }
}

.phone-radio-select {
  .q-item__section--avatar {
    min-width: 16px;
    padding-right: 8px;
  }

  .q-field__control {
    margin-top: 0;
  }
}

.giphy-wrapper {
  div.img-box.default-card-animation {
    cursor: pointer;
  }
}

.mx-height-600 {
  max-height: 600px !important;
}

.mx-height-500 {
  max-height: 500px !important;
}

.mx-height-300 {
  max-height: 300px !important;
}

.option__group_title {
  color: $grey-100;
  font-weight: 600;
}

.basic-selector {
  @include border-radius('8px')
}

.q-tooltip {
  background-color: $grey-light11;
  color: $grey-100;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.22);
}

.contact-save-bar-wrapper {
  position: fixed;
  width: 100%;
  background: $grey-40;
  height: 65px;
  padding: 17px 18px;
  bottom: 0;
  border-top: 1px solid $grey-70;
  z-index: 999;
  left: 0;

  .label {
    font-size: 14px;
    font-weight: 600;
    color: $grey-90;
  }
}

.q-item--active {
  background: $primary !important;
  color: $white !important;
}

.q-manual-focusable--focused:not(.q-item--active) {
  background: $grey-50 !important;
  color: $grey-100 !important;
}

.start-state {
  align-items: center;
  background: $white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  padding-top: 36px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.disabledButton {
  & .btn:not(.dropdown-toggle-split) {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.title-icon {
  padding-left: 5px;
  padding-right: 5px;
}

.selected-contacts {
  font-size: 11px;
}

.action-desc {
  padding-left: 20px;
}

.contacts-filter-sidebar {
  height: 100%;
  width: 400px;
  //display: flex;
  //justify-content: flex-end;

  .card.filter-container {
    border-top: 0;
  }

  .card {
    -webkit-border-radius: 0;


    .card-header {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-radius: unset;

      .header-buttons {
        color: $grey-90;
      }
    }
  }

  .filter-list {
    border: none;
    padding: 0;
    margin: 0;
  }

  .filter-divider {
    border: none;
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    padding: 0 5px;
    font-weight: bold;
  }

  .filter-divider:not(:first-child) {
    margin-top: 15px;
  }

  .filter-list-item {
    border: none;
    padding: 0 5px;
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: $light-green2;
    }
  }

  .filter-search-icon {
    background-color: transparent;
    border-radius: 0;
  }

  .filter-search-input {
    border-radius: 0;
  }

  .filter-contents {
    width: 100%;
    height: calc(100vh - 132px);
    overflow: auto;
  }

  .filter-contents.step-2 {
    overflow: hidden !important;

    .filter-list {
      height: calc(100vh - 220px);
      overflow: auto;
    }
  }

  .contact-prop-label {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  .add-filters {
    font-size: 13px;

    .add-icon {
      font-size: 16px;
    }
  }

  .step-1,
  .step-3,
  .conjunction-button {
    font-size: 12px;
  }

  .step-3 {
    .filter-label {
      font-weight: 600;
    }

    .filter-operation {
      border-radius: 0.25rem;
    }
  }

  .filter-name {
    color: #0090AF;
    font-weight: bold
  }

  .filter-item {
    position: relative;

    .delete-filter {
      margin-top: 1rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }

  .delete-group-filter {
    color: #0090AF;
    font-weight: bold;
  }

  .group-conjunction {
    line-height: 2.1em;
  }
}

.relative-time {
  color: $grey-mid;
  display: inline-block;

  &::first-letter {
    text-transform: capitalize;
  }
}

.mycard {
  background: $white;
  box-sizing: border-box;
  border: solid 1px $grey-light3;
  @include border-radius(10px);
  overflow: hidden;

  &.no-border {
    border: 0;
  }

  &.dashed {
    border-style: dashed;
    cursor: pointer;
    background-color: lighten(grey, 47%);

    .row {
      color: darken(grey, 5%) !important;

      span {
        font-size: 32px;
      }
    }
  }
}

.mycard--shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.time-picker-column::-webkit-scrollbar {
  display: block;
}

.appointment-form {
  font-size: 12px;

  .form-title {
    font-size: 11px;
  }

  .sms-reminder-template-variables {
    cursor: pointer;
  }

  .sms-reminder-template-variables:hover {
    color: #C4183C !important;
  }

  .checkbox-wrapper .custom-control-label {
    padding-top: 3px;
  }
}

.tree-list-item {
  .folder {
    line-height: 34px;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out;

    &__arrow {
      margin-top: -5px;
      margin-right: 5px;
    }

    &__icon {
      margin-top: 1px;
      margin-right: 5px;
    }

    &__name {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        font-size: $f-size-h5;
        line-height: 17px;
        color: $grey-100;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    &__sub {
      padding-left: 10px;
    }

    &__indent {
      width: 10px;
    }

    &__option {
      margin-left: 5px;
      height: 34px;
    }

    &__input {
      font-size: 12px;
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 0;

      &:focus {
        outline-color: $green;
        -moz-outline-radius: 0;
      }
    }
  }
}

.sublists {
  &.is-root {
    .folder__indent {
      display: none;
    }
  }

  > .layer-indent-1 {
    > .folder {
      // padding-right: 0px !important;
      > .folder-item {
        padding-left: 18px;
      }
    }
  }

  > .layer-indent-2 {
    > .folder {
      > .folder-item {
        padding-left: 30px;
      }
    }
  }

  > .layer-indent-3 {
    > .folder {
      > .folder-item {
        padding-left: 40px;
      }
    }
  }
}

.avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: bold;
  line-height: 0;
  letter-spacing: 0.35px;

  &--src {
    color: transparent;
  }

  &--active {
    border: solid 1px $green;
    background-color: white;
  }

  &__inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      background-color: $dark;
      border: solid 2px $white;
    }
  }
}

.checkbox {
  &__input {
    position: absolute;
    left: -36px;
    top: -36px;
  }

  &__input ~ div {
    width: 18px;
    height: 18px;
    background-color: $white;
    @include border-radius(2px);
    border: solid 2px $grey-mid;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 100ms ease-in;

    svg {
      display: none;
    }
  }

  &__input:checked ~ div {
    background-color: $green;
    border-color: $green;

    svg {
      display: block;
    }
  }
}

.column-headers-modal {
  max-height: 100%;
  @include screen('lg') {
    max-height: 100%;
  }

  .ghost {
    border: dashed 1px $green !important;
  }

  .category-name {
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
  }

  &__checkboxes {
    overflow: auto;
  }

  &__remove {
    width: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 10px;
  }

  &__selected {
    font-size: 14px;
  }

  .handle:hover {
    background-color: $light-green2;
  }

  .handle {
    cursor: move;
  }

  &__item {
    height: 35px;

    &--hidden {
      height: 0;
      visibility: hidden;
      overflow: hidden;
    }

    i {
      color: $grey-2;
      font-size: 10px;
    }
  }

  &__label {
    font-size: 14px;
  }

  .modal-title {
    color: $white;
  }

  .modal-header {
    background-color: $dark;
    border-radius: 0;
    padding: 15px;

    .close {
      color: $white;
    }
  }

  .modal-content {
    border-radius: 0;
    border: none;
  }

  .modal-footer {
    .custom-btn {
      min-width: 120px;
    }
  }

  .draggable-columns {
    height: calc(100% - 24px) !important;
  }
}

.compact-button {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}

.medium-button {
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.btn-outlined-light {
  border-color: $grey-light11;
  color: $black;

  &.with-border {
    background-color: #EFF6FE;
  }
}

.button-disabled {
  background-color: #E0E0E0 !important;
  border-color: #E0E0E0 !important;
  color: #828282;
}

.folder-create {
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
  cursor: pointer;

  &:hover {
    background-color: white;
  }

  &__arrow {
    margin-top: -5px;
    margin-right: 5px;
  }

  &__icon {
    margin-top: -5px;
    margin-right: 5px;
  }

  &__name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__indent {
    width: 10px;
  }

  &__input {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;

    &:focus {
      outline-color: $green;
      -moz-outline-radius: 0;
    }
  }
}

.calls-header {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $grey-light3;

  &__label {
    padding-left: 20px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    min-width: 0;

    .contact-name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.confirm-dialog {
  .modal-title {
    color: $dark;
    font-size: 16px;
    font-weight: bold;
  }

  .modal-header {
    background-color: $white;
    border-radius: 0;
    padding: 15px;

    .close {
      color: $dark;
    }
  }

  .modal-content {
    border-radius: 0;
    border: none;
    font-size: 14px;
  }

  .modal-footer {
    .action-btn {
      min-width: 120px;
    }
  }
}

.contact-menu-item {
  height: 40px;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 8px;
  transition: background-color 100ms ease-in-out;

  &__title {
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
  }

  &__icon {
    margin-top: -2px;
  }

  &__title,
  &__icon,
  &__suffix * {
    height: 40px;
  }

  &:hover {
    background-color: $grey-60;
  }
}

.time-picker-column::-webkit-scrollbar {
  display: block;
}

.appointment-form {
  font-size: 12px;

  .form-title {
    font-size: 11px;
  }

  .sms-reminder-template-variables {
    cursor: pointer;
  }

  .sms-reminder-template-variables:hover {
    color: #C4183C !important;
  }

  .checkbox-wrapper .custom-control-label {
    padding-top: 3px;
  }

  span.sms-reminder-label {
    display: block;
    margin-top: 4px;
  }
}

.contact-menu {
  width: 200px;

  &__title {
    padding-left: 2px;
    padding-right: 2px;

    &__inner {
      height: 34px;
      font-weight: bold;
      line-height: 14px;
      font-size: 12px;
    }
  }
}

.filters-modal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .filter-list {
    border: none;
    padding: 0;
    margin: 0;
  }

  .filter-divider {
    border: none;
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
  }

  .filter-divider:not(:first-child) {
    margin-top: 15px;
  }

  .filter-list-item {
    border: none;
    padding: 0;
    font-size: 13px;
    line-height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: $light-green2;
    }
  }

  .filter-search-icon {
    background-color: transparent;
    border-radius: 0;
  }

  .filter-search-input {
    border-radius: 0;
  }

  .modal-title {
    font-size: 16px;
    color: $white;
  }

  .modal-header {
    background-color: $dark;
    border-radius: 0;
    padding: 15px;

    .close {
      color: $white;
    }
  }

  .modal-dialog {
    margin: 0;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: flex-end;
  }

  .modal-body {
    padding: 0px;
    margin: 0;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    height: 100%;
    overflow: hidden;

    @include screen('lg') {
      max-width: 324px;
    }
  }

  .filter-contents {
    width: 100%;
    height: calc(100vh - 61px);
    overflow: auto;
  }

  .modal-footer {
    .custom-btn {
      min-width: 120px;
    }
  }
}

.list--header {
  font-size: 13px !important;
  color: $grey-90 !important;
}

button#bs-folder-options {
  margin-top: -9.2px;
  position: absolute;
}

.folders {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 5px;

  &.border-top {
    .folders__header {
      padding-top: 16px;
      padding-bottom: 10px;

      button#bs-folder-options {
        margin-top: -1px;
      }
    }
  }

  &__header {
    font-size: $f-size-body-sm;
    line-height: 16px;
    letter-spacing: 0.03em;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 10px;

    &__title {
      color: $grey-90;
    }
  }

  &__content {
    overflow: auto;

    &.no-folder {
      padding-left: 18px;
      height: 34px;
    }
  }

  .item {
    cursor: pointer;
    font-size: 13px;
    line-height: 34px;
    transition: background-color 100ms ease-in-out;
  }

  .item:hover {
    background: $light-blue3;
  }

  .sublists {
    .folder {
      &--active:not(:hover) {
        background-color: $grey-50;
      }
    }
  }

}

.contact-popover {
  &__custom {
    padding: 4px !important;
    width: 260px;

    .popover-body {
      .contact-menu {
        width: 100%;
      }
    }
  }

  .popover-body {
    padding: 0;
  }
}

.create-list-modal {
  .modal-lg {
    @media (min-width: 992px) {
      max-width: 650px;
    }
  }

  .modal-body {
    padding: 0;
  }

  &__body {
    padding: 40px;
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    color: $black;
  }

  &__close {
    margin-right: -15px;
    margin-top: -30px;
  }

  &__list-title {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &__list-desc {
    font-size: 13px;
    color: $grey-light5;
  }
}

.integration-import-list-modal {
  .modal-lg {
    @media (min-width: 992px) {
      max-width: 650px;
    }
  }

  .modal-body {
    padding: 0;
  }

  &__body {
    padding: 40px;
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    color: $black;
  }

  &__close {
    margin-right: -15px;
    margin-top: -30px;
  }

  &__list-title {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &__list-desc {
    font-size: 13px;
    color: $grey-light5;
  }
}

.trial-expired-modal {
  .modal-lg {
    @media (min-width: 992px) {
      max-width: 750px;
    }
  }

  .modal-body {
    padding: 0;
  }

  &__body {
    font-size: 16px;
    padding: 20px;
    word-break: normal;
  }

  &__title {
    font-size: 28px;
    font-weight: 400;
    color: $black;
  }

  &__close {
    margin-right: -15px;
    margin-top: -30px;
  }

  &__list-title {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &__list-desc {
    font-size: 13px;
    color: $grey-light5;
  }
}

.empty-state {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 35px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 0;
}

.scrollableArea {
  position: relative;
  margin: auto;
  overflow-y: auto;
  overflow-x: auto;

  .datatable {
    z-index: 0;
  }
}

.paginated {
  .table-pagination {
    .q-btn__wrapper {
      min-height: 35px !important;
    }

    .q-btn__wrapper:before {
      box-shadow: none !important;
    }

    .q-btn {
      font-size: $f-size-12 !important;
    }

    .q-btn-item {
      min-width: 2em !important;
    }

    > .q-btn:nth-child(2) {
      margin-right: 10px;
    }

    > .q-btn:nth-child(4) {
      margin-left: 10px;
    }

  }
}

.q-select-pager.q-field {
  font-size: $f-size-12 !important;
}

.static-list-add-item.scrollableArea {
  max-height: calc(100vh - 196px);
}

div.tableResizer {
  position: absolute;
  user-select: none;
  width: 12px;
  bottom: 0;
  right: 0;
  height: 100%;
  color: transparent;
  cursor: col-resize;
  z-index: 10;
  background-color: transparent;
  border-right: 2px solid rgba(37, 110, 255, 0);

  &.resizing {
    background-color: rgba(37, 110, 255, 0.7);
  }

  &:hover {
    border-right: 2px solid rgba(37, 110, 255, 0.7) !important;
  }

  &:active {
    border-right: 2px solid rgba(37, 110, 255, 0.7);
  }
}

table.datatable {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  box-sizing: border-box;
  position: relative;
  table-layout: fixed;
  min-width: 100%;
  word-break: break-all;

  &--sticky-columns {
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          z-index: 4;
          font-weight: 500;
          color: $grey-90;
          font-size: 13px;
        }
      }
    }

    tbody {
      tr {
        td {
          z-index: 1;

          .contact-name {
            font-weight: 500;
            color: $primary;
          }
        }
      }
    }
  }

  thead {
    tr {
      th.checkbox {
        width: 40px;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        padding: 0;

        input[type='checkbox'] {
          padding: 0;
          display: block;
          height: 19px;
        }
      }

      .handle {
        cursor: move;
        margin-right: 0px;
        top: 2px;
        position: relative;
        color: $grey-100;
      }

      .handle-label {
        margin-right: 0px;
        top: 2px;
        position: relative;
        color: $grey-100;
      }

      th {
        background-color: $white;
        border-bottom: solid 1px $grey-60;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 10px 30px 10px 8px;
        text-align: left;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;
        font-weight: 600;

        > .move-icon-drag-container {
          opacity: 0;
        }

        &.th-name {
          .handle-label {
            padding-left: 38px;
          }
        }

        &.ghost {
          background-color: $light-green2;
        }

        &:last-child {
          border-right: none;
        }

        &:not(.checkbox):hover {
          background-color: $grey-60;

          &.hovering {
            background-color: #e9f0ff;
          }
        }

        &:not(.checkbox) .move-icon-drag:hover {
          // background-color: rgba(37, 110, 255, 0.1);
          // background-color: $grey-60;
        }

        .sorter-container {
          display: inline;
          opacity: 0;

          &.has-sorting {
            opacity: 1;

            a.sorter.sorter-asc {
              &:before {
                opacity: 1 !important;
                position: relative;
                top: 0;
              }

              &:after {
                //opacity: 0 !important;
              }
            }

            a.sorter.sorter-desc {
              &:before {
                //opacity: 0 !important;
              }

              &:after {
                opacity: 1 !important;
                position: relative;
                top: 0;
              }
            }

          }
        }

        &:hover {
          .tableResizer {
            border-right: 2px solid $grey-80;
          }

          // border-left: 1px $grey-light3 solid;
          // border-right: 1px $grey-light3 solid;
          > .move-icon-drag-container {
            opacity: 1;
          }

          a.sorter:before,
          a.sorter:after {
            opacity: 1;
          }

          .sorter-container {
            display: inline;
            opacity: 1;

            &.has-sorting {

              a.sorter.sorter-desc {
                &:before {
                  opacity: 1 !important;
                  position: relative;
                  top: 0px;
                }

                &:after {
                  opacity: 1 !important;
                }
              }

              a.sorter.sorter-asc {
                &:before {
                  opacity: 1 !important;
                }

                &:after {
                  opacity: 1 !important;
                  position: relative;
                  top: 0px;
                }
              }

            }
          }
        }

        a.sorter {
          position: absolute;
          height: 30px;
          width: 30px;
          // display: flex;
          // align-items: center;
          justify-content: center;
          padding-left: 6px;
          // right: 15px;
          // top: 9%;
        }

        a.sorter:before,
        a.sorter:after {
          border: 4px solid transparent;
          content: '';
          display: block;
          height: 0;
          width: 0;
        }

        a.sorter:before {
          border-bottom-color: $grey-30;
          margin-top: 1px;
          // opacity: 0;
        }

        a.sorter:after {
          border-top-color: $grey-30;
          margin-top: 2px;
          margin-left: 0px;
          // opacity: 0;
        }

        a.sorter.sorter-asc:before {
          border-bottom-color: $primary;
          opacity: 1;
        }

        a.sorter.sorter-desc:after {
          border-top-color: $primary;
          opacity: 1;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: $grey-light8;
      }

      &:hover td {
        background-color: $light-green2;
      }

      td.checkbox {
        min-width: 40px;
        max-width: 40px;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;

        input[type='checkbox'] {
          padding: 0;
          display: block;
          height: 19px;
          margin: auto;
        }
      }

      td:not(.datatable-row__checkbox) {
        background-color: $white;
        padding: 8px;
        border-bottom: solid 1px $grey-60;
        font-size: 13px;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 0;
        min-width: 0;

        &:read-write:focus {
          outline: 1px solid $light-green3;
          background: white;
        }

        .unread-text {
          font-size: 11px;
          line-height: 1;
        }
      }

      td.datatable-row__checkbox {
        background-color: $white;
        border-bottom: solid 1px $grey-60;
        font-size: 12px;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 0;
        min-width: 0;
        padding-right: 20px;

        &:read-write:focus {
          outline: 1px solid $light-green3;
          background: white;
        }
      }

      &:last-child td {
        border-bottom: none;
      }
    }
  }
}

.submenu {
  &-items {
    background: white;
    width: 130px;
  }
}

.folder-submenu-items,
.folder-childmenu-items {
  background: white;
  border-radius: 8px;
  padding: 6px 6px;
  border: solid 1px $grey-light;
  display: none;
  flex-direction: column;
  position: absolute;
}

.folder-submenu-items {
  width: 200px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);

  &.extended {
    width: 300px;
  }
}

.folder-childmenu-items {
  width: 300px;
}

.import-modal {
  .modal-title {
    color: #fff;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-header {
    color: $white;
    background-color: $dark;
    border-bottom-color: $dark;
    border-radius: 0;
    padding: 15px;

    .close {
      color: $white;
    }
  }
}

.btn-prev {
  width: 100px;
  background-color: transparent;
  border-color: $green;

  &:hover {
    background-color: darken($green, 5%);
  }
}

.btn-next {
  width: 100px;
  background-color: $green;

  &:hover {
    background-color: darken($green, 5%);
  }
}

.select-columns {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.select-columns-alert {
  font-size: 13px;
  padding: 15px;
  width: 100%;

  &--success {
    border: solid 1px $green;
    background-color: $light-green2;
  }

  &--dark {
    border: solid 1px $dark;
    background-color: lighten($dark, 80%);
  }
}

.table-holder {
  max-height: 300px;
  max-width: calc(100% - 1px);
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
    // word-break: break-all;
  }

  th {
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: sticky;
    top: 0;
    background-color: $white;
    border-bottom-width: 1px !important;
  }

  tr:first-child td {
    border-top: none;
  }

  td {
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.datatable-row {
  font-size: 12px;

  td {
    .col-indented {
      padding-left: 19px;
    }

    &.col-indented-2 {
      padding-left: 28px !important;
    }
  }

  &__checkbox {
    max-width: 40px;

    input {
      margin-top: 5px;
    }
  }

  &__phone {
    min-width: 50px;
  }

  &__name {
    min-width: 150px;
  }

  &__actions {
    min-width: 40px;
    max-width: 40px;

    /*&__action {
      &--call:hover {
        color: $green;
      }

      &--chat:hover {
        color: $blue;
      }

      &--trash:hover {
        color: $red;
      }
    }*/

    button {
      color: $grey-mid;
    }
  }
}

.contact-tags-title {
  font-size: 12px;
}

.contact-tags-item {
  font-size: 14px;
}

.import-modal  {
  .modal-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 300px);
    max-height: 500px;
  }

  .import-content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
  }
}

.import-dropzone {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  border: dashed 1px $green;
  display: flex;
  align-items: center;
  justify-content: center;

  &-link {
    color: $green;
    font-weight: bold;
  }

  &-text {
    color: $grey-2;
    font-size: 14px;
  }

  &-content {
    text-align: center;
  }

  .btn-upload {
    background-color: $green;

    &:hover {
      background-color: darken($green, 5%);
    }
  }
}

.import-steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: $grey-light;
    flex-grow: 1;
    cursor: pointer;

    &--active {
      border-color: $green !important;
      background-color: $green !important;
    }

    &--active & {
      &__number {
        color: $white !important;
        border-color: $white !important;
      }

      &__title {
        color: $white !important;
      }
    }

    &--done {
      border-color: $green;
    }

    &--done & {
      &__number {
        color: $black;
        border-color: $green;
      }

      &__title {
        color: $black;
      }
    }

    &__number {
      width: 30px;
      height: 30px;

      @include screen('lg') {
        width: 40px;
        height: 40px;
      }

      border-radius: 50%;
      border: solid 3px $grey-light;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      justify-content: center;
      margin-bottom: 5px;
      color: $grey-light;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-grow: 1;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: background-color 100ms ease-in-out;
    }

    &__title {
      display: none;
      font-weight: bold;
      color: $grey-light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include screen('md') {
        display: block;
        font-size: 10px;
      }

      @include screen('lg') {
        display: block;
        font-size: 14px;
      }
    }
  }

  &__item:last-child {
    border-right: none;
  }
}

.list-actions {
  .folder {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 34px;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out;

    &__arrow {
      margin-top: -5px;
      margin-right: 5px;
    }

    &__icon {
      margin-top: -5px;
      margin-right: 5px;
    }

    &:hover {
      background-color: $light-blue3;
    }

    &__name {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__sub {
      padding-left: 10px;
    }

    &__indent {
      width: 10px;
    }

    &__option {
      margin-top: -5px;
    }
  }
}

.templates-list-wrapper {
  max-height: 250px;
  overflow: auto;
  padding-right: 5px;

  .list-group-title {
    color: $grey-90;
    font-size: 13px;
    font-weight: 600;
  }

  .action-links {
    color: $green;
    text-decoration: none;

    svg {
      margin-top: -2px;
    }
  }
}

.group-label {
  font-size: 90%;
}

.custom-multi-select {
  .multiselect__option {
    padding: 5px !important;
  }

  i.search-icon {
    right: 14px;
    position: absolute;
    top: 12px;
  }
}

.option__desc {
  padding-left: 20px;

  .option__title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16.94px;
  }

  .option__small {
    font-size: 11px;
    font-weight: 400;
    color: $grey-90;
    max-width: 240px;
    white-space: normal;
  }
}

.option__group_header {
  font-size: 13px;
  font-weight: 500;
  color: $grey-100;
  padding-top: 0;
  margin-left: 5px;
}

.move-dialog {
  width: 258px;
  //min-height: 300px;
  max-height: 600px;
  background: $white;
  border: solid 1px $grey-light;
  position: absolute;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  flex-direction: column;
  z-index: 100;

  &.move-dialog__create {
    width: 280px !important;
  }

  &-close {
    margin-right: -5px;
    padding: 5px 5px 5px 5px;
    display: inline-flex;
    align-items: center;
    color: $grey-mid;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: solid 1px $grey-light;
    background-color: $grey-light2;
    font-size: 12px;
    min-height: 35px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    min-height: 35px;
    box-shadow: 0px -1px 3px 0px rgb(187, 186, 186, .5);
  }

  &-input {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;

    &.mdi_input_2 {
      padding-top: 10px !important;
    }

    input {
      height: 30px;
    }
  }

  &-lists {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: calc(300px - 120px);
    max-height: calc(100vh - 200px);
    padding-left: 10px;
    padding-right: 10px;
  }

  &__create {
    left: 334px;
    top: 178px;
  }
}

.folder {
  padding-right: 0px;
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;

  &__arrow {
    min-height: 34px;
    margin-top: -5px;
    margin-right: 5px;

    svg {
      margin-top: 5px;
    }
  }

  &__icon {
    min-height: 34px;
    margin-right: 5px;
  }

  &--target {
    background-color: rgba(37, 110, 255, 0.09);

    .folder__option {
      display: block;
      opacity: 1 !important;
    }
  }

  &:hover {
    .folder__option {
      display: block;
      border: 0 !important;
    }
  }

  &__name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__sub {
    padding-left: 10px;
  }

  &__indent {
    width: 10px;
  }

  &__option {
    line-height: 0;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-left: 5px;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none !important;
    }

    i {
      color: $grey-light4;

      &.fa.fa-circle,
      &.fa.fa-check-circle {
        font-size: 16px;
      }
    }
  }

  &__input {
    font-size: 12px;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;

    &:focus {
      outline-color: $green;
      -moz-outline-radius: 0;
    }
  }
}

.select-list-modal {
  .modal-lg {
    @media (min-width: 992px) {
      max-width: 550px;
    }
  }

  .modal-body {
    padding: 0;
    height: 90vh;
  }

  .b-overlay-wrap {
    height: 90vh;
  }

  &__body {
    padding: 40px;
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    color: $black;
  }

  &__close {
    margin-right: -15px;
    margin-top: -30px;
  }

  &__list-title {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &__list-desc {
    font-size: 13px;
    color: $grey-light5;
  }

  .tree-container {
    max-height: 65vh;
    overflow: auto;
  }

  .row.q-input .q-field__prepend + .q-field__control-container .q-field__native {
    padding-left: 15px !important;
  }
}

.select-list-tree-folder {
  .folder {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 34px;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out;
    max-height: 40vh;

    &__arrow {
      margin-top: 4px;
      margin-right: 5px;
    }

    &__icon {
      margin-top: 7px;
      margin-right: 5px;
    }

    &--selected {
      background-color: $light-blue3;
    }

    &:hover {
      background-color: $light-blue3;

      .folder__option {
        display: block;
      }
    }

    &__name {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__sub {
      padding-left: 10px;
    }

    &__indent {
      width: 10px;
    }

    &__option {
      margin-top: -5px;

      &--hide {
        width: 0;
        overflow: hidden;
      }
    }

    &__input {
      font-size: 12px;
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 0;

      &:focus {
        outline-color: $green;
        -moz-outline-radius: 0;
      }
    }
  }
}

.select-list-tree-list-item.list--active {
  background-color: $light-blue3;
}

.select-list-tree-list-item {
  .folder {
    line-height: 34px;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out;

    &__arrow {
      margin-top: -5px;
      margin-right: 5px;
    }

    &__icon {
      margin-top: -5px;
      margin-right: 5px;
    }

    &:hover,
    &--moving,
    &--active {
      background-color: $light-blue3;
    }

    &__name {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    &__sub {
      padding-left: 10px;
    }

    &__indent {
      width: 10px;
    }

    &__option {
      margin-top: -5px;
      margin-left: -5px;
    }

    &__input {
      font-size: 12px;
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 0;

      &:focus {
        outline-color: $green;
        -moz-outline-radius: 0;
      }
    }
  }
}

.folder {
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;

  &__arrow {
    min-height: 34px;
    min-width: 6px;
    margin-top: -5px;
    margin-right: 5px;

    svg {
      margin-top: 5px;
    }

    &.pd-arrow {
      margin-top: 0 !important;
    }

    &.pd-arrow-create {
      top: 8px !important;
      position: relative;
    }
  }

  &__icon {
    min-height: 34px;
    margin-right: 5px;

    svg {
      margin-top: 0px;
    }

    &.pd-icon-create {
      top: 8px !important;
      position: relative;
    }
  }

  &--selected {
    background-color: $light-blue3;
  }

  &:hover {
    .folder__option {
      opacity: 1;
    }
  }

  .folder__option {
    opacity: 0;
  }

  .folder__option:focus {
    opacity: 1;
  }

  &__name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &.pd-name-create {
      top: 2px !important;
      position: relative;
    }
  }

  &__sub {
    padding-left: 10px;
  }

  &__indent {
    width: 10px;
  }

  &__option {
    &--hide {
      width: 0;
      overflow: hidden;
    }
  }

  &__input {
    font-size: 12px;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;

    &:focus {
      outline-color: $green;
      -moz-outline-radius: 0;
    }
  }
}

.tree-folder {
  & > .folder,
  & > .sublists > .folder,
  & > .animated > .sublists > .folder {
    padding-left: 7px;
  }

  & > .folder,
  & > .sublists > .folder,
  & > .animated > .sublists > .folder,
  & .subfolders .sublists > .folder {
    &:hover {
      background-color: $light-blue3 !important;
    }
  }

  .folder {
    min-height: 34px;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease-in-out;

    &__arrow {
      margin-top: -5px;
      margin-right: 5px;
    }

    &__icon {
      min-height: 34px;
      margin-right: 5px;
    }

    &--selected {
      background-color: $light-blue3;
    }

    &:hover {
      .folder__option {
        display: block;
      }
    }

    &__name-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__name {
        font-size: $f-size-h5;
        line-height: 17px;
        color: $grey-100;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    &__sub {
      padding-left: 10px;
    }

    &__indent {
      width: 10px;
    }

    &__option {
      &--hide {
        width: 0;
        overflow: hidden;
      }
    }

    &__input {
      font-size: 12px;
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 0;

      &:focus {
        outline-color: $green;
        -moz-outline-radius: 0;
      }
    }
  }
}

.inbox-nav-item {
  @include border-radius(10px);
  display: flex;
  align-items: center;
  height: 40px;
  color: $grey-dark;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;

  &--closed {
    overflow: hidden;
  }

  &__active {
    background-color: $grey-50;
    color: $black;
  }

  @include screen('lg') {
    overflow: auto;
  }

  &:hover {
    text-decoration: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  &__icon {
    padding-right: 10px;
  }

  &__label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0025em;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--opened {
      display: block;
    }

    &--closed {
      display: none;
    }

    @include screen('lg') {
      display: block;
      &--closed {
        display: none;
      }
    }
  }

  .count-label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 8px;
    color: $grey-30;
  }

  .open-count {
    color: $grey-20;
  }

  .pending-count {
    font-weight: 500;
  }
}

.page-side-menubar-list-item {
  @include border-radius(10px);
  display: flex;
  align-items: center;
  height: 40px;
  color: $grey-dark;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;

  &--closed {
    overflow: hidden;
  }

  &__active {
    background-color: $grey-50;
    color: $black;
  }

  @include screen('lg') {
    overflow: auto;
  }

  &:hover {
    text-decoration: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  &__icon {
    padding-right: 7px;
    width: 23px;
  }

  &__label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0025em;
    flex-grow: 1;

    &--opened {
      display: block;
    }

    &--closed {
      display: none;
    }

    @include screen('lg') {
      display: block;
      &--closed {
        display: none;
      }
    }
  }

  .count-label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 8px;
    color: $grey-30;
  }

  .open-count {
    color: $grey-20;
  }

  .pending-count {
    font-weight: 500;
  }
}

//
.pinned {
  display: flex;
  flex-direction: column;
  //height: 20rem;
  padding-top: 16px;
  padding-bottom: 24px;

  &__header {
    font-size: $f-size-body-sm;
    line-height: 16px;
    letter-spacing: 0.03em;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 10px;

    &__title {
      color: $grey-90;
    }
  }

  &__content {
    ///height: calc(100% - 32px);
    //overflow: auto;
    .counts {
      padding-right: 17px;

      .badge-pill {
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }

  .item {
    padding-left: 18px;
    color: $dark;
    cursor: pointer;
    min-height: 34px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: background-color 100ms ease-in-out;
    white-space: nowrap;

    .icon {
      svg {
        margin-top: 1px;
      }
    }

    .counts {
      .badge {
        font-weight: 500;
        font-size: 11px;
        line-height: 11px;
      }
    }

    .icon,
    .item-name,
    .counts {
      min-height: 34px;
    }

    .item-name {
      font-size: $f-size-body-sm;
      line-height: 17px;
      color: $grey-100;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon {
      padding-right: 5px;
    }
  }

  .item:hover {
    background: $light-blue3;
    color: $dark;
    text-decoration: none;
  }

  .item-empty {
    padding-left: 20px;
  }

  .item.router-link-exact-active {
    background: $grey-50;
  }
}

.shared__header {
  padding-bottom: 0 !important;
}

.inbox-nav-item-group-header {
  padding: 10px 5px 10px 7px !important;
  color: #666666;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.inbox-nav-item {
  @include border-radius(10px);
  display: flex;
  align-items: center;
  height: 40px;
  color: $grey-dark;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;

  &--closed {
    overflow: hidden;
  }

  &__active {
    background-color: $grey-50;
    color: $black;
  }

  &:hover {
    text-decoration: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-left: 5px;
    padding-right: 0px;
  }

  &__icon,
  &__label,
  .count-label {
    line-height: 1;
  }

  &__icon {
    padding-right: 5px;
  }

  &__label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0025em;
    flex-grow: 1;

    &--opened {
      display: block;
    }

    &--closed {
      display: none;
    }

    @include screen('lg') {
      display: block;
      &--closed {
        display: none;
      }
    }
  }

  .count-label {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $grey-30;
  }

  .open-count {
    color: $grey-20;
  }

  .pending-count {
    font-weight: 500;
  }
}

.shine {
  background-color: #fff7df;
}

.message {
  &.shine {
    animation: shine-fade 0.5s linear;
  }

  @keyframes shine-fade {
    0% {
      background-color: rgba(255, 247, 223, 0);
    }
    100% {
      background-color: rgba(255, 247, 223, 1);
    }
  }

  .sms-activity a.linkified {
    color: $grey-90;
  }

  .sms-activity.bg-primary a.linkified {
    color: $white !important;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.contact-task-item {
  padding-right: 14px !important;
  min-height: 71px;

  .contact-unread-badge {
    left: 38px;
    top: -8px;
    height: 20px;
    width: 20px;
  }

  .overlay {
    opacity: 0.9 !important;
    background: white;
    width: 100%;
    height: 98%;
    cursor: pointer;

    .avatar {
      margin: 0 auto;
    }
  }
}

.table-more-rows-spinner {
  position: sticky !important;
  height: 96px;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-top: 48px;
  margin-bottom: 48px;
}

.z-index-0 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.input-text-sm,
.input-text-sm .q-field__control {
  max-height: 30px !important;
  min-height: 30px !important;
}

.input-text-sm {
  &.no-after-border {
    :before {
      border: 0;
    }

    :after {
      display: none;
    }
  }
}

.q-chip--dense {
  border-radius: 5px;
  padding: 0 0.4em;
  height: 1.5em;
  background: transparent;
  border: 1px solid #dee2e6;
  color: black;

  .q-chip__content {
    color: #1D1D1D;
    font-size: 13px;
  }
}

#communications-nav-list {
  .left-column-wrapper {

    .filter-group-title {
      padding: 10px 5px 10px 7px !important;
      color: #666666;
      letter-spacing: 1px;
      font-size: 10px;
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    .filter-items {
      color: #666666;
      margin: 7px 0;
      padding: 5px 12px;
      line-height: 1.2;
      height: 30px;

      span:not(.check-icon) {
        font-size: 14px;
        color: #666666;
        line-height: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
      }

      span.position-absolute.check-icon {
        right: 10px;
        top: 5px;
      }

      .b-dropdown {
        height: 10px !important;
        margin-top: 0 !important;
        right: 0;

        button {
          margin-top: -1px;
          background: none;
          padding: 2px;
          border: none !important;
          display: none;
          height: 20px !important;
          font-size: 10px;
        }

        .dropdown-menu.show {
          min-width: 5rem;

          .dropdown-item {
            padding: 0.25rem 1rem;
          }
        }
      }

      button:hover,
      button:focus,
      button:active {
        border: none;
      }
    }

    .filter-items:hover {

      &:not(.no-data) {
        background: $grey-50;
      }

      button {
        display: block;
      }
    }

    .filter-items {
      border-radius: 5px;
    }

    .filter-items.active {
      background: $grey-60;
    }

    .filter-group-title,
    .filter-type-description,
    .empty-filter-placeholder {
      //padding: 7px 12px;
    }

    .saved-filters {

    }
  }
}


#comms-channel-filter-modal {
  .filter-group-title {
    padding: 10px 5px 10px 7px !important;
    color: #666666;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .filter-items {
      margin: 7px 0;
      padding: 5px 12px;
    span:not(.check-icon) {
      font-size: 14px;
      color: #666666;
      line-height: 17px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

#inbox-channel-filter-modal {
  .filter-group-title {
    font-size: 12px;
    color: $grey-80;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .filter-items {
    span:not(.check-icon) {
      font-size: 13px;
      color: $grey-100;
      width: 95%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

#comms-channel-filter-modal,
#inbox-channel-filter-modal {
  .modal-content {
    border-radius: 8px;
    max-height: 92vh;

    .modal-body {
      overflow: hidden;
      padding: 28px 0 !important;
    }
  }

  .action-option-container {
    padding-right: 28px;
  }

  .filter-name {
    color: $grey-90;
    font-size: 14px;
  }

  .filter-type-description {
    font-size: 14px;
    font-weight: 600;
    color: $grey-100;
    display: block;
    width: 83px;
  }

  .left-column-wrapper {
    border-right: 1px solid $grey-60;
    padding-left: 10px;
    width: 25%;

    .left-column-wrapper-scrollable {
      max-height: 85vh;
      overflow: auto;
    }



    .filter-items {
      margin: 5px 0;
      padding: 7px 12px;
      line-height: 1.2;
      height: 30px;

      span.position-absolute.check-icon {
        right: 10px;
        top: 5px;
      }

      .b-dropdown {
        height: 10px !important;
        margin-top: 0 !important;
        right: 0;

        button {
          margin-top: -1px;
          background: none;
          padding: 2px;
          border: none !important;
          display: none;
          height: 20px !important;
          font-size: 10px;
        }

        .dropdown-menu.show {
          min-width: 5rem;

          .dropdown-item {
            padding: 0.25rem 1rem;
          }
        }
      }

      button:hover,
      button:focus,
      button:active {
        border: none;
      }
    }

    .filter-items:hover {
      &:not(.no-data) {
        background: $grey-50;
      }

      button {
        display: block;
      }
    }

    .filter-items {
      border-radius: 5px;
    }

    .filter-items.active {
      background: $grey-60;
    }
  }

  .right-column-wrapper {
    padding-left: 5px;
  }

  .q-field__focusable-action {
    font-size: 0.8em;
  }

  .q-toggle {
    margin-left: -10px !important;
  }

  .comm-owner-filter-tooltip-wrapper {
    position: absolute;
    top: -1px;
    right: 48%;
  }
}

.inbox-channel-filter-form {
  position: relative;
  overflow: auto;
  height: auto;
  max-height: 72vh;
  padding-right: 8px;


  .section-header {
    font-size: 14px;
    font-weight: 600;
    color: $grey-100;
  }

  .form-label {
    color: $grey-90;
  }

  .custom-control-input ~ .custom-control-label::before,
  .custom-control-input ~ .custom-control-label::after {
    cursor: pointer;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: $white;
    border-color: $success;
    background-color: $success;
  }

  .quick-access {
    .vue-daterange-picker {
      .reportrange-text {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: 992px) {
  #comms-channel-filter-modal .modal-lg,
  #comms-channel-filter-modal.modal-xl,
  #inbox-channel-filter-modal .modal-lg,
  #inbox-channel-filter-modal.modal-xl {
    max-width: 950px;
  }
}

#create-filter-modal {
  .modal-content {
    border-radius: 8px;
  }
}

div.q-menu {
  min-width: 0;
}

button.primary {
  color: $primary;
}

button.grey-90 {
  color: $grey-90;
}

.channel-filter-actions-wrapper {
  margin-top: 14px;

  .filter-toggle-button {
    max-width: 81px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
}

.btn.not-focusable:focus {
  box-shadow: none !important;
}

.tag-selected-chip {
  height: auto !important;

  .q-chip__content {
    white-space: initial;
  }
}

.bottom-border__none {
  .q-field__control:before {
    border-bottom: none !important;
  }

  .q-field__control:after {
    background: none !important;
  }
}

.q-field--highlighted.highlighted-primary {
  .q-field__native, .q-field__marginal {
    color: $primary
  }
}

.padding-left__none {
  .q-field__native {
    padding-left: 0 !important;
  }
}

.make-primary-label {
  display: inline-block;
  margin-top: 3px;
  cursor: pointer;
}

.bordered-right {
  border-right: 1px solid $grey-60;
}

.bordered-left {
  border-left: 1px solid $grey-60;
}

.bordered-top {
  border-top: 1px solid $grey-60;
}

.bordered-bottom {
  border-bottom: 1px solid $grey-60;
}

.bulk-action-menu {
  background: white;

  &__power-dialer,
  &__tags {
    margin-left: 40px !important;
    margin-top: 2px !important;
  }

  .menu-actions {
    min-height: 35px !important;
    position: relative;

    a {
      text-decoration: none;
      font-weight: 500;

      .fa {
        color: #6F6F6F;
      }

      svg {
        margin-top: -5px;
      }
    }

    .custom-checkbox-container {
      position: absolute;
      padding-right: 35px;
      margin-left: -0.25rem;
      z-index: 20;

      &:not(.checked) {
        &:not(.pd-add):not(.contacts) {
          top: 13px;
        }

        &.pd-add {
          top: 26px;
        }

        &.contacts {
          top: 20px;
        }
      }

      &.checked {
        top: 116px;
      }

      .checkmark {
        position: initial !important;
      }
    }
  }

  .menu-actions {
    font-size: 0.75rem;

    .items {
      margin-right: 80px;
    }
  }
}

.modal.br-8 {
  .modal-content {
    border-radius: 8px;
  }
}

.btn-grey-80 {
  color: $white;
  background-color: $grey-80;
  border-color: $grey-80;
}

#create-contact-modal {
  .scrollable {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.general-notification {
  position: relative;

  .q-notification__wrapper {
    display: contents;
  }

  .download-button {
    margin-top: 2px;
  }

  .close-button {
    background: transparent;
    border: none;

    .q-btn__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.bg-green-10 {
    border: 1px solid $green-90;
  }

  &.bg-red-10 {
    border: 1px solid $red-90;
  }

  &.bg-orange-2 {
    border: 1px solid $yellow-9;
  }

  .close-button {
    .q-btn__wrapper {
      min-height: auto !important;
      padding: 0;
    }

    .q-btn__content {
      display: flex;
      align-items: center;
      padding-top: 1px;
    }
  }

  .close-button {
    height: 17px;
    width: 23px;
    display: flex;
    align-items: center;
  }

  .q-notification__message,
  .close-button .q-btn__content .q-icon {
    @include font-size($f-size-h5, normal, true);
  }
}

.notification-border-round {
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.08), 0px 10px 20px -4px rgba(4, 4, 4, 0.2);
  border-radius: 12px;
}

#b-toaster-bottom-right,
#b-toaster-top-center {
  .b-toaster-slot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.b-toast {
  max-width: 300px !important;

  .action-notification {
    width: 300px !important;

    &.has-clear-queues {
      margin-top: 30px !important;
    }

    &.incoming-call-notification {
      .toast-header {
        display: none;
      }
    }

    .toast-header {
      .close {
        position: absolute;
        width: 18px;
        height: 18px;
        background: $grey-90;
        opacity: 0.7;
        color: white;
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        float: none;
        padding-bottom: 2px;
        border-radius: 50%;
        font-weight: 100;
        left: -5px;
        top: -5px;
      }
    }

    .toast-body {
      text-decoration: none;
      padding: 0 0 !important;

      > div {
        padding: 12px 12px 15px 12px;
      }

      .title {
        @include font-size($f-size-h5, 600, true);
        line-height: 18px;
        max-width: 162px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        @include font-size($f-size-h5, normal, true);
        line-height: 16px;
        padding-top: 1px;
      }

      .notification-icon {
        padding-top: 2px;
      }

      .message-body {
        @include font-size($f-size-body-xs, normal);
        line-height: 15px;
        padding-top: 1.39px;

        .message-text {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          .mention-tag {
            color: $grey-81 !important;
            background: unset;
            padding: 0 0 !important;
            font-weight: 600;
          }
        }

        .attachment {
          border-radius: 5px;
          max-width: 32px;
          height: 32px;
        }
      }
    }
  }
}


.btn-filter-wrapper {
  border-radius: 6px;
}

.btn-filter-wrapper.background {
  background: rgba(37, 110, 255, 0.12);
}

.v-divider {
  border: 1px solid $grey-60;
  height: 24px;
  margin-right: 10px;
}

/* The custom-checkbox-container */
.custom-checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: flex;
  height: 15px;
  width: 15px;
  background-color: $white;
  border: 1px solid $grey-60;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-red-80 {
  background: $red-80 !important;
}

.dropdown-white {
  height: 28px;

  &.dropdown-active {
    button {
      border: 1px solid $primary !important;
    }
  }

  button {
    background: $white !important;
    color: $grey-100 !important;
  }
}

.dropdown-white:not(.border-0) {
  button {
    // border: 1px solid $grey-70 !important;
    border: 1px solid $grey-70;
  }
}

.dropdown-white.contacts-options-dropdown {
  button {
    color: $grey-90 !important;
  }
}

.filter-toggle-button {
  height: 28px;
}


.title-static-icon {
  margin-right: 10px;
}

.mention-tag,
span.mention {
  color: $grey-100;
  font-weight: 500;
  background: #CDDCFF;
  border-radius: 4px;
  padding: 2px 5px;
}

.notes-body {
  p {
    display: -webkit-box;

    .mention,
    .mention-tag {
      background: none;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }
}

.custom-toggle-button {
  background: $grey-60;
  padding: 2px;

  button:not(:last-child),
  button:not(:first-child) {
    margin: 0 1px;
  }

  button.bg-transparent:hover {
    .task-count,
    .badge-active-count {
      background: transparent;
    }
  }

  button {
    border-radius: 5px !important;
    border: none !important;
    height: 24px;

    &[aria-pressed="true"]:hover {
      background: lighten($primary, 10%) !important;
    }

    &:not(.active) {
      .task-count,
      .badge-active-count,
      .broadcast-count {
        background: $grey-70 !important;
        color: $grey-90 !important;
      }
    }

    .options {
      font-size: 14px !important;
      font-weight: 500;
      width: 100% !important;
      text-align: center;
      font-family: 'Inter', sans-serif !important;

      .task-status-name,
      .toggle-button-name,
      .broadcast-filter-name {
        margin-top: -2px;
      }

      .task-count,
      .badge-active-count,
      .broadcast-count {
        background: rgba(255, 255, 255, 0.22);
        border-radius: 5px;
        font-size: 10px;
        font-weight: 600;
        padding: 0 2px;
        color: $white;
        height: 16px;
        margin-top: 1px;

        span {
          top: -3px;
          position: relative;
        }
      }
    }
  }
}

.channel-filter-actions-wrapper.inbox-tab--filter {
  .filter-wrapper {

    &.--highlighted {
      background: rgba(37, 110, 255, 0.12);
      padding: 0 11px;
      border-radius: 6px;
      height: 29px;
    }


    .filter-icon {
      margin-top: -4px;
    }
  }

  .search-icon {
    margin-top: -2px;
    height: 28px;
  }

  .q-separator {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 2px;
  }
}

.inbox-tab--filter {
  .filter-wrapper {
    //margin-left: 30px;

    .filter-icon {
      margin-top: -2px;
    }
  }

  .search-wrapper {
    .compact-button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.inbox-filter-actions-wrapper {
  width: 50%;
  margin-top: 15px;

  div.search-icon {
    margin-top: -2px;
  }

  .q-user-selector {
    width: 8vw;
  }

  .q-line-and-ring-group-selector {
    width: 8vw;
  }

  .generic-selector {

    margin-top: -3px;

    .q-field__control:before {
      border: none !important;
    }

    .q-field__native {
      padding-left: 20px !important;

      span {
        font-size: 14px;
        color: $grey-100;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 5.5vw;
        overflow: hidden;
      }
    }

    .q-field__input {
      font-size: 14px;
      font-weight: 500;
    }

    .q-field__marginal {
      font-size: 14px;
    }
  }
}

.mentions-filter-actions-wrapper {
  width: 50%;
  margin-top: 15px;

  .filter-wrapper {
    margin-left: 15px;
  }

  div.search-icon {
    margin-top: -2px;
  }

  .q-user-selector {
    width: 8vw;
  }

  .q-line-and-ring-group-selector {
    width: 8vw;
  }

  .generic-selector {

    margin-top: -7px;

    .q-field__control:before {
      border: none !important;
    }

    .q-field__native {
      padding-left: 20px !important;

      span {
        font-size: 14px;
        color: $grey-100;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 5.5vw;
        overflow: hidden;
      }
    }

    .q-field__input {
      font-size: 14px;
      font-weight: 500;
    }

    .q-field__marginal {
      font-size: 14px;
    }
  }
}

.ml-7 {
  margin-left: 5rem !important;
}

.settings-form-wrapper {

  padding-bottom: 5rem;

  .scrollableArea {
    overflow-y: auto !important;
    overflow-x: hidden !important;

    .datatable {
      margin-left: 0 !important;
    }
  }

  .form-label {
    font-weight: 500;
    font-size: 13px;
    color: $grey-100;
  }

  .form-helper-text {
    font-size: 12px;
    color: $grey-90;
    letter-spacing: -0.0025em;
    margin-top: 10px;
  }

  form {
    .form-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding: 0.5rem !important;
    }
  }

  .highlighted {
    background: #FFFBD4;
    border-radius: 6px;
  }

  .q-field--with-bottom {
    padding-bottom: 0 !important;
  }

  .q-field--error {
    .q-field__marginal {
      display: none !important;
    }
  }
}

.r-0 {
  right: 0 !important;
}

.q-item__label {
  font-weight: normal !important;
}

.flex-table {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.75em 0;
  height: 45px;
  width: 100%;
}

.flex-row.day {
  width: 110px;
}

.flex-row.toggle {
  width: 110px;
}

.business-hours-wrapper {
  .selector-container {
    width: 110px;
  }
}

.group-checkbox {
  .custom-checkbox {
    width: 120px;
  }
}

.recording {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 0 rgba(173, 0, 0, .3);
  }
  65% {
    box-shadow: 0 0 5px 13px rgba(173, 0, 0, .3);
  }
  90% {
    box-shadow: 0 0 5px 13px rgba(173, 0, 0, 0);
  }
}


.live-call-pulse {
  animation: live-call-pulse 2s infinite;
}

@keyframes live-call-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0,0,0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0,0,0, 0);
  }
}

.ring-group-list {
  li {
    list-style: circle;
    left: 0;
    margin-left: -22px;
  }
}


.custom-control {
  .custom-control-label {
    cursor: pointer;
    padding-top: 2px;
  }
}

.business-hours-container {

  font-family: 'Inter', sans-serif !important;

  div.hours {

    position: relative;

    select {
      border-radius: 6px;
      background: $white;
      height: 40px;
      padding: 0 10px;
      font-weight: 400;
      font-size: 14px;

      -webkit-appearance: none;
      appearance: none;
    }

    select:focus-visible {
      border: 2px solid $primary;
      outline-width: inherit;
    }
  }

  div.hours::after {
    content: "▼";
    //content: "\f078";
    font-size: 0.75rem;
    top: 12px;
    right: 10px;
    position: absolute;
  }

  .add-hours {
    font-weight: normal !important;
  }
}


.fixed-header {
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed-header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100px;
}

.fixed-header thead tr {
  display: block;
}

.fixed-header th, .fixed-header td {
  padding: 5px;
  text-align: left;
}

.filter-types {
  .q-field__native.row {
    .q-field__input {
      padding-left: 0 !important;
    }
  }

  .b-calendar-inner {
    min-width: 215px !important;
    width: 215px !important;
  }
}

.input-field-shadow {
  box-shadow: 0px 0px 0px 1.5px rgba(37, 110, 255, 0.12);
  border-radius: 6px;
}

.rounded-input {
  .q-field__control {
    border-radius: 6px;
  }
}

@media (min-height: 378px) {
  .sidebar-wrapper {
    & .sidebar {
      & .q-list {
        height: 100% !important;
      }
    }
  }
}

.menu-avatar-wrapper {

  .contact-info-wrapper {
    margin-right: 5px;
    margin-top: -10px;
  }

  .user-full-name {
    font-weight: 500 !important;
    font-size: $f-size-body !important;
    color: $grey-100;
    margin-top: 8px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .focus-mode-icon {
      margin-top: -2px;
      margin-left: 7px;
    }
  }

  .user-company-name {
    font-weight: 500 !important;
    font-size: $f-size-body-xs !important;
    color: $grey-80;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .q-btn__wrapper {
    min-height: 44px;
  }
}

.header-help-wrapper {
  .q-btn-dropdown__arrow {
    display: none;
  }

  .q-btn__wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .col {
    flex-basis: unset;
  }

  .header-help-icon-wrapper {
    color: #ad002b;
    font-size: 34px;
  }

  margin-right: 20px !important;
}

.header-help-dropdown-list {
  .q-btn:hover {
    text-decoration: none;
  }
}

.tab-avatar-menu {

  min-width: 227px !important;
  max-width: 227px !important;

  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px !important;

  .q-list {
    padding: 0;

    .q-item {
      padding: 0 17px 0 16px !important;
      font-size: $f-size-body !important;
      min-height: 33px !important;

      .rounded-badge {
        margin-right: 14px !important;
      }

      .focus-mode-icon {
        margin-right: 8px;
        margin-top: -2px;
      }

      .logout-icon {
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    .q-item:not(:first-child) {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .q-item:nth-child(6) {
      margin-top: 0 !important;
    }
  }
}

.badge-task-status {
  margin: 1px 0 0 12px;
}

.default-btn-padding {
  .q-btn,
  .btn {
    padding: 7px 12px !important;
    display: flex;
    align-items: center;

    .fa-chevron-down {
      height: 17px;
      display: flex;
      margin-left: 4px;
      align-items: flex-end;
    }
  }
}

.transparent {
  opacity: 0;
}

.q-menu.settings-menu-links {
  padding: 10px;

  .q-item {
    padding: 10px;
    border-radius: 6px;
  }
}

.settings-searcher {

  .q-field__prepend {
    i.material-icons.q-icon {
      font-size: 18px;
    }
  }

  .q-field__focusable-action.q-icon {
    font-size: 14px !important;
  }
}

.form-control.is-invalid,
.form-control.is-valid {
  background-image: none !important;
}

.form-control.is-valid {
  border-color: #ced4da !important;
}

.shared {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pinned,
.shared,
.folders {
  .folders__header button,
  .header__header__title {
    margin-left: 18px !important;
    margin-right: 10px !important;
    display: flex;
    align-items: center;
  }

  .item-empty {
    padding-top: 20px;
    padding-left: 18px;
  }

  &.public-lists .item-empty {
    padding-top: 4px;
  }
}

.contacts {
  &__title {
    height: 19px;

    .list-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .title-breadcrumb {
    font-family: Roboto;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $grey-90;
  }

  .title-breadcrumb {
    height: 19px;
    margin-right: 5px;
  }

  .title-breadcrumb-link {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 19px;
    margin-right: 5px;
    color: #256eff;
  }

  .title-path:not(:last-child) {
    .title-slash {
      height: 19px;
      margin-right: 5px;
    }
  }

  .title-path:last-child {
    .title-slash {
      height: 19px;
      margin-right: 15.5px;
    }
  }

  .contacts-left-sidebar {
    min-width: 260px;
    max-width: 260px;

    &.sidebar-1 {
      min-width: 280px;
      max-width: 280px;
    }

    &.minimized {
      min-width: 15px;
      max-width: 15px;
    }

    .contacts-sidebar-card {
      overflow-y: auto !important;

      .q-item.q-item-type {
        padding: 0 17px;

        .q-item__label {
          text-transform: uppercase;
          font-weight: 700 !important;
          font-size: 13px !important;
          color: #62666E !important;
        }
      }
    }
  }

  &-header-separator {
    margin: 0 15px;
  }

  .sessions-main-page {
    &.minimized {
      width: 100%;
    }
  }
}

.custom-control-input:disabled {
  opacity: 0 !important;
}

.inbox-search {
  border-radius: 0 !important;
  box-shadow: none !important;
  z-index: 1;
  padding: 0 15px;
  margin-top: -9px;
  border-bottom: 1px solid $grey-60;
  background: rgb(255 255 255);

  .q-item {
    padding: 0 !important;
    height: 38px;
    min-height: unset;

    label.q-field {
      height: 38px;
      margin-bottom: 0;
    }

    .q-field--filled .q-field__control {
      padding: 0;
      border-radius: 4px 4px 0 0;
      background: transparent;
    }

    .q-field--filled .q-field__control:before {
      background: transparent;
    }

    .q-field--filled .q-field__control:after {
      background: transparent !important;
    }

    .q-field--filled .q-field__control:before {
      border-bottom: none !important;
    }

    .q-field__marginal {
      font-size: 14px !important;
    }

    .q-field--filled .q-field__control:hover:before {
      opacity: 0 !important;
    }

    input {
      padding-left: 0 !important;
      font-size: 14px;
      font-weight: 500;
    }

    .q-field__append {
      padding-right: 0;
    }
  }
}

.mt-37 {
  margin-top: 37px;
}

.contact-details-container {
  max-width: 250px;
  width: 100%;

  &-drawer {
    aside {
      border-radius: 0.25rem;
      background: $grey-50;
      padding: 15px 0px 15px 15px;
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
      top: 0 !important;
      position: absolute !important;

      .details-component-container {
        padding-right: 15px;
      }
    }

    &__close {
      border-radius: 50%;
      background: $grey-90;
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 9;
      padding: 0 0;
      width: 24px;
    }

    .contact-details-wrapper .details-component-container {
      height: calc(100vh - 83px);
    }
  }

  .mobile-header {
    margin-left: -15px;
    margin-top: -15px;
    width: calc(100% + 15px);
    margin-bottom: 15px;
    background: white;
  }
}

.contact-activity-wrapper {
  width: 100%;
}

.contact-activities-actions {
  &__mobile {
    display: none;
  }

  &__drawer_btn,
  &__mobile_btn {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    min-height: 24px;
    display: flex;
    align-items: center;

    .q-btn__wrapper {
      padding: 0 0 !important;
    }

    .q-btn__wrapper,
    .q-btn__content {
      height: 30px;
      min-height: 30px;
    }
  }

  &-dropdown {
    width: 20px;
    height: 20px;
    margin-right: 5px !important;

    button {
      width: 100%;
      height: 100%;
    }
  }
}

.contact-activities-actions__mobile_btn {
  display: none !important;
}

.contact-details-container-drawer {
  .q-drawer__content {
    overflow: unset;
  }
}

.mobile-header {
  display: none;
}

.inbox-wrapper {
  display: flex;
  max-width: 558px;
  width: 100%;
  flex: 0 0 100%;

  &.no-max-width {
    max-width: unset!important;
  }
}

.unsupported {
  display: none !important;
  height: 0;
}

.dashboard {
  &:not(.contact-page) {
    .mobile-back-btn {
      display: none;
    }
  }
}

.mobile-back-btn {
  border-radius: 50%;
  height: 27px;
  width: 24px;
  min-height: 27px;
  align-items: center;
  margin-right: 5px;

  .q-btn__wrapper {
    padding: 0 0 !important;
  }

  .q-btn__wrapper,
  .q-btn__content {
    height: 27px;
    min-height: 27px;
  }
}

.b-toaster-slot {
  max-width: 399px !important;
  width: 100%;
}

.stats-container {
  height: 100vh;
  max-width: 100 wh;
}

.settings {
  &-side__right {
    .mobile-back-btn {
      display: none !important;
    }
  }
}

.contacts-total.mobile {
  display: none;
}

@media screen and (max-width: 1425px) and (min-width: 1085px),
screen and (max-width: 956px) {
  .contacts-total {
    &.mobile {
      display: none;
    }
  }
}

@media (max-width: 1485px) and (min-width: 1085px) {
  .inbox {
    .inbox-details {
      .contact-activity-container {
        .composer-footer:not(.is-widget) {
          flex-direction: column;

          .inline-select.q-select--with-input {
            min-width: unset;
          }

          & > div {
            width: auto !important;
            height: 24px;

            > div {
              float: none !important;

              .q-field__append {
                align-items: center;
                height: 29px !important;
              }
            }
          }

          .q-field__control {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media (max-width: 1220px) and (min-width: 1085px) {
  .contact-page {
    .contact-activity-container {
      .composer-footer:not(.is-widget) {
        flex-direction: column;

        .inline-select.q-select--with-input {
          min-width: unset;
        }

        & > div {
          width: auto !important;
          height: 24px;

          > div {
            float: none !important;

            .q-field__append {
              align-items: center;
              height: 29px !important;
            }
          }
        }

        .q-field__control {
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 956px) {
  .contact-activity-container {
    .composer-footer:not(.is-widget) {
      flex-direction: column;

      .inline-select.q-select--with-input {
        min-width: unset;
      }

      & > div {
        width: auto !important;
        height: 24px;

        > div {
          float: none !important;

          .q-field__append {
            align-items: center;
            height: 29px !important;
          }
        }
      }

      .q-field__control {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 650px) {
  .contact-activity-container {
    .composer-footer.is-widget {
      flex-direction: column;

      .inline-select.q-select--with-input {
        min-width: unset;
      }

      & > div {
        width: auto !important;
        height: 24px;

        > div {
          float: none !important;

          .q-field__append {
            align-items: center;
            height: 29px !important;
          }
        }
      }

      .q-field__control {
        flex-direction: column;
      }
    }
  }
}

@media (min-width: 1319px) {
  .contact-details-container {
    max-width: 330px;
  }
}

@media (min-width: 1160px) and (max-width: 1261px) {
  .inbox-side {
    &__left {
      max-width: 433px;
    }

    &__right {
      .custom-toggle-button {
        margin-left: 1px !important;
        margin-right: 1px !important;
      }
    }
  }
}

@media (max-width: 1261px) {
  .inbox-wrapper,
  .inbox-side {
    max-width: 433px;
  }
  .inbox-side {
    &__left {
      max-width: 185px;

      .count-label {
        margin-left: 10px;
      }
    }

    .inbox-nav-list {
      > div:not(:first-child) {
        .inbox-nav-item__label {
          min-width: 73.88px;
        }
      }
    }

    .inbox-nav-item__icon {
      display: none;
    }
  }
}

@media (max-width: 1159px) {
  .contacts-filter-sidebar {
    position: fixed;
    right: 0;
    z-index: 999;
  }

  .inbox-side {
    .inbox-nav-item__icon {
      display: block;
    }
  }
}

@media (min-width: 605px) and (max-width: 1159px) {
  .inbox-wrapper,
  .inbox-side {
    max-width: 313px;

    &__right.no-max-width {
      max-width: unset!important;
    }

  }
  .inbox-side {
    &__nav {
      padding-top: 2.92px;
    }
  }
  .contact-details-container {
    width: 250px;
  }
  .contact-activity-wrapper {
    width: 100%;
  }
  .inbox-wrapper {
    &:not(.inbox-side-mobile-full-width) {
      .inbox-side {
        &__left {
          .header,
          .count-label {
            display: none;
          }

          .inbox-nav-item {
            margin: 0 0 !important;
          }
        }
      }
    }

    &.inbox-side-mobile-full-width {
      max-width: unset;

      .inbox-side {
        max-width: unset;
      }
    }
  }
  .contact-page {
    .contact-view-wrapper {
      .contact-list-sidebar-container {
        width: 270px;
      }
    }
  }
  .contacts-filter-sidebar {
    width: 300px;
  }
  .main-content {
    .contacts {
      > .main .mycard > div:first-child,
      .table-header > div {
        padding: 0 16px !important;
      }

      .table-header {
        > div {
          > .row {
            justify-content: space-between;

            > div {
              width: auto !important;
              flex: unset;
              max-width: unset;
            }

            > div:first-child {
              > div:nth-child(2) {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1084px) {
  .contact-activities-actions-dropdown {
    height: 24px;
    width: auto;
  }
  .contact-activity-container {
    &.contact-activity--closed {
      .contact-activities-actions {
        margin-right: 22.81px;

        &__mobile {
          display: flex;
          align-items: center;
        }

        &__desktop {
          display: none;
        }

        &-dropdown {
          button {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            padding: 0 0 !important;
          }

          button:not(:hover):not([aria-expanded="true"]) {
            border: 1px solid white !important;
          }
        }
      }
    }
  }
  .contact-details-container {
    width: 0;
    margin: 0;

    &:not(.contact-details--opened) {
      .mobile-header {
        margin: 0;
      }
    }
  }
  .contact-activities-actions__mobile {
    display: flex;
  }
  .contact-activities-actions__desktop {
    display: none !important;
  }
}

@media (max-width: 1019px) {
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            .contacts-header-separator {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1019px) {
  .contacts-total.desktop {
    padding-left: 10px;
  }
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            > div:nth-child(2) {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .contacts-total {
    white-space: nowrap;
    margin-left: 15px;
  }
  .datatable-wrapper {
    .scrollableArea:not(.mobile-scrollableArea) {
      max-height: calc(100vh - 195px);
    }
  }
  .main-content {
    .contacts {
      > .main .mycard > div:first-child,
      .table-header > div {
        padding: 0 16px !important;
      }

      .table-header {
        > div {
          > .row {
            flex-flow: column-reverse;

            > div {
              justify-content: space-between;
              width: 100%;
              padding: 0 0 !important;

              .contacts-options-dropdown {
                margin-right: 0 !important;
              }
            }

            > div:first-child {
              .width-250 {
                width: 100%;
              }
            }

            > div:nth-child(2) {
              justify-content: flex-end;

              > div:first-child {
                display: none;
              }

              > div:nth-child(2) {
                padding-left: 0 !important;
              }
            }
          }
        }
      }

      .contacts-total {
        &.mobile {
          display: block !important;
        }

        &.desktop {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 785px) and (max-width: 1084px) {
  .contact-details-container {
    &-drawer {
      &.has-contact-changes aside {
        height: calc(100% - 65px);
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 1084px) {
  .inbox-side {
    height: 100%;
  }
}

@media (max-width: 784px) {
  .page-layout {
    .sidebar-active {
      .page-header + .page-container {
        padding-top: 62px;
      }
    }
  }
  .mobile-phone-drawer {
    &:not(.mobile-phone-visible) {
      .phone-padding {
        padding: 0 16px;
      }
    }
  }
  .dialer-wrapper {
    &:not(.hide) {
      display: grid !important;
      grid-template-rows: 1fr auto;

      &.on-text-tab {
        .mobile-parked-calls-list {
          max-height: calc(100vh - 413px);
        }
      }

      &.on-call-tab {
        .mobile-parked-calls-list {
          max-height: calc(100vh - 343px);
        }
      }
    }

    &.hide {
      display: none !important;
    }
  }
  .mobile-back-btn-global-header {
    transform: translateX(-10px);
    margin-right: 4px;
  }
  /*.main-content {
    padding-top: 62px;
  }*/
  .general-notification {
    margin-left: 5px !important;

    &:last-child:not(:only-child),
    &:only-child {
      margin-bottom: 50px !important;
    }
  }
  .b-toaster {
    &.b-toaster-bottom-right {
      bottom: 50px !important;

      .b-toaster-slot {
        max-width: 300px !important;
      }
    }
  }
  .dashboard:not(.communications-page) {
    .h-1-livebar,
    .h-2-livebar {
      .sidebar-active {
        > .page-container {
          padding-top: 62px;
        }
      }
    }
  }
  .h-2-livebar {
    .stats-container {
      height: calc(100vh - 153px);
    }

    .sidebar-active {
      > .page-header {
        top: 45px !important;
      }
    }
  }
  .h-1-livebar {
    .stats-container {
      height: calc(100vh - 129px);
    }

    .sidebar-active {
      > .page-header {
        top: 20px !important;
      }
    }
  }
  .stats-container {
    padding-bottom: 0 !important;
    height: calc(100vh - 107px);

    .stats-header {
      .q-card__actions {
        padding-bottom: 1rem !important;
      }
    }

    .stats-metrics-container {
      padding-bottom: 0 !important;
    }
  }
  .contacts-total {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block !important;
    }
  }
  .page-header {
    height: 58px;

    &.dialer-header {
      left: 0 !important;
    }

    & > div {
      &:first-child {
        flex: 1;
      }

      min-width: 0;

      & > h1 {
        font-size: 18px;
        font-family: $font-family-body;
      }
    }

    &.q-toolbar {
      height: 58px;
      padding: 0 15px 0 18.5px !important;
      justify-content: space-between;
      flex: 1 0 100%;
    }
  }
  .mobile-back-btn {
    display: flex !important;
  }
  .page-footer {
    border-top: solid 1px #e4e4e4;
  }
  .inbox {
    &-side,
    &-details {
      border-bottom: unset;
    }
  }
  .sidebar-wrapper {
    display: none !important;
  }
  .page-layout {
    padding-bottom: 49px;

    .page-container {
      padding-left: 0 !important;
    }

    .page-footer,
    .page-footer .dropdown-menu.show {
      z-index: 999;
    }
  }
  .sidebar-active {
    .q-header {
      left: 0 !important;
      display: flex;
      justify-content: flex-start;

      .page-header {
        & > div:nth-child(2) > div > div:not(.menu-avatar-wrapper),
        & > div:nth-child(2) > div > hr:not(.menu-avatar-wrapper),
        & > div:nth-child(3) {
          display: none !important;
        }
      }
    }
  }
  .contact-list-sidebar-wrapper {
    .card {
      .list-group-item {
        > div {
          flex: 1 0 100%;
        }
      }
    }
  }
  .main-content {
    .contacts {
      .main {
        width: calc(100vw - 260px);
      }

      .table-header {
        > div {
          > .row {
            flex-flow: column-reverse;

            > div {
              justify-content: space-between;
              width: 100%;
              padding: 0 0 !important;
            }
          }
        }
      }
    }
  }
  .contact-details-container {
    &-drawer {
      &.has-contact-changes aside {
        height: calc(100% - 16px);
      }
    }
  }
  .notification-container-header {
    display: none !important;
  }
}

@media (min-width: 605px) and (max-width: 780px),
(max-width: 500px) {
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            > div:nth-child(2) {
              > div:nth-child(3) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 721px) {
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            flex-flow: column-reverse;

            > div {
              justify-content: space-between;
              width: 100%;
              padding: 0 0 !important;

              .contacts-header-separator {
                margin: 0 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 605px) and (max-width: 721px) {
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            > div:nth-child(2) {
              > div:nth-child(2) {
                padding-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 605px) and (max-width: 670px),
(max-width: 425px) {
  .main-content {
    .contacts {
      .table-header {
        > div {
          > .row {
            > div:nth-child(2) {
              > div:nth-child(2) {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 667px) {
  .contact-activity-container .calls-header__label {
    padding-left: 15px;
  }
}

@media (max-width: 605px) {
  .stats-container {
    .stats-header {
      .q-btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 604px) {
  .contact-activities-actions {
    margin-right: 5px;
  }
  .profile-menu {
    padding-right: 0 !important;
  }
  .mobile-header {
    display: flex !important;
  }
  .inbox-page {
    .page-header {
      display: none;
    }
  }
  .inbox-wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .mobile-header {
    height: 58px;
    width: 100%;
    padding-left: 10px;
    border-bottom: 1px solid $grey-60;

    span:not(.label-my-contacts) {
      color: $grey-92;
      font-size: 18px;
      font-weight: 600;
      font-family: $font-family-body;
    }
  }
  .contact-activities-actions__desktop {
    display: none;
  }
  .sidebar-active .q-header {
    justify-content: flex-start;
  }
  .contact-activities-actions {
    &__drawer_btn {
      display: none;
    }

    &__mobile_btn {
      display: flex !important;
    }
  }
  .inbox {
    overflow-x: hidden;

    &-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      max-width: unset;

      &.mobile-contact-active {
        flex: 0 0 auto !important;
        width: 0 !important;
      }
    }

    &-side,
    &-details {
      border-left: unset;
      border-right: unset;
    }

    &-side {
      max-width: unset;
      overflow-x: inherit;

      &__left {
        max-width: unset;
        min-width: unset;
        z-index: 1;

        &.inbox-side__left--closed {
          width: 0 !important;
          height: 0;
        }
      }

      &__right {
        width: 0;
        z-index: 2;

        .task-list-scroller {
          padding-bottom: 60px;
        }

        &.inbox-side__right--opened {
          width: 100% !important;
        }

        &:not(.inbox-side__right--opened) {
          display: none !important;
        }

        .calls-header__label {
          padding-right: 0 !important;

          .channel-filter-actions-wrapper {
            margin-left: 17px !important;
          }

          .q-select {
            margin-right: 17px !important;

            .q-field__append {
              padding-right: 0;
            }
          }
        }

        .task-list .task-item {
          padding-right: 14px !important;
        }
      }
    }
  }
  /*.inbox-side__left,
  .inbox-side__right,
  .inbox-details,
  .contact-activity-wrapper {
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }*/
  .settings {
    &.page-side-menubar__left {
      max-width: unset;
    }

    &-content-wrapper {
      padding-right: 0 !important;

      & > .row {
        width: 100%;

        .settings-form-wrapper,
        .settings-form-wrapper .container {
          padding-right: 0 !important;
          margin: 0 0 !important;
        }

        .settings-form-wrapper {
          margin: 0 0 !important;
          padding: 0 18.5px !important;

          .container {
            max-width: unset;

            .form-row,
            .form-row > div {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }

            .form-row:first-child,
            .row:first-child {
              > div > div > button {
                transform: translateX(-8px);
                margin-top: 0.5rem !important;
                height: 24px !important;
                min-height: 24px !important;
                max-height: 24px !important;
                margin-right: 0;
              }

              h1 {
                line-height: 1;
                font-size: 18px;
                display: flex;
                align-items: center;
              }
            }

            .row.row-no-padding {
              & > div,
              & > div table {
                padding-left: 0 !important;
                padding-right: 0 !important;

                th:nth-child(2),
                th:nth-child(3) {
                  min-width: 170px !important;
                }
              }
            }

            .business-hours-container {
              .flex-row:first-child {
                min-width: 86px;
              }
            }

            #voicemail-drop-library-container {
              table tbody tr td audio {
                max-width: 250px;
              }
            }
          }
        }
      }
    }

    &-form-wrapper,
    &-form-wrapper .container {
      padding-left: 0 !important;
    }

    &-side {
      max-width: unset;
      overflow-x: inherit;

      &__left {
        max-width: unset;
        min-width: unset;
        width: 100%;
        z-index: 1;

        &.settings-side__left--closed {
          width: 0 !important;
          height: 0;
        }

        .page-side-menubar-list-item {
          background-color: unset !important;
        }
      }

      &__right {
        width: 0;
        z-index: 2;

        &.settings-side__right--opened {
          width: 100% !important;
        }

        &:not(.settings-side__right--opened) {
          display: none !important;
        }

        .mobile-back-btn {
          display: flex !important;
        }
      }
    }
  }
  .contact-activity-wrapper.contact-activity--closed {
    width: 0 !important;
    height: 0;

    * {
      display: none;
    }
  }
  .contact-details-container.contact-details--opened {
    padding-left: 15px;
    padding-top: 15px;
    width: 100% !important;
    display: block !important;
    max-width: unset !important;

    .details-component-container {
      > div {
        margin-right: 10px !important;
      }
    }

    .mobile-header {
      padding-right: 13px !important;

      .profile-menu {
        min-width: 63px;
      }
    }

    .menu-avatar-wrapper {
      padding-top: 0 !important;
    }
  }
  .contact-activity-container {
    .calls-header {
      &__label {
        padding-left: 10px;

        span {
          display: flex;
          align-items: center;
        }
      }

      .profile-menu {
        min-width: 63px;
      }
    }

    .contact-activities-actions-dropdown {
      height: 27px;
      width: auto;
    }
  }
  .mobile-more-dropdown {
    left: unset !important;
    top: unset !important;
    right: 0;
    margin-right: 10px;
    transform: unset !important;
    bottom: 49px;
  }
  .calls-header {
    height: 58px;
    padding-right: 15px !important;
  }
  .calls-header {
    &__label {
      .contact-name {
        font-size: 18px;
      }
    }
  }
  .contact-list-sidebar-container {
    &.contact-list-sidebar-open {
      flex: 1 0 100%;
    }

    .contact-list-sidebar-wrapper {
      .card {
        width: 100%;
        padding-bottom: 50px;

        .list-group {
          padding-right: 0.5rem !important;
        }
      }
    }
  }
  .contacts {
    .contacts__title,
    .mobile-back-btn .q-btn__content {
      height: 27px
    }

    .main {
      .mycard {
        padding-top: 15px !important;
      }
    }
  }
  .contacts-filter-sidebar {
    width: 260px;
  }
}

@media screen and (min-width: 400px) {
  #callFishing__toast_outer {
    &.b-toast,
    .action-notification.in-progress {
      max-width: 399px !important;
      width: 100% !important;
    }

    .action-notification {
      margin: 0 auto;

      &.in-progress {
        button {
          padding: 0 17px;
        }
      }

      .toast-body {
        > .notification-body-wrapper {
          > div {
            > div:nth-child(3) {
              > button:first-child {
                svg {
                  margin-right: 2px !important;
                }
              }
            }
          }
        }
      }

      .toast-body {
        > .notification-body-wrapper {
          > div {
            .notification-icon {
              max-width: 32px;
              width: 100%;
            }

            > .notification-details {
              max-width: 150px;
              width: 100%;
              flex-grow: unset !important;
            }

            > .call-actions {
              max-width: 84px;
              width: 100%;
            }
          }
        }
      }

      .message-text {
        &.has-ring-group {
          .campaign-wrapper,
          .ring-group-wrapper {
            padding: 0 0;
          }
        }

        div:nth-child(2) {
          padding: 0 5px;
          max-width: 17px;
          min-width: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 399px) {
  #callFishing__toast_outer {
    .action-notification.in-progress {
      max-width: 210px !important;
      width: 100% !important;
    }

    .action-notification {
      margin: 0 auto;

      .message-text {
        &.has-ring-group {
          .campaign-wrapper,
          .ring-group-wrapper {
            padding: 0 0;
          }
        }

        div:nth-child(2) {
          padding: 0 5px;
          max-width: 17px;
          min-width: 17px;
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .paginated {
    .table-pagination {
      .q-btn__wrapper {
        padding: 0 0 !important;
      }
    }
  }
}

@media (max-width: 325px) {
  .paginated {
    .table-pagination {
      button {
        margin: 0 0 !important;
      }

      .q-btn__wrapper {
        padding: 0 0 !important;
      }
    }
  }
}

@media (max-width: 306px) {
  .contacts {
    .main {
      .mycard {
        .datatable-wrapper {
          .q-select-pager {
            margin-left: 18px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 299px) {
  .unsupported {
    display: flex !important;
    height: 100%;
  }
  .page {
    display: none;
  }
}

@media screen and (max-height: 500px) {
  .unsupported {
    display: flex !important;
    height: 100%;
  }
  .page {
    display: none;
  }
}

.mark-all-as-read-icon {
  margin-top: -2px;
}

.dropdown-icon {
  margin-right: 10px;
}

.border {
  border: 1px solid $grey-60;

  &-bottom {
    border-bottom: 1px solid $grey-60 !important;
  }

  &-top {
    border-top: 1px solid $grey-60 !important;
  }

  &-left {
    border-left: 1px solid $grey-60 !important;
  }

  &-right {
    border-right: 1px solid $grey-60 !important;
  }
}

.border-required {
  border: 1px solid $danger;
}

.stats-refresh-btn {
  height: 32px;
  font-size: $f-size-body-sm;
  margin-left: 15px;
  font-size: $f-size-h5;
  font-weight: 500;

  &:hover {
    border-color: #000;
    border: 1px solid #000 !important;
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
  }

  svg {
    margin-right: 5px;
  }
}

.contact-popover {
  border-radius: 8px;
  padding: 6px 6px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);

  .arrow {
    display: none;
  }

  .contact-menu-item {
    padding: 6px 10px;
    border-radius: 6px;

    &__icon {
      margin-right: 10px;
      width: 16px;
    }

    &__title {
      span {
        color: $grey-100;
      }
    }
  }
}

.filter-toggle-button,
.contacts-options-dropdown,
.tags-table {
  .dropdown-menu {
    border-radius: 8px;
    padding: 6px 6px;
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);

    li {
      a {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 500;

        &:not(.disabled) {
          color: $grey-100;

          i {
            color: $grey-90;
          }
        }

        i {
          margin-right: 14px;
        }

        svg {
          width: 16px;
          margin-right: 8px;
        }
      }
    }
  }
}

.hours {
  position: relative;
}

.fw-500 {
  font-weight: 500 !important;
}

.q-item--active.text-primary {
  .q-item__label {
    color: $white !important;
  }
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
}

.workflow-selector {
  max-width: 310px !important;
}

.mobile-phone-drawer {
  &:not(.mobile-phone-visible) {
    height: 100%;
  }

  &.mobile-phone-visible {
    height: calc(100vh - 50px);
  }

  .q-header {
    left: 0 !important;
    display: flex;
    justify-content: flex-start;

    .page-header {
      & > div:nth-child(2) > div > div:not(.menu-avatar-wrapper),
      & > div:nth-child(2) > div > hr:not(.menu-avatar-wrapper),
      & > div:nth-child(3) {
        display: none !important;
      }
    }
  }

  .phone-header-title {
    font-size: 34px;
    font-weight: 600
  }

  .phone-header {
    position: fixed;
    z-index: 9;
  }

  .dialerForm {
    padding-top: 75px !important;
  }

  .q-drawer__backdrop {
    display: none;
  }

  aside {
    max-width: 784px !important;
    width: 100% !important;
    position: absolute !important;

    .q-drawer__content {
      & > div {
        width: 100% !important;

        &.row {
          display: flex;
          flex-direction: column;
          padding-bottom: 0 !important;

          &.dialerForm {
            height: 100%;
          }

          &:not(.dialerForm) {
            height: calc(100% - 51px);
          }

          &.loading-cover-screen {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: $blue-80;
            padding-top: 0 !important;

            .loading-container {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .dialer-tabs {
          flex-direction: column !important;
          height: 100%;

          & > div {
            padding: 0 0 !important;
            border: unset;

            ul[role='tablist'] {
              background: $grey-60;
              border: 2px solid $grey-60;
              border-radius: 6px;
              display: flex;
              flex-direction: row !important;
              margin-bottom: 15px;

              li {
                flex: 1;

                .nav-link {
                  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
                  font-size: $f-size-h5;

                  &.active {
                    background-color: #FFFFFF !important;
                    color: $grey-100;
                    border-radius: 5px;
                    font-weight: 600;
                  }

                  &:not(.active) {
                    color: #62666E;
                    border-radius: unset;
                  }
                }
              }
            }
          }

          .tab-content {
            display: flex;
            height: 100%;
            width: 100%;

            .tab-pane {
              width: 100%;
            }
          }

          .mobile-message-composer {
            textarea {
              font-size: 16px !important;
            }
          }
        }

        .tab-content {
          .tab-pane {
            .dialer {
              justify-content: flex-start !important;

              & > .form-group {
                flex: 1;

                .search-form {
                  width: 100% !important;
                }
              }

              .q-btn {
                margin-left: 17px;
              }
            }
          }
        }
      }

      .line-selector {
        label,
        .generic-selector .q-field__control,
        .generic-selector .q-field__control:after,
        .generic-selector .q-field__prepend,
        .generic-selector .q-field__append,
        .generic-selector .q-field__append,
        .generic-selector .q-field__control-container,
        .generic-selector .q-field__native {
          min-height: 40px !important;
        }

        label {
          height: unset !important;
          padding-bottom: 0 !important;
        }

        .q-field__prepend span,
        .q-field__native span,
        .q-field__control-container input {
          font-size: $f-size-h3;
        }
      }

      .search-form {
        .input-group *,
        input::-webkit-input-placeholder {
          font-size: $f-size-h3 !important;
        }

        .form-control {
          min-height: 40px !important;
        }
      }

      .dialer-contact-info {
        width: 100% !important;

        div {
          font-size: $f-size-body-sm;
        }
      }

      .invalid-feedback {
        font-size: $f-size-body-sm;
      }

      .phone {
        position: unset;
        height: 100%;
        overflow-y: scroll;
        border-radius: unset;
        padding-bottom: 44px;

        .mobile-live-call-bar {
          z-index: 10;
          position: absolute;

          .dropdown-menu {
            max-width: 375px;
            width: 100%;
            top: 23px !important;
            right: 0;
            margin: auto;
            transform: unset !important;

            &.bg-grey-93 {
              .dropdown-item:hover {
                background: $grey-94;
                color: #FFF;
              }
            }
          }
        }

        #signal-strength {
          height: 20px;

          li {
            width: 4px;

            &:not(:last-child) {
              margin-right: 2px;
            }

            &.very-weak {
              padding-top: 17px;
            }

            &.weak {
              padding-top: 12px;
            }

            &.strong {
              padding-top: 6px;
            }
          }
        }

        &-header {
          &:not(.call-ended) {
            height: 92px;
          }

          &.call-ended {
            height: 88px;
          }

          &.call-ended + .phone-body {
            margin-top: 88px;

            .phone-wrap-up {
              padding-top: 0;
            }
          }

          padding-bottom: 10px;

          div:last-child {
            button:last-child {
              display: none;
            }
          }

          div:first-child {
            span {
              font-size: 14px;
            }
          }

          div:nth-child(2) {
            span {
              font-size: 16px;
            }
          }

          div:nth-child(3) {
            width: 80px;

            svg {
              width: 22px !important;
              height: 22px !important;
            }

            button {
              .height-12 {
                height: 20px;
                width: 19px;

                img {
                  width: 19px;
                  height: 20px;
                }
              }
            }
          }

          .phone-settings-icon {
            font-size: 16px !important;
          }
        }

        &-body {
          .phone-wrap-up {
            max-height: unset;
            padding-top: 36px;
            padding-bottom: 76px;
          }

          .phone-info {
            margin-top: 20px;
            width: 100%;

            .q-item__label:first-child {
              margin-top: 25px !important;

              > span {
                font-size: 24px;
                font-weight: 600;
              }

              button {
                display: block;
                font-size: 18px;
              }
            }

            .q-item__label:nth-child(2) {
              margin-top: 10px !important;

              span {
                font-size: 16px;
              }
            }

            .q-item__label:nth-child(3) {
              margin-top: 10px !important;

              span {
                font-size: 14px;
                color: $grey-90;
              }
            }
          }
        }

        &-main {
          .dummy {
            height: 92px;
          }

          .phone-avatar {
            position: fixed;
            top: 68px;
            margin-top: unset;
            z-index: 9
          }
        }

        .actions-block {
          padding: 0 21px !important;
          max-width: 400px;
          margin-top: 50px !important;
        }

        &-expansion {
          .q-expansion-item {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;

            .q-item {
              height: 44px;

              .q-item__label {
                font-size: 16px;
                font-weight: 600 !important;
                color: $grey-90;
              }
            }

            &__toggle-icon {
              font-size: 25px;
              color: $grey-90;
            }

            &__content {
              .q-card__section {
                & > div {
                  justify-content: space-between !important;
                  max-width: 400px;
                  margin: auto;
                }
              }
            }
          }
        }

        &-buttons.elevated {
          max-width: 98px;
          width: 100%;
          height: 98px;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }

        &-footer-buttons {
          border-radius: unset !important;
          padding: 18px 16px !important;
          height: unset !important;
          position: fixed;
          bottom: 0;
          width: 100%;
          box-shadow: 0px -5px 10px 0px rgb(4 4 4 / 20%) !important;

          button:first-child {
            background: $success;
            color: #FFFFFF;
            margin-right: 10px;
          }

          button {
            height: 40px;
            max-width: 166px;
            width: 100%;
          }
        }
      }
    }
  }
}

.q-tree {
  width: 100%;

  &__node {
    &-header {
      &:before {
        width: 10px !important;
      }

      &-content {
        padding-left: 0 !important;
      }
    }

    &--child {
      padding: 0 0 3px 22px !important;
    }

    &-collapsible {
      .q-tree__node {
        &-header {
          > div {
            margin-left: -20px;
          }
        }
      }
    }
  }
}

.mobile-dialer-button {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    border-radius: 6px;
    font-size: $f-size-h3;
    font-weight: 500;
    height: 48px;
    margin: 0 0 13px 0 !important;
    padding: 0 10px;
    min-width: 0;
    flex: 1;

    &:hover {
      background: $success !important;
      color: white;
    }

    svg {
      margin-right: 8px;
    }

    .contact-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.b-toaster {
  z-index: 9999;
}

.notes-wrapper {
  border-radius: 12px;
  padding: 14px;
  background: linear-gradient(0deg, $blue-70, $blue-70);
  border: 1px solid rgba(47, 128, 237, 0.25);

  .q-item {
    padding: 0 !important;
    min-height: 10px !important;
  }
}

.contact-sidebar-list-wrapper {
  .folders__header {
    .header__header__title {
      margin-top: -1px;
      position: relative;
    }
  }
}

.q-select-auto-width {
  .q-field__control-container {
    width: auto !important;
    max-width: fit-content;

    .q-field__native {
      padding-right: 6px !important;
    }
  }
}

.vue-daterange-picker {
  width: 100%;
}

.last-engagement-tooltip-wrapper {
  position: absolute;
  right: 50%;
  top: -1px;
}

.last-engagement-dynamic-tooltip-wrapper {
  position: absolute;
  right: 40%;
  top: -1px;
}

.bridge-menu-wrapper {
  margin-right: 20px;
}

.q-shared-login-menu-dropdown {
  box-shadow: none !important;
  margin: 0 !important;

  button:first-child {

    text-transform: none;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    border-right: none !important;

    .q-btn__wrapper {
      padding-right: 0 !important;
    }

    .q-btn__content {
      i {

      }

      span {
        margin-left: 10px;
        color: $grey-100 !important;
      }
    }
  }

  button:nth-child(2) {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;

    .q-btn__wrapper {
      padding-left: 0 !important;
      padding-right: 6px !important;
    }
  }

  button {
    border: 1px solid $grey-70;
    color: $grey-90;
    background: transparent !important;

    &:hover,
    &:active {
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    .q-btn__wrapper {
      color: $grey-90 !important;
    }
  }

  .q-separator {
    background: $grey-70 !important;
    height: 19px;
  }
}

.q-shared-login-menu-dropdown-list {
  padding: 15px !important;
  width: 180px !important;

  .q-item {
    min-height: 16px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:not(:last-child) {
      margin-bottom: 13px;
    }

    .custom-control-label {
      color: $grey-100 !important;
    }
  }

}

.shared-login-menu-dropdown {
  button {
    border: 1px solid $grey-60;
    color: $grey-90;

    &:hover,
    &:active {
      background-color: transparent !important;
      border-color: $grey-60 !important;
      color: $grey-90 !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  button:not(.dropdown-toggle) {
    color: $grey-100 !important;

    i {
      color: $grey-90 !important;
    }
  }
}

.my-lists {
  .q-expansion-item__content {
    > .folders {
      .folders__content {
        overflow-x: hidden;

        .tree-folder {
          > .folder,
          .tree-list-item {
            min-width: 0;
            padding-right: 17px;
          }
        }
      }
    }
  }
}

.public-lists {
  .item {
    min-width: 0;
    padding-right: 17px;
  }
}

.w-0 {
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}

.no-min-max-width {
  min-width: unset !important;
  max-width: unset !important;
}

.textual-filters {
  .filter-item {
    .card-body {
      padding-right: 26px;
    }
  }
}

.q-tooltip.b-tooltip {
  padding: 0 0 !important;
  max-width: 180px;
  width: auto;

  .tooltip-inner {
    background-color: unset;
    font-size: 10px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: $grey-100;
    text-align: left;
  }

  .arrow:before {
    display: none;
  }
}


.message-composer-send-dropdown-button {
  border-radius: 6px !important;

  button:first-child {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  button:nth-child(2) {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }


  .q-btn {
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 600;
    height: 32px;

    .q-btn__wrapper {

      .q-btn__content {
        height: 32px !important;
      }
    }
  }
}

.message-composer-send-dropdown-button-list {

  .q-item {

    padding: 0 12px !important;

    .q-item_-section {
      .q-item__label {

      }
    }
  }

}

.message-composer-send-button {
  text-transform: none !important;
  border-radius: 6px !important;

  span.q-btn__wrapper {
    span.q-btn__content {
      padding: 0 12px;
      font-weight: 600;
    }
  }

  .q-btn__wrapper:before {
    box-shadow: none !important;
  }
}

.call-fishing-actions {
  padding-top: 2px;

  > button:first-child {
    margin-right: 10px;
  }

  button {
    .q-btn__wrapper {
      margin: 0 0;
      padding: 0 0;
      min-height: 32px !important;

      &:before {
        box-shadow: unset;
      }
    }

    span {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .b-compact-dropdown-button {
    margin: 0 0 !important;

    button {
      border: unset;
      padding: 0 0 !important;
    }

    ul {
      margin-top: 8px;
      padding: 6px 6px;
      background: rgba(21, 22, 63, 0.8) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px -2px rgba(0, 0, 0, 0.08) !important;
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      border-radius: 8px;

      li a {
        color: white;
        padding: 6px 12.65px;

        &:not(svg) {
          font-size: 13px;
        }

        .icon-margin {
          margin-right: 8px;
        }
      }

      &[x-placement="bottom-end"] {
        width: 298px;
        transform: translate(-249px, 40px) !important;
      }

      &[x-placement="right-start"] {
        margin: 0 !important;
        transform: translate(36px, -6px) !important;
      }
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background: #585A6D;
      border-radius: 8px;
    }
  }
}

.incoming-call-notification {
  .message-text {
    -webkit-line-clamp: unset !important;
    -webkit-box-orient: unset !important;

    &.has-ring-group {
      overflow: auto !important;
      text-overflow: unset !important;

      .campaign-wrapper,
      .ring-group-wrapper {
        min-width: 0;
      }

      .campaign-wrapper .campaign-name,
      .ring-group-wrapper .ring-group-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:not(.has-ring-group) {
      display: block !important;
      white-space: nowrap;
    }
  }
}

.b-radius-12 {
  border-radius: 12px;
}

.notification-body-wrapper {
  /* cursor: pointer; */
  .call-actions {
    padding-top: 2px;

    button:first-child {
      margin-right: 10px;
    }

    .q-btn__wrapper {
      min-width: unset;
      min-height: unset;
    }
  }
}

.shared-login-menu-dropdown.dropdown.show {
  .dropdown-toggle {
    border: 1px solid $grey-60 !important;
    background-color: transparent !important;
    color: $grey-90 !important;
  }
}

.q-btn-standard {
  border: 1px solid $grey-70;
  color: $grey-90;
  text-transform: none !important;
  padding: 0 14px 0 10px;
  border-radius: 6px;

  .q-btn__wrapper:before {
    border: none !important;
  }

  .q-btn__wrapper {
    .q-btn__content {
      color: $grey-90;

      span {
        margin-left: 7px;
        color: $grey-100;
      }
    }
  }
}

.background-blur {
  box-shadow: 0 10px 20px -4px rgba(4, 4, 4, 0.2);
  border-radius: 8px;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 8px;
  }
}

.live-call-options {
  padding: 6px;

  .q-list {
    .q-item {
      padding: 5px 10px;
      border-radius: 6px;
      min-height: 28px !important;

      .q-item__section {
        display: block !important;
        min-height: 0 !important;

        svg:not(.hangup-icon) {
          margin-right: 10px;
        }

        svg.hangup-icon {
          margin-right: 6px !important;
        }
      }
    }
  }
}

.menu-phone {
  &:hover {
    color: $blue-4;
  }
}

.pt-60 {
  padding-top: 60px !important;
}

.contact-phone-number-search {
  .vbt-autocomplete-list {
    .list-group-item {
      padding: 0.75rem;
    }
  }
}

.call-connected {
  border: 2px solid lighten($primary, 10%);

  &-text {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
  }
}

.contact-task-item {

  &.item-call-parked,
  &.item-call-connected {
    background: $light-blue4 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }

  .call-parked-label {
    font-size: 12px;
    color: $purple-100;
  }

  .call-connected-label {
    font-size: 12px;
    color: $primary;
  }
}

.call-fishing-clear-queues {
  top: -32px;
}

.disabled-user-status {
  background: #747981 !important;
}

.incoming-call-notification {
  .message-text:not(.has-ring-group) {
    max-width: 162px;
  }
}

.border-radius-1 {
  border-radius: 5px;
}

.border-radius-2 {
  border-radius: 10px;
}

.border-radius-3 {
  border-radius: 15px;
}

.border-radius-4 {
  border-radius: 20px;
}

.daterange-picker-highlighted .reportrange-text {
  border: 2px solid $primary !important;
}

.live-call-badge {
  top: 1px !important;
  right: 4px;
  height: 8px;
  width: 2px;
}

.item-live-call + .item-live-call {
  border-bottom: 2px solid $grey-60 !important;
}

.h-1-livebar {
  height: calc(100vh - 21px);
  min-height: unset !important;
}

.h-2-livebar {
  height: calc(100vh - 46px);
  min-height: unset !important;
}

.mobile-header-labels {
  display: flex;
  min-width: 0;

  .contact-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.mobile-header {
  .menu-avatar-wrapper {
    padding-right: 2.5px !important;
  }
}

.btn-light {
  border-color: $grey-light;
}

.q-avatar {
  .avatar-initials {
    height: 7px;
    font-weight: 500;
  }
}

.q-tooltip {
  z-index: 9999;
}

.ring-group-snapshot-table {
  box-shadow: none !important;
}

.ring-group-snapshot-card,
.communication-details-card {
  border-radius: 8px !important;
  background: $white !important;
  color: $grey-100;
  border: 1px solid rgba(120, 140, 140, 0.13);

  .q-card {
    border: 1px solid rgba(120, 140, 140, 0.13);
  }

  hr:not(.q-separator) {
    border-top: 1px solid rgba(120, 140, 140, 0.13);
  }
}

.communication-details-card {

  .record-was-deleted-label {
    margin-top: 1rem !important;
    line-height: 1.2em !important;
    display: block;
  }

  .header-line {
    margin-bottom: 33px !important;
  }

  .comm-description-card {
    padding-top: 0 !important;
  }

  .comm-type-container {
    padding-bottom: 20px !important;
  }

  .comm-type-wrapper {
    margin-top: 1.4px;

    span {
      margin-left: 11px;
    }
  }

  .form-row {
    padding: 10px 0;
  }

  hr:not(.has-margin) {
    margin: 0 !important;
  }

  .q-card__section {
  }

  .q-item__label:not(.custom-item-label) {
    margin-top: 0.25rem !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .q-item__label.custom-item-label {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}


.br-8 {
  border-radius: 8px;
}

.text-dark-greenish {
  color: #00702f !important;
}

@media (max-width: 767px) {
  .ring-group-snapshot-wrapper {
    padding-left: 0 !important;
    margin-top: 10px;
  }
}

@media (min-width: 401px) {
  .communication-details-card {
    .header {
      .header-btn-wrapper {
        .report-issue-wrapper {
          margin-right: 5px !important;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .communication-details-card {
    .header {
      flex-direction: column !important;

      .header-title {
        text-align: center;
      }

      .header-btn-wrapper {
        margin-top: 5px;
        flex-direction: column !important;

        button {
          margin-top: 5px;
        }
      }
    }
  }
}

.min-w-0 {
  min-width: 0;
}

.d-grid {
  display: grid;
}

.truncated-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.grid-2-col {
  &.task-item-body {
    grid-template-columns: 26px 1fr;
  }
}

.park-call-icon {
  margin-top: -3px;
}

.lh-27 {
  line-height: 27px;
}

.mobile-live-call-bar {
  .dropdown-menu {
    z-index: 9999;
  }
}

.green-phone,
.purple-phone {
  .q-tab__indicator {
    top: 0 !important;
    max-width: 38px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
  }
}

.green-phone {
  svg {
    path {
      fill: $green-91 !important;
    }
  }

  .q-tab__content {
    color: $green-91 !important;
  }

  .q-tab__indicator {
    background: $green-91 !important;
  }
}

.purple-phone {
  svg {
    path {
      fill: $purple-100 !important;
    }
  }

  .q-tab__content {
    color: $purple-100 !important;
  }

  .q-tab__indicator {
    background: $purple-100 !important;
  }
}

.communication-page {
  .main-content {
    overflow: auto !important;
  }
}

.account-level-notification-tooltip-wrapper {
  position: absolute;
  left: 300px;
  margin-top: -22px;
  z-index: 10;
}

.cursor-default {
  cursor: default !important;
}

.cursor-blocked {
  cursor: not-allowed !important;
}

.template-preview {
  min-width: 15rem !important;
}

.aloicons {
  font-family: $font-family-icons;
  color: #62666E !important;

  &.action-icons {
    font-size: $f-size-h3;
  }
}

button.q-field__focusable-action {
  font-size: 16px;
}


h1.contact-list-name-label {
  width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-contact-sequence-enrol {
  &:hover {
    svg {
      path {
        fill: $white
      }
    }
  }

  svg {
    margin-top: -4px;

    path {
      fill: $primary
    }
  }
}

.contain-text {
  padding-top: 6px !important;
}

.mt-n-5 {
  margin-top: -5px;
}

.contact-info-editable {
  .q-icon {
    color: var(--q-color-primary);
  }

  &.inline-input {
    .q-field__append.q-field__marginal {
      button {
        padding: 0;
        line-height: 0;
      }
    }
  }

  &.inline-input:focus-within {
    .q-field__append.q-field__marginal {
      opacity: 1 !important;

      button {
        padding: 0;
        line-height: 0;
      }
    }
  }
}

.contact-info-popover {
  .q-date {
    min-width: unset;
    box-shadow: unset;

    &__main {
      flex: 10000 1 0% !important;
      padding: 0 !important;
    }

    &__main button.close-button,
    &__years-content button,
    &__months-item button,
    &__view .q-date__navigation .relative-position button {
      .q-btn__wrapper {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 1.9em;
      }
    }

    &__content {
      min-height: unset;
    }

    &__years {
      &-content {
        justify-content: space-between;
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;

        button {
          margin-bottom: 4px;
        }
      }

      &-item {
        button {
          width: 45px;
        }
      }
    }

    &__years-content button,
    &__months-item button,
    &__view .q-date__navigation .relative-position button {
      padding-left: 4px;
      padding-right: 4px;
    }

    &__main,
    &__content,
    &__view {
      padding: 0 !important;
    }
  }
}

.session-integration-title {
  color: #303133
}

.power-dialer-list {
  height: calc(100% - 100px);
  overflow-y: auto;

  .sublists {
    overflow-y: auto !important;
  }
}

.modal-pd-add {
  width: 420px;
  max-width: 90vw;
}

.hubspot-crm-iframe {
  overflow: hidden;
}

#pro-feature-alert {
  .modal-header {
    border: none;
  }
}

.hyperlink-color {
  color: $blue-5;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-15 {
  line-height: 1.5 !important;
}

.no-bg {
  background: none !important;
}

// Calendar
.calendar {
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 0px 20px;
    z-index: 1000;

    &__action-left,
    &__action-right {
      height: 60px;
      flex-grow: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .q-field {
      margin-bottom: 0px !important;
    }

    .q-field__control,
    .q-field__native {
      min-height: 31px !important;

      &::before {
        border: solid 1px #d8d8d8;
      }
    }

    .date-time-picker {
      .arrow-month {
        padding-top: 5px;
      }
    }

    @include screen('lg') {
      flex-direction: row;

      &__action-left {
        justify-content: left;
        width: 50%;
      }

      &__action-right {
        justify-content: right;
        width: 50%;
      }
    }
  }

  &__helper {
    &__popover {
      .popover-header {
        background-color: #fff;
        border-bottom: none;
        font-weight: 500;
      }
    }
  }

  .scheduler {
    background-color: #fff;

    &__header {
      z-index: 910;
      position: relative;
      background-color: #fff;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;

      &__table {
        table-layout: fixed;
        text-align: center;
        width: calc(100% - 10px);
        z-index: 910;
        background: transparent 0 0;
        display: table;

        &--week {
          width: calc(100% - 50px);
          margin-left: auto;
        }

        td {
          border: 0;
          font-size: 13px;
          line-height: 1.25;
          padding-top: 0.8rem;
          height: 57px;

          .day-of-week {
            text-transform: uppercase;
            display: block;
            color: rgba(0, 0, 0, .8);
          }

          .day {
            display: block;
            font-size: 18px;
            font-weight: 300;
            color: rgba(0, 0, 0, .54);
          }

          &.today {
            .day-of-week {
              color: #2D96F3;
            }

            .day {
              color: #2D96F3;
            }
          }
        }
      }
    }

    &__body {
      z-index: 901;
      margin-top: -22px;
      position: relative;
      background-color: #fff;

      .actual-scheduler {

        .dhx_before .dhx_month_head,
        .dhx_after .dhx_month_head {
          font-weight: 300 !important;
        }

        .dhx_cal_tab.active {
          color: #fff;
          background-color: #2196f3;
        }

        .dhx_cal_date {
          text-align: left !important;
          margin-left: 1rem;
        }

        .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab {
          box-shadow: none !important;
          border: 1px solid #dcdfe6;
          color: #606266;
          vertical-align: middle;
          line-height: 30px;
          text-transform: none !important;
        }

        .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.dhx_cal_tab_first {
          border-right: 0;
          border-radius: 4px 0 0 4px;
        }

        .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.dhx_cal_tab_last {
          border-left: 0;
          border-radius: 0 4px 4px 0;
        }

        .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.active {
          color: #fff;
        }

        .dhx_cal_nav_button {
          color: #606266;
          border-radius: 4px;
          text-transform: none !important;
        }

        .dhx_cal_today_button.dhx_cal_nav_button {
          border: 1px solid #dcdfe6;
          color: #606266;
          vertical-align: middle;
          line-height: 32px;
          margin-right: 1rem !important;
          border-radius: 4px;
        }

        .dhx_cal_prev_button::after,
        .dhx_cal_next_button::after {
          color: #232323 !important;
        }

        .dhx_cal_event {
          .dhx_header,
          .dhx_body,
          .dhx_footer,
          .dhx_title {
            background-color: transparent !important;
            cursor: pointer;
          }
        }

        .dhx_cal_scale_placeholder,
        .dhx_cal_header {
          display: none !important;
          visibility: hidden;
        }

        .dhx_month_head {
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }

        .dhx_now > .dhx_month_head {
          color: #2196f3;
        }

        .dhx_cal_data td:hover {
          .dhx_month_head,
          .dhx_month_body {
            background: rgba(#00BF50, 0.05);
          }
        }

        .dhx_event_move.dhx_title {
          padding: 0;
          display: none;
        }

        .dhx_cal_event .dhx_body,
        .dhx_cal_event.dhx_cal_select_menu .dhx_body {
          padding: 0;
          height: fit-content !important;
        }

        .dhx_event_resize.dhx_footer {
          display: none;
        }

        .is_past {
          cursor: pointer;
        }

        .dhx_cal_event {
          cursor: pointer !important;
        }

        .dhx_cal_data table tr {
          border-left: 0;
          border-right: 0;

          td:last-child {
            .dhx_month_body, .dhx_month_head {
              border-right: 0;
            }
          }

          td:first-child {
            .dhx_month_body, .dhx_month_head {
              border-left: 0;
            }
          }
        }

        .dhx_cal_data {
          border-left: 1px solid #dcdfe6;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          .dhx_scale_holder {
            overflow-x: auto;
            overflow-y: hidden;
          }
        }

        .dhx_month_link {
          text-align: center;
          color: #62666E;
          font-size: 11px;
          margin-top: 5px;

          .custom-more-link {
            color: inherit;
            text-decoration: none;
            width: 100%;

            &:hover {
              text-decoration: underline;
            }
          }

          .custom-expand-link {
            color: inherit;
            text-decoration: none;
            width: 100%;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .dhx_cal_event,
        .dhx_cal_event_clear {
          font-size: 13px;
          border-radius: 6px;
        }

        .dhx_month_head {
          text-align: right;
          font-weight: normal;
        }

        .dhx_scale_hour {
          display: flex !important;
          align-items: flex-start;
          justify-content: center;
          font-weight: 400 !important;
        }

        .dhx_cal_today_button.dhx_cal_nav_button,
        .dhx_cal_nav_button.dhx_cal_nav_button_custom.dhx_cal_tab.btn-goto-date,
        .dhx_cal_prev_button.dhx_cal_nav_button,
        .dhx_cal_next_button.dhx_cal_nav_button,
        .dhx_cal_date,
        .dhx_cal_tab.dhx_cal_tab_first,
        .dhx_cal_tab.dhx_cal_tab_first.active,
        .dhx_cal_tab,
        .dhx_cal_tab.dhx_cal_tab_last {
          display: none !important;
        }

        .dhx_multi_day {
          margin-right: 0 20px 0 0 !important;
        }

        .dhx_more {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .hour-label {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .time-column {
          width: 50%;
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }

        .current-time {
          border-right: 1px solid #dcdfe6;
        }

        .event-content {
          .event-time {
            font-size: 10px;
          }

          .event-text {
            font-size: 12px;
            font-weight: 700;
          }
        }
      }

      .dhx_scheduler_week {
        .dhx_cal_data {
          .dhx_scale_holder {
            > div {
              min-width: fit-content;
            }
          }
        }
      }

      .dhx_cal_event, .dhx_cal_event_clear {
        border: 1px solid white;
      }

      .day {
        margin-top: -24px;
        z-index: 901;

        .actual-scheduler {
          height: calc(100vh - 90px);
        }
      }

      .hour-label {
        text-decoration: none;
        width: 100%;
        cursor: pointer;

        &.header-row {
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }

  #dd-views {
    color: $danger;
  }

  .b-overlay-wrap {
    z-index: 1005;
  }

  .event {
    &-content {
      padding: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-title {
      font-weight: bold;
    }

    &-time {
      font-size: 0.8em;
    }
  }
}

#calendar-manager-modal {
  .form-control {
    &:disabled {
      background-color: inherit;
    }
  }

  .q-field {
    &--disabled {
      input:disabled {
        display: none;
      }
    }
  }

  .multiselect {
    &__content-wrapper {
      border: none !important;
    }
  }
}

/* Event Colors */
.event-color-type-12 {
  &.status-11 {
    color: $upcoming-appointment;
    &.is_past {
      color: $upcoming-appointment-is-past;

      &:hover {
        color: $upcoming-appointment-is-past-hover;
      }
    }

    &:hover {
      color: $upcoming-appointment-hover;
    }
  }

  &.status-9 {
    color: $completed-event;
    &.is_past {
      color: $completed-event-is-past;

      &:hover {
        color: $completed-event-is-past-hover;
      }
    }

    &:hover {
      color: $completed-event-hover;
    }
  }

  &.status-10 {
    color: $canceled-appointment;
    &.is_past {
      color: $canceled-appointment-is-past;

      &:hover {
        color: $canceled-appointment-is-past-hover;
      }
    }

    &:hover {
      color: $canceled-appointment-hover;
    }
  }

  &.status-14 {
    color: $missed-appointment;
    &.is_past {
      color: $missed-appointment-is-past;

      &:hover {
        color: $missed-appointment-is-past-hover;
      }
    }

    &:hover {
      color: $missed-appointment-hover;
    }
  }
}

.event-color-type-13 {
  &.status-8 {
    color: $upcoming-reminder;
    &.is_past {
      color: $upcoming-reminder-is-past;

      &:hover {
        color: $upcoming-reminder-is-past-hover;
      }
    }

    &:hover {
      color: $upcoming-reminder-hover;
    }
  }

  &.status-4 {
    color: $completed-event;
    &.is_past {
      color: $completed-event-is-past;

      &:hover {
        color: $completed-event-is-past-hover;
      }
    }

    &:hover {
      color: $completed-event-hover;
    }
  }

  &.status-5 {
    color: $canceled-reminder;
    &.is_past {
      color: $canceled-reminder-is-past;

      &:hover {
        color: $canceled-reminder-is-past-hover;
      }
    }

    &:hover {
      color: $canceled-reminder-hover;
    }
  }

  &.status-3 {
    color: $missed-appointment;
    &.is_past {
      color: $missed-appointment-is-past;

      &:hover {
        color: $missed-appointment-is-past-hover;
      }
    }

    &:hover {
      color: $missed-appointment-hover;
    }
  }
}

.event-bg-color-type-12 {
  color: #ffffff !important;

  &.status-11 {
    background-color: $upcoming-appointment;
    &.is_past {
      background-color: $upcoming-appointment-is-past;

      &:hover {
        background-color: $upcoming-appointment-is-past-hover;
      }
    }

    &:hover {
      background-color: $upcoming-appointment-hover;
    }
  }

  &.status-9 {
    background-color: $completed-event;
    &.is_past {
      background-color: $completed-event-is-past;

      &:hover {
        background-color: $completed-event-is-past-hover;
      }
    }

    &:hover {
      background-color: $completed-event-hover;
    }
  }

  &.status-10 {
    background-color: $canceled-appointment;
    text-decoration: line-through;
    &.is_past {
      background-color: $canceled-appointment-is-past;

      &:hover {
        background-color: $canceled-appointment-is-past-hover;
      }
    }

    &:hover {
      background-color: $canceled-appointment-hover;
    }
  }

  &.status-14 {
    background-color: $missed-appointment;
    &.is_past {
      background-color: $missed-appointment-is-past;

      &:hover {
        background-color: $missed-appointment-is-past-hover;
      }
    }

    &:hover {
      background-color: $missed-appointment-hover;
    }
  }
}

.event-bg-color-type-13 {
  color: #ffffff !important;

  &.status-8 {
    background-color: $upcoming-reminder;
    &.is_past {
      background-color: $upcoming-reminder-is-past;

      &:hover {
        background-color: $upcoming-reminder-is-past-hover;
      }
    }

    &:hover {
      background-color: $upcoming-reminder-hover;
    }
  }

  &.status-4 {
    background-color: $completed-event;
    &.is_past {
      background-color: $completed-event-is-past;

      &:hover {
        background-color: $completed-event-is-past-hover;
      }
    }

    &:hover {
      background-color: $completed-event-hover;
    }
  }

  &.status-5 {
    background-color: $canceled-reminder;
    text-decoration: line-through;
    &.is_past {
      background-color: $canceled-reminder-is-past;

      &:hover {
        background-color: $canceled-reminder-is-past-hover;
      }
    }

    &:hover {
      background-color: $canceled-reminder-hover;
    }
  }

  &.status-3 {
    background-color: $missed-appointment;
    &.is_past {
      background-color: $missed-appointment-is-past;

      &:hover {
        background-color: $missed-appointment-is-past-hover;
      }
    }

    &:hover {
      background-color: $missed-appointment-hover;
    }
  }
}

.btn-calendar-prev-next {
  height: 32px;
  width: 32px;
}

.btn-calendar-today {
  height: 32px;
}

.sending {
  background-color: #E5EEFF !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pe-none {
  pointer-events: none;
}

#ss-messenger-button {
  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  pointer-events: none;
  height: 49px;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.new-car-container {
  width: 600px;
  max-width: 600px;
  max-height: 576px;
  height: 576px;

  .container-cars {
    overflow-y: scroll;
    max-height: 400px;
  }

  .infinite-scroll-loading {
    height: 50px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.h-93 {
  height: 93% !important;
}

.h-100 {
  height: 100% !important;
}

.vh-100 {
  height: 100vh;
}

#daytime-hours-confirmation___BV_modal_outer_ {
  z-index: 9999 !important;
}

.t-session-settings {
  &__actions button {
    font-size: 1em !important;
  }
}

.dial-sessions__form {
  &__script-selector.populated:not(.q-field--highlighted) input {
    display: none;
  }
}

.centered-contact-deleted {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.communication-overlay {
  position: inherit !important;
}

.agents-status-pills {
  width: 100%;
  display: flex;

  &__pill {
    color: $white;
    margin-left: 10px;
    display: flex;
    align-items: center;
    border: none;

    &__counter {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      font-size: 10px;
      font-weight: 600;
      padding: 0 2px;
      color: $white;
      height: 16px;
      margin-right: 5px;
    }

    &:hover {
      color: $white;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.wallboard {
  height: 100%;

  &__sidebar {
    min-width: 200px;
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px;
    color: $grey-90;

    &__title {
      font-size: 13px;
      font-weight: 700;
      margin: 20px 10px 5px 10px;
    }

    &__item {
      height: 36px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 8px;
      text-decoration: none !important;
      margin-bottom: 5px;
      border-radius: 5px;

      &__icon {
        margin: 0px 5px;
      }

      &__name {
        flex-grow: 1;
        font-weight: 400;
        font-size: 14px;
        color: $grey-92;
      }

      &--active {
        background-color: $grey-50;
      }
    }
  }

  &__header {
    height: 100px;
    display: flex;
    flex-direction: column;

    &__title {
      height: 40px;
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 0px 15px;
    }

    &__actions {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      color: $grey-92;

      .ring-group-filter {
        max-width: 300px;
      }

      .form-group {
        margin-bottom: 0px;

        span {
          font-size: 14px;
        }
      }
    }
  }

  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition: all 1s;
    overflow-y: hidden;
    flex-grow: 1;

    .overview {
      width: 100%;
      display: flex;
      flex-direction: column;

      // taller
      &--comfort {
        .overview__body__card {
          width: 20%;

          &__container {
            flex-direction: column;
            justify-content: space-between;

            &__icon {
              margin-bottom: 20px;
            }
          }
        }
      }

      // wider
      &--compact {
        .overview__body__card {
          width: calc(100% / 3);

          &__container {
            flex-direction: row;

            &__icon {
              display: flex;
              align-items: center;
            }

            &__body {
              padding-left: 20px;
            }
          }
        }
      }

      &__header {
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px;
      }

      &__body {
        flex-grow: 1;
        border-radius: 15px;
        border: 1px solid $grey-60;
        display: flex;
        flex-wrap: wrap;
        margin: 0px 20px;
        padding: 20px;

        &__card {
          display: flex;
          padding: 10px;
          transition: all 1s;
          color: $grey-100;

          &__container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            border: 1px solid $grey-60;
            border-radius: 10px;
            padding: 20px;

            &__body {
              &__value {
                font-size: 22px;
                font-weight: bold;
              }
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .users {
      color: $grey-92;

      &--compact {
        .users {
          &__table {
            table.datatable {
              td {
                padding-top: 2px;
                padding-bottom: 2px;
              }
            }
          }
        }
      }

      &__header {
        display: flex;
        padding: 10px 20px;

        &__status {
          border: solid 1px $grey-light;
          height: 32px;
          border-radius: 0.25rem;
          margin-left: 10px;
          min-width: 120px;

          .q-field {
            font-size: 12px;

            &__native,
            &__control {
              color: $grey-11;
              min-height: 32px;
            }
          }
        }
      }

      &__table {
        table.datatable {
          td {
            transition: all .5s;
          }

          tbody {
            td {
              padding-left: 28px;
            }
          }
        }

        &__agent-name {
          a {
            color: $grey-92;
            text-decoration: none !important;
          }
        }

        &__agent-status {
          &__button {
            color: $white;
            transition: all .3s;
          }

          &__popover {
            &__header {
              color: $grey-92;
              font-size: 13px;
              margin-bottom: 10px;
            }

            &__body {
              color: $grey-92;
              font-size: 13px;
              display: flex;
              flex-direction: column;

              span {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                padding: 4px;

                &:hover {
                  background-color: $light-grey;
                }
              }
            }
          }
        }

        &__agent-ring-groups {
          &__ring-group-name {
            text-overflow: ellipsis;
            max-width: 270px;
            white-space: nowrap;
            overflow: hidden;
            float: left;
          }

          .popover-body {
            color: $grey-92;
            font-size: 13px;
          }
        }
      }
    }

    .calls {
      width: 100%;
      color: $grey-92;

      &--compact {
        .calls {
          &__table {
            table.datatable {
              td {
                padding-top: 2px;
                padding-bottom: 2px;
              }
            }
          }
        }
      }

      &__header {
        display: flex;
        padding: 10px 20px;

        &__columns-dropdown {
          .dropdown-item {
            padding-bottom: 0px;
          }
        }
      }

      &__table {
        table.datatable {
          td {
            transition: all .5s;
          }

          tbody {
            td {
              padding-left: 28px;
            }
          }
        }

        &__collapse {
          i {
            transition: all .5s;
          }

          &--collapsed {
            i {
              transform: rotate(90deg);
            }
          }
        }

        &__contact {
          &__tags {
            white-space: normal !important;
            word-break: break-word;
            font-size: 12px;
          }
        }

        &__user {
          white-space: normal !important;
          word-break: break-word !important;
        }

        &__tags {
          white-space: normal !important;
        }

        &__notes {
          textarea {
            word-break: break-word !important;
          }
        }

        &__operations {
          display: flex;
        }

        .relative-time {
          font-size: 13px !important;
          color: $grey-92;
        }
      }
    }
  }

  .q-basic-selector {
    margin-bottom: 0px;
  }
}

.chip-ellipsis {
  .b-overlay {
    & > div {
      border-radius: 10px;
    }
  }
}

.top-0 {
  top: 0;
}

.text-decoration-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &.text-red-130 {
    &:hover {
      color: $red-130;
    }
  }
}

.view-filter-name {
  &.row.q-input .q-field__control .q-field__native {
    padding-top: 14px;
    padding-bottom: 2px
  }
}

.compliance-badge {
  background-color: #f44336;
  color: white;
  padding: 8px;
  border-radius: 8px;
  height: unset !important;
}

.tags {
  &__modal {
    .modal-header,
    .modal-footer {
      border: none
    }

    .modal-body {
      padding-bottom: 0
    }
  }

  &__tabs {
    &.q-btn-group--spread {
      & > .q-btn-item {
        flex: inherit;
        flex-grow: 1;
        min-width: 200px;
      }
    }
  }

  &__helper {
    &__popover {
      .popover-header {
        background-color: #fff;
        border-bottom: none;
        font-weight: 500;
      }
    }
  }

  &__form {
    .color-picker {
      .dropdown-toggle::after {
        margin-left: 0.5em;
        vertical-align: 0.5em;
      }

      ul.dropdown-menu {
        text-align: center;
        min-width: 20rem;

        li {
          display: inline-block;
        }
      }

      a.dropdown-item {
        padding: 0
      }

      span.color-pick.selected::after {
        content: "\2713";
        color: #fff;
        font-size: medium;
        vertical-align: super;
        margin-left: -25px;
        padding-right: 10px;
      }
    }
  }
}

.tree-selector {
  &.vue-treeselect--focused {
    .vue-treeselect__control {
      border-color: $primary !important;
      border-width: 2px;
      box-shadow: none !important;
      transform: scale3d(1, 1, 1)
    }
  }

  .vue-treeselect__control {
    height: 40px;
    padding: 2px 5px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.64);
    border: 1px solid rgba(0, 0, 0, 0.24);

    &.vue-treeselect--open {
      border-color: rgba(0, 0, 0, 0.24);
    }

    .vue-treeselect__single-value {
      font-size: 14px;
      color: $black;
    }
  }

  .vue-treeselect__menu {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.80);

    .vue-treeselect__list-item {
      transition: color 0.3s, background-color 0.3s;

      .vue-treeselect__option {
        padding: 3px 5px;
        border-radius: 5px;
        margin: 2px 0;

        &.vue-treeselect__option--selected {
          background: $primary;
          color: $white;
          font-weight: normal;
        }

        &.vue-treeselect__option--highlight:not(.vue-treeselect__option--selected) {
          background: $grey-60;
          color: $grey-100;
        }

        .vue-treeselect__label {
          white-space: inherit;
        }
      }
    }

  }
}

.g-recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
}

.broadcasts {
  &-cancel-button {
    border: 1.6px solid #D8D8D8
  }

  &__home {
    overflow-y: scroll;

    &__loading-overlay {
      position: fixed !important;
      width: calc(100% - 64px);
      height: calc(100% - 62px);
      top: 62px;
      left: 64px;
      z-index: 1029;
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      row-gap: 10px;

      &__search {
        order: 2;
      }

      &__status {
        order: 1;
        flex-grow: 1;
        width: 100%;
        overflow-x: auto;

        .custom-toggle-button {
          min-width: 450px;
        }
      }

      &__new-button {
        order: 3;
        display: flex;
        justify-content: flex-end;
      }

      @media(min-width: 1024px) {
        &__search {
          order: 1;
        }

        &__status {
          order: 2;
          width: auto;
          flex-grow: 0;
          margin-bottom: 0px;
          width: 50%;
        }
      }
    }

    &__graph {
      padding: 0px 10px;

      .highcharts-scrollbar {
        display: none;
      }

      @media print {
        .activity-graph {
          background-color: white;
          height: 100vh;
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1051;

          &__header {
            .chart-types {
              display: none !important;
            }
          }

          .highcharts-container {
            svg {
              width: 100vw !important;

              .highcharts-legend-item {
                text {
                  font-size: 13px !important;
                }
              }
            }
          }
        }
      }
    }

    &__table {
      .broadcast-status-pill {
        border: solid black 1px;

        &.new {
          border-color: $primary;
        }

        &.enrolling,
        &.sending {
          border-color: $indigo;
        }

        &.paused {
          border-color: $warning;
        }

        &.sent {
          border-color: $green;
        }

        &.stopped {
          border-color: $danger;
        }
      }

      .datatable {
        padding-left: 0 !important;

        .sorted-column {
          padding-left: 28px !important;
        }

        .relative-time {
          color: inherit;
        }

        tr {
          min-height: 50px;
          height: 50px;

          .context-menu {
            &:not(.keep-visible) {
              visibility: hidden;
            }

            .dropdown-toggle::after {
              display: none;
            }

            button,
            .show button {
              background-color: $grey-light2 !important;
              border: $grey-light4 solid 1px !important;
            }

            button:focus {
              box-shadow: 0 0 0 0.2rem $grey-light3 !important;
            }
          }

          &:hover .context-menu {
            visibility: visible;
          }
        }

        tr:hover,
        tr:hover td {
          background-color: #F4F4F6 !important;
        }

        td.datatable-row__checkbox {
          padding-right: 0 !important;

          .custom-checkbox-container .checkmark {
            left: 10px;
          }
        }
      }

      &--empty {
        min-height: 100px;
        width: 100%;
        height: 100%;
        top: 35px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        z-index: 0;
      }
    }
  }

  &__add {
    display: flex;
    height: 100%;
    background-color: $grey-40;
    padding: 15px;

    &__sidebar {
      width: 200px;
      margin-right: 15px;
      background-color: $white;
      border-radius: 8px;
      padding: 20px;

      @media (min-width: 1425px) {
        width: 300px;
      }
    }

    &__contacts-filters {
      transition: all 0.5s;
      position: absolute;
      right: 0px;
      margin-top: -15px;
      width: 300px;

      @media (min-width: 1425px) {
        width: 400px;
      }
    }

    &__view {
      max-width: calc(100% - 550px);
      flex-grow: 1;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      @media (min-width: 1425px) {
        max-width: calc(100% - 750px);
      }

      @media (min-width: 1900px) {
        max-width: 1000px;
      }

      &__form {
        transition: all .5s;
        margin-bottom: 15px;
        background-color: $white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        &__header {
          height: 50px;
          border-bottom: 1px solid $grey-60;
          display: flex;
          align-items: center;
          font-weight: bold;
          padding: 0px 30px;
        }

        &__content {
          background-color: $white;

          .broadcast-add {
            padding: 15px 30px;
            height: 100%;

            &__contacts {
              &__options {
                display: flex;

                &__option {
                  flex-grow: 1;
                  text-align: center;
                  height: 70px;
                  border-radius: 6px;
                  border: solid 1px $grey-light;
                  display: flex;
                  align-items: center;
                  margin: 0px 5px;
                  padding-left: 20px;
                  cursor: pointer;
                  transition: all .3s;
                  position: relative;

                  &__icon {
                    position: absolute;
                    top: 7px;
                    right: 7px;
                    border-radius: 50%;
                    background-color: $primary;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  &--active {
                    border-color: $primary;
                  }

                  &--disabled {
                    cursor: not-allowed;
                    border-color: $grey-light3;
                    color: $grey-light3;
                  }
                }
              }

              &__selection {
                margin-top: 25px;
              }

              // &__filters {
              //   transition: all 0.5s;
              //   position: fixed;
              //   right: 0px;
              //   top: 60px;
              //   width: 300px;

              //   @media (min-width: 1425px) {
              //     width: 400px;
              //   }
              // }
            }

            &__message {
              &__sms {
                margin: 20px 0px;

                &__composer-header,
                &__composer-footer {
                  margin: 5px 0px;
                }

                &__composer-body {
                  position: relative;
                  border: 1px solid $grey-70;
                  border-radius: 8px;
                  padding: 10px 12px;
                }

                &__composer-header {
                  text-align: right;
                }

                &__composer-footer {
                  display: flex;
                  justify-content: space-between;
                }

                &__label-preview {
                  color: $grey-90;
                  margin: 20px 0px;
                }

                &__preview {
                  background-color: $primary;
                  border-radius: 8px;
                  padding: 10px;
                  transition: all .3s;
                  min-height: 58px;

                  textarea {
                    color: $white;
                  }

                  &--empty {
                    background-color: $grey-70;
                  }

                  &__image {
                    display: block;
                    margin-bottom: 10px;
                  }
                }
              }

              &__rmv {
                margin-top: 20px;

                &__file-upload {
                  display: flex;
                  column-gap: 20px;
                }

                &__file-preview {
                  position: relative;
                  background-color: $grey-40;
                  border-radius: 10px;
                  padding: 25px 50px;

                  &__remove-icon {
                    cursor: pointer;
                    position: absolute;
                    top: -10px;
                    right: -10px;

                    svg {
                      background-color: $grey-90;
                      padding: 3px;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }

            &__schedule {
              display: flex;
              flex-direction: column;

              &__row {
                display: flex;
                margin-bottom: 30px;

                &__label {
                  width: 30%;
                  color: $grey-90;
                }

                &__fields {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  max-width: 70% !important;

                  .time-schedule {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    row-gap: 15px;

                    &__row {
                      display: flex;
                      align-items: center;

                      &__label {
                        color: $grey-90;
                        width: 50px;
                      }

                      &__field {
                        flex-grow: 1;

                        .multiselect__placeholder {
                          width: 100%;
                        }

                        &__time-selector {
                          position: relative;

                          .pickers-container {
                            .time-picker {
                              flex-wrap: nowrap;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &__preview {
              display: flex;
              flex-direction: column;
              row-gap: 30px;

              &__row {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                &__label {
                  color: $grey-90;
                }

                &__field {
                  &__sms-preview {
                    background-color: $primary;
                    border-radius: 8px;
                    padding: 10px;
                    transition: all 0.3s;

                    textarea {
                      color: $white;
                    }
                  }

                  &__rvm-preview {
                    background-color: $grey-40;
                    border-radius: 10px;
                    padding: 25px 50px;
                  }
                }
              }
            }
          }
        }

        &__footer {
          padding: 30px;
          text-align: right;
          display: flex;
          justify-content: flex-end;
        }
      }

      &__details {
        flex-grow: 1;
        border-radius: 8px;

        .contacts-preview {
          height: 100%;
          position: relative;
          background-color: $white;
          border-radius: 8px;

          &__header {
            height: 50px;
            border-bottom: 1px solid $grey-60;
            display: flex;
            align-items: center;
            font-weight: bold;
            padding: 0px 30px;

            &__counter {
              margin-left: 20px;
              color: $grey-90;

              &--dnc {
                color: $red-90;
                margin-left: 5px;
              }
            }
          }

          &__body {
            .b-overlay-wrap {
              display: none;
            }

            .datatable {
              margin-bottom: 60px;
            }
          }
        }

        .cards {
          position: relative;

          &__body {
            display: flex;
            column-gap: 10px;

            &__card {
              background-color: $white;
              border-radius: 6px;
              flex-grow: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              max-width: 50%;

              &__title {
                font-size: 18px;
                color: $black;
                margin-bottom: 5px;
                font-weight: 500;
              }

              &__text {
                color: $grey-90;
              }
            }
          }
        }
      }
    }
  }
}

.broadcast-proxy-modal-title {
  font-size: 18px;
  font-weight: bold;
}

.steps-wrapper {
  display: flex;
  flex-direction: column;

  &__step {
    color: $grey-90;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
    font-weight: bold;

    &__icon {
      border: solid 1px $grey-70;
      margin-right: 15px;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__separator {
      position: absolute;
      background-color: $grey-70;
      height: 24px;
      width: 2px;
      left: 12px;
      top: 32px;
    }


    &--active {
      .steps-wrapper__step {
        &__icon {
          background-color: $success;
          color: $white;
        }

        &__name {
          color: $black;
        }
      }
    }

    &--completed {
      .steps-wrapper__step {
        &__icon {
          background-color: $success;
          color: $white;
        }

        &__name {
          color: $black;
        }

        &__separator {
          background-color: $success;
        }
      }
    }
  }
}

.steps-wrapper-horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__step {
    color: $grey-90;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-weight: bold;
    flex: 1;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      background-color: $grey-70;
      height: 2px;
      width: calc(100% - 4em);
      top: 20%;
      left: 50%;
      margin-left: 2em;
      transform: translateX(0%);
    }

    &:last-of-type::after {
      display: none;
    }

    &__icon {
      border: solid 1px $grey-70;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      z-index: 1;
    }

    &--active .steps-wrapper-horizontal__step__icon {
      background-color: $success;
      color: $white;
    }

    &--completed .steps-wrapper-horizontal__step__icon {
      background-color: $success;
      color: $white;
    }

    &--completed::after {
      background-color: $success;
    }
  }
}

.activity-graph {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    margin-top: 10px;

    @media(min-width: 785px) {
      flex-direction: row;
      margin-top: 0px;
    }
  }
}

.download-dropdown {
  .q-btn-dropdown__arrow {
    display: none;
  }
}

#maitre-inline-button {
  border: 1px solid #D8D8D8 !important;
  text-transform: none !important;
  padding: 8px 14px 7px 10px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

#maitre-inline-button::after {
  content: "\1F381";
  padding-left: 6px;
}

.account-registration {
  display: flex;
  height: 100vh;

  // Base Styles
  .row.q-input .q-field__control .q-field__native {
    height: 35px !important;
  }

  .q-field--auto-height .q-field__control,
  .q-field--auto-height .q-field__native {
    min-height: 35px !important;
  }

  .toggle-border {
    border: 1px solid #256eff;
  }

  .q-stepper__dot {
    height: 32px !important;
    width: 32px !important;
  }

  .stepper__container {
    display: flex;
    text-align: center;
    text-align: -webkit-center;
  }

  .text-h4 {
    font-size: 2.5rem;
  }

  @include screen('xs') {
    .text-h4 {
      font-size: 20px;
    }
  }

  @include screen('sm') {
    .text-h4 {
      font-size: 20px;
    }
  }

  @include screen('md') {
    .text-h4 {
      font-size: 2.5rem;
    }
  }

  .text-h5 {
    text-align: start;

    font-size: 1.25rem !important;
  }

  .text-body1 {
    font-size: 1.25rem;
    margin-top: 28px;
    width: 82%;
  }

  @include screen('xs') {
    .text-body1 {
      font-size: 14px;
      margin-top: 10px;
      width: 100%;
    }
  }

  @include screen('sm') {
    .text-body1 {
      font-size: 14px;
      margin-top: 10px;
      width: 100%;
    }
  }

  @include screen('md') {
    .text-body1 {
      font-size: 1.25rem;
      margin-top: 28px;
      width: 82%;
    }
  }

  .password-hint {
    ul {
      padding-top: 10px;
      list-style-type: none;
    }

    li {
      list-style: none;
      font-size: 14px;
      color: #000000;
      margin-top: 4px;
    }
  }

  .zipcode-hint {
    padding-top: 0;
    padding-left: 10px;
    font-size: 14px;
    color: #000000;
    text-align: left;
  }

  .negative-top {
    margin-top: -10px;
  }

  .banner {
    align-items: center;
    background: linear-gradient(240deg, #0037ff2a 0%, #22ff001c 100%);
    height: 100vh;

    .card {
      background-color: #ffffff;
      border-radius: 12px;

      > h3 {
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      > p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0 24px 10px 24px;
      }
    }
  }

  &-form-container {
    // Base styles for form-container can be added here
  }

  &-action-btn {
    .row.q-field,
    .row.q-field__control-container,
    .row.q-field__control,
    .row.q-notification__wrapper,
    .row.q-notification__content,
    .row.q-btn__wrapper,
    .row.q-btn__content,
    .row.q-avatar__content,
    .row.q-item,
    .row.q-field__native,
    .row.q-chip__content,
    .row .q-field__inner {
      padding-right: 6px !important;
      padding-left: 6px !important;
      display: flex !important;
      align-content: center !important;
    }
  }

  // Stepper Container Details
  .stepper__container {
    display: flex;
    flex-direction: column;
    height: 90vh !important;

    .q-stepper__header,
    .stepper__content__header {
      position: relative;
      top: 0;
      z-index: 10;
      background-color: white;
    }

    .stepper__content {
      overflow-y: auto;
      flex-grow: 1;
      height: 56vh;
    }

    @media (min-height: 720px) {
      .stepper__content {
        height: 50vh;
      }

      .stepper__container {
        height: 70vh !important;
      }
    }

    @media (min-height: 800px) {
      .stepper__container {
        height: 90vh !important;
      }
    }

    @media (min-height: 900px) {
      .stepper__content {
        height: 56vh;
      }
    }

    .business-information__form {
      // overflow-y: auto;
      flex-grow: 1;
      height: 65vh;
    }

    @media (min-height: 720px) {
      .business-information__form {
        height: 55vh;
      }
    }

    @media (min-height: 900px) {
      .business-information__form {
        height: 72vh;
      }
    }

    .business-information__container {
      // overflow-y: auto;
      flex-grow: 1;
      height: 60vh;
    }

    @media (min-height: 480px) {
      .business-information__container {
        height: 42vh;
      }
    }

    @media (min-height: 620px) {
      .business-information__container {
        height: 50vh;
      }
    }

    @media (min-height: 720px) {
      .business-information__container {
        height: 55vh;
      }
    }

    @media (min-height: 900px) {
      .business-information__container {
        height: 65vh;
      }
    }

    @media (min-height: 1024px) {
      .business-information__container {
        height: 68vh;
      }
    }

    .q-stepper__nav {
      position: sticky;
      bottom: 0;
      background-color: white;
      padding: 0 16px 16px;
      z-index: 10;

      @include screen('xs') {
        padding: 0 16px 0;
      }

      @include screen('sm') {
        padding: 0 16px 0;

      }
    }
  }

  ::-webkit-scrollbar {
    width: 9px !important;
    max-height: 397px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #256EFF !important;
    border-radius: 6px !important;

    &:hover {
      background-color: #256EFF !important;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #E0E0E0 !important;
    border-radius: 6px !important;
  }

  @include screen('xs') {
    .banner {
      display: none !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;

      .q-stepper__header,
      .stepper__content__header {
        max-width: 100%;
        position: relative;
        z-index: 10;
        background-color: white;
        font-size: 10px
      }
    }
  }

  @include screen('sm') {
    .banner {
      display: none !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;

      .stepper__container {
        display: flex;
        text-align: -webkit-center;

        .q-stepper__header,
        .stepper__content__header {
          max-width: 100%;
          position: relative;
          z-index: 10;
          background-color: white;
          font-size: 10px;
        }
      }
    }
  }

  @include screen('md') {
    .banner {
      display: none !important;
    }

    .min-w-100 {
      // width: 100vh !important;
    }

    .q-stepper__header {
      max-width: 600px !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;
      padding-left: 4vh;
    }
  }

  @include screen('lg') {
    .banner {
      display: none !important;
    }
  }

  @include screen('xl') {
    .banner {
      display: flex !important;
    }
  }

  @include screen('xs') {
    .q-stepper__header {
      max-width: 100%;
    }
    .stepper__container {
      display: flex;
      text-align: -webkit-center;
    }
  }

  @include screen('sm') {
    .stepper__container {
      display: flex;
      text-align: -webkit-center;
    }
  }

  @include screen('md') {
    .min-w-100 {
      // width: 100vh !important;
    }

    .q-stepper__header {
      max-width: 600px !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;
      padding-left: 4vh;
    }
  }

  // Miscellaneous Classes
  .w-65 {
    width: 65%;
  }

  .min-h-60 {
    min-height: 60vh;
  }

  .terms-iframe {
    border: 0;
    width: 100%;
    height: 30vh; /* Default iframe height */
    padding-bottom: 20px;
  }

  @include screen('xs') {
    .terms-iframe {
      height: 30vh;
    }
  }

  @include screen('sm') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  @include screen('md') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  @include screen('lg') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  .accept-box {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    padding-right: 2.8rem;

    .carrier-fees {
      position: relative;
      left: -8.1%;
    }
  }

  .h-55 {
    height: 55vh;
  }

  .h-75 {
    height: 75%;
  }

  .q-mx-6 {
    margin-left: 3.3rem !important;
    margin-right: 3.3rem !important;
  }

  .logo {
    z-index: 11;
  }

  .phone-row {
    display: flex;
    height: 35px;
    width: 100%;

    > .col-md-4 {
      flex-grow: 1;
    }
  }

  #recaptcha-element {
    position: relative;
    left: -12%;
  }

  .q-field--disabled
  .q-field__control > div,
  .q-field--disabled
  .q-field__control > div * {
    background: #D8D8D8;
    color: #040404;
    border-radius: 20px;
  }

  .q-field--outlined.q-field--with-bottom.disabled {
    > div {
      background: #D8D8D8;
      color: #040404;
      border-radius: 20px;
      opacity: 100%
    }
  }

  .form-actions {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: -14px;
    justify-content: flex-end;
    width: 100%;
  }

  .business-information-actions {
    display: flex;
    position: absolute;
    top: calc(100vh - 20vh);
    width: 100%;
    padding: 0px 5rem;
  }

  @media (min-height: 480px) {
    .business-information-actions {
      top: calc(100vh - 36vh);
    }
  }

  @media (min-height: 620px) {
    .business-information-actions {
      top: calc(100vh - 32vh);
    }
  }

  @media (min-height: 720px) {
    .business-information-actions {
      top: calc(100vh - 30vh);

      .business-information-actions {
        top: calc(100vh - 22vh);
        height: 65vh;
      }
    }

    @media (min-height: 1024px) {
      .business-information-actions {
        top: calc(100vh - 16vh);
        height: 68vh;
      }
    }

    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .loading-icon {
      width: 250px;
      height: 250px;
    }

    .loading-text {
      font-size: 20px;
      font-weight: 500;
    }
  }

  @media (min-height: 900px) {
    margin-top: 20px;
  }
}

// .trial-banner {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 20px;
//   background-color: #256EFF1F;
//   height: 55px;

//   .trial--text {
//     margin: 0px;
//     font-size: 16px;
//     font-weight: 700 !important;
//     line-height: 19px;
//     letter-spacing: 0em;
//     text-align: left;
//     margin-right: 30px;
//   }

//   .button-index {
//     z-index: 1 !important;

//     .block {
//       padding-right: 16px;
//       padding-left: 16px;
//     }

//     & > a {
//       color: #256EFF;
//       font-size: 14px;
//       line-height: 19px;
//       letter-spacing: 0em;
//       text-align: center;
//       text-decoration: none;

//       &:hover {
//         color: #256EFF;
//         text-decoration: underline;
//       }
//     }
//   }

//   .demo--button {
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     font-size: 14px;
//     font-weight: 500;
//     letter-spacing: 0em;

//     > img {
//       margin-right: 10px;
//     }
//   }
// }

.trial-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #256EFF1F;

  .left-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .trial--text {
    margin: 0;
    font-size: 1rem;
    font-weight: 700 !important;
    margin-right: 2rem;
  }

  .button-index {
    z-index: 1 !important;
    margin-right: 1rem;

    .q-btn__wrapper {
      margin-bottom: 3px;
    }

    .block {
      padding-right: 20px;
      padding-left: 20px;
      min-width: 200px;
    }

    & > a {
      color: #256EFF;
      font-size: 0.875rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .demo--button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;

    > img {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 1280px) {
    .trial-banner {
      flex-direction: column;
      align-items: flex-start;
    }

    .left-content {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    }

    .left-content > .trial--text {
      flex: 1 0 100%;
    }
  }
}

.tags-deprecation-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #FFFFE7;
  border: #F2C94D 1px solid;
  border-radius: 5px;

  .left-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .trial--text {
    margin: 0;
    font-size: 0.8rem;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1280px) {
    .trial-banner {
      flex-direction: column;
      align-items: flex-start;
    }

    .left-content {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    }

    .left-content > .trial--text {
      flex: 1 0 100%;
    }
  }
}

.video-modal-section {
  .activator {
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 34px;
      border-radius: 4px;
      border: none;
      background-color: rgba(37, 255, 72, 0.12) !important;
      cursor: pointer;

      &:hover {
        background-color: rgba(37, 255, 73, 0.15) !important;
      }
    }

    &-icon {
      flex-shrink: 0;
      color: #00A04F;
      width: 24px;
      height: 24px;
    }

    &-outline__button {
      border-radius: 4px;

      .q-btn__wrapper {
        padding-left: 6px;
        padding-right: 6px
      }
    }
  }

  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    padding: 40px
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000003b;
    cursor: pointer;
  }

  .modal-content {
    position: relative;
    width: 80%;
    max-width: 800px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 20px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 10px 15px;
  }

  .modal-header h3 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 18px; /* Smaller font size */
    font-weight: 300; /* Lighter font weight */
    line-height: 1;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }

  .close-button:hover {
    color: #555; /* Slightly darker on hover for feedback */
  }

  .modal-body {
    padding: 0;
  }

  .modal-body iframe {
    width: 100%;
    height: 50vh;
    border: none;
  }

  .modal-notes {
    padding: 15px;
    font-size: 1em;
    color: #666;
    font-weight: normal;
    text-align: center;
  }

  .modal-link {
    text-align: center;
    color: var(--Primary-Blue, #256EFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.transcription {
  .speaker--title {
    font-weight: 500;
    font-size: 14px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #256EFF !important;
    border-radius: 6px !important;

    &:hover {
      background-color: #256EFF !important;
      border-radius: 6px !important;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #E0E0E0 !important;
    border-radius: 6px !important;
  }

  .chat-area {
    background: whitesmoke;
    max-height: 64vh;
    padding: 1em;
    margin: 0 auto 2em auto;
    overflow: auto;
    flex-grow: 1;

    .sentiment {
      &-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6px;
      }

      &-description {
        font-size: 12px;
        font-weight: 700;
      }
    }

    .message-box {
      width: 75%;
      border-radius: 10px;
      padding: .5em;
      font-size: 14px;
      margin: 0.5em 0;
      color: black;
      background-color: #85858511;

      &-out {
        color: black !important;
      }

      &-in {
        color: black;
        margin-left: 26% !important;
      }
    }
  }

  .q-chip {
    height: 100%;
    max-height: 34px;
  }

  .q-chip__content {
    .white-color {
      color: white;
    }
  }

  .link {
    color: blue;
  }
}

.loading-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loading-icon {
  width: 250px;
  height: 250px;
}

.loading-text {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .intercom-lightweight-app {
    display: none !important;
  }
}

.trial-expired-icon {
  margin: 2rem;
  color: #d0d0d0;
  font-size: 120px
}

thead.conflicted-contact-thead-class tr th {
  border-top: none;
  border-bottom-width: 1px;
}

.center-modal-header .modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn-tiny, .btn-group-tiny > .btn {
  padding: 2px 5px;
  font-size: 6px;
  line-height: 1.5;
}

.scrollable-tags-area {
  overflow-y: auto;
}

.dark-tooltip {
  background-color: #161922;
  color: #fff;
  font-size: 12px;
}

.custom-date-time-picker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

#start-date-time-picker-wrapper, #end-date-time-picker-input {
  margin-right: 5px;
}

#start-date-time-picker-input, #end-date-time-picker-input {
  min-height: 32px;
  height: 32px;
  border-radius: 24px;
}

label[for="start-date-time-picker-input"], label[for="end-date-time-picker-input"] {
  top: -4px !important;
}

.btn-custom-date {
  display: flex;
}

.btn-custom-date .btn-apply {
  margin-right: 10px;
}

.time-picker-column {
  height: 100%;
}

@media (max-width: 768px) {
  .custom-date-time-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #start-date-time-picker-input, #end-date-time-picker-input {
    margin-bottom: 10px;
  }
}

.power-dialer-session-toggle-button {
  z-index: 1;
  margin-top: -14px !important;
  margin-bottom: -12px !important;
}

.select-campaign-dialog {

  .label {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    margin-bottom: 2px;
  }

  .q-dialog__backdrop {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .q-dialog__inner > div {
    border-radius: 8px 8px 0 0;
  }

  .card {
    width: 375px;
    min-height: 130px;
    padding: 36px 36px 0 36px;
  }

  .q-btn {
    border-radius: 6px;
    height: 40px;
    margin-left: 16px;
  }

  .q-btn__content {
    padding-right: 14px;
    padding-left: 14px;
    text-transform: capitalize;
  }

  .line-selector {
    width: 75%;
  }

  .inline-select.q-select--with-input {
    min-width: 75% !important;
  }
}

.login-form-logo {
  max-width: 200px;
  height: auto;
}

.hubspot-widget-error-details {
  color: #000;
  padding: 0 12px;
  h4 {
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }
  p {
    margin: 0 0 1rem;
    font-size: 0.95rem;
  }
}

.broadcast-warning-note {
  font-size: 12px;
  border-radius: 4px;
  margin-top: 16px;
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  flex: 1 1 100%;

  &--warning {
    color: #7e641d;
    background-color: #fcf2d7;
    border-color: #fbedc7;
  }

  &__title {
    color:#a17d1c;
    font-weight: bold;
    margin-bottom: 6px
  }
}

.contact-activity-container {
  .contact-activities-hs-widget {
    min-height: 250px;

    @media (max-height: 500px) {
      height: 250px !important;
    }
  }
}

.activity-bottom-info-hs-widget {
  min-height: 30px;
  height: auto;
  max-height: 100%;
  overflow-x: auto;
  overflow-y: auto;

  span {
    @media (max-width: 500px) {
      white-space: normal;
    }
  }
}

.contact-view-wrapper-hs-widget {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;

  @media (max-width: 400px) {
    width: 400px !important;
    overflow-x: auto;
  }
}

.text-menu-purple {
  color: #9797AE;
}

.search-icon-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.limit-characters-error {
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  margin-top: -8px;
  margin-bottom: 2px;
}

.search-error-icon {
  color: red;
  display: inline-block;
  transform: scale(1.5);
  vertical-align: middle;
}

.search-error-text {
  position: relative;
  top: 2px;
  font-size: 80%;
}

.form-control-search-component.is-invalid {
  padding-right: 0 !important;
}

.select-account {
  &--dialog {
    min-height: 200px;

    &.fullscreen {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .logo-image {
      max-width: 200px;
      max-height: 50px;
      height: auto;
    }
  }

  &--icon {
    margin-right: 6px !important;
  }
}

.rounded-circle-dot {
  width: 16px !important;
  height: 16px !important;
  border-radius: 100px !important;
}

hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}


.external-contact-integration-link-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.duplicateContactPhoneNumberMessage {
  color: $grey-90;
}

.integration-jit-card-header {
  margin: 12px;
}

.bulk-action-menu-dropdown {
  font-size: .75rem;

  svg {
    margin-top: 0 !important;
  }
}

.bulk-action-menu-dropdown-btn {
  outline: none;
  border: 1px solid transparent;
  font-size: 0.75rem;
}

.calendar-event-list {
  &-modal-title {
    font-size: .875rem;
    font-weight: 500;
  }

  &-modal {
    .q-dialog__inner--minimized > div {
      max-width: 660px !important;
    }
  }
}

.preview-card-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2em;
  max-width: 325px;
}

.preview-card-box .preview-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.preview-card-box .icon-circle {
  background-color: #007bff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.preview-card-box .icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9em;
}

.w-100 {
  width: 100%;
}

.gap-2 {
  gap: 0.5rem;
}

/* Container for the entire effect */
.ai-effect-container {
  position: relative;
  border-radius: 0.5rem; /* rounded-lg */
  overflow: hidden;
}

/* Gradient background */
.ai-effect-gradient {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to bottom right, #60A5FA, #A855F7, #EC4899); /* from-blue-400 via-purple-500 to-pink-500 */
  opacity: 0.75;
}

/* Blur effect */
.ai-effect-blur {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(4px); /* backdrop-blur-sm */
}

/* Content container */
.ai-effect-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9); /* bg-white bg-opacity-90 */
  margin: 2px;
  border-radius: 0.3rem; /* rounded-lg */

  ul, ol {
    padding-inline-start: 16px;
  }

  h3 {
    font-size: 13px;
  }
}

/* Gradient text effect for inbox */
.inbox-effect-gradient-text {
  cursor: pointer;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  background-image: linear-gradient(135deg, #9031B5, #256EFF, #0E8EBB) !important;
}

.q-message-text-content {
  line-height: 1.5;
  font-size: 13px;

  ul, ol {
    padding-inline-start: 16px;
  }

  p {
    margin: 0 0 px;
  }

  a {
    font-weight: 600;
  }
}

/* Gradient input */
.ai-effect-gradient-input {
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom right, #60A5FA, #A855F7, #EC4899) border-box;
}

.ai-info-box {
  position: absolute;
  top: 125px;
  right: 5px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, #9333ea, #6b46c1);
  color: white;

  .ai-info-box-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .ai-info-box-icon {
      font-size: 24px;
      margin-right: 10px;
    }

    .ai-info-box-title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ai-info-box-content {
    margin-bottom: 10px;

    strong {
      font-weight: bold;
    }
  }

  .ai-info-box-links {
    a {
      color: #ffe4e6;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.transcription-link {
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.transcription-link:hover {
  color: $dark;
  font-weight: 600;
}

.transcription-message {
  font-size: 10px;
  font-weight: 600;
}

.transcription-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Gradient text effect */
.ai-effect-gradient-text {
  cursor: pointer;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #2563EB, #9333EA); /* from-blue-600 to-purple-600 */
}

.outbound-line-selector {
  max-width: 460px !important;
}

.tooltip {
  .tooltip-inner {
    background-color: $grey-light11 !important;
    font-size: 12px !important;
    color: black !important;
  }

  .arrow::before {
    border-bottom-color: $grey-light11 !important;
    opacity: 0 !important;
  }
}

.text-decoration-none {
  text-decoration: none !important;
}

.no-p-margin p {
  margin-bottom: 0px !important;
}

.talk-table-container {
  padding: 24px 0 0 0;
  flex: 1;
  min-width: 0;
  width: inherit;
  overflow-y: auto;

  .talk-table {
    margin-top: 20px;
    max-height: calc(100% - 110px);

    tr th {
      position: sticky;
      z-index: 2;
      background: #fff;
    }

    thead tr:last-child th {
      top: 48px;
      z-index: 3;
    }

    thead tr:first-child th {
      top: 0;
      z-index: 3;
    }

    tr:first-child th:last-child {
      z-index: 3;
    }

    tr:nth-of-type(odd) {
      background-color: $light-grey;
    }

    tr:nth-of-type(even) {
      background-color: #fff;
    }

    tr:hover {
      background-color: $light-blue;
    }

    tr.live-call-tr {
      background-color: $light-blue;
    }

    td:last-child {
      z-index: 1;
    }

    td:last-child, th:last-child {
      position: sticky;
      right: 0;

      @media (max-width: 575px) {
        position: relative;
      }
    }

    tbody {
      scroll-margin-top: 48px;
    }

    td {
      padding: 8px 0;
      border: none;

      & *.d-flex {
        row-gap: 8px;
      }
    }

    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;

      &:hover {
        background-color: #555;
      }
    }

    &-pagination {
      margin-bottom: 24px !important;
      margin-right: 8px !important;
    }

    &-per-page-select {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    .disposition-icon {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      padding: 6px;

      &[class*="completed"],
      &[class*="answered"] {
        background-color: rgba(#00BF4A, 0.1);
      }

      &[class*="missed"],
      &[class*="failed"],
      &[class*="voicemail"]{
        background-color: rgba(#FA003F, 0.1);
      }

      &[class*="abandoned"] {
        background-color: rgba(#9C27BB, 0.1);
      }

      &[class*="inprogress"] {
        background-color: rgba(#4450C0, 0.1);
      }

      &[class*="callback-pending"] {
        background-color: rgba(156,39,187, 0.1);
      }

      &[class*="deadend"] {
        background-color: rgba(#F6D047, 0.1);
      }
    }

    .operation-button {
      border: solid 1px #D8D8D8;
      border-radius: 6px;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1px;

      & span {
        margin: 0 2px;
      }
    }

    .accordion {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 0;
      height: 28px;
      overflow: hidden;
      transition: width 0.3s ease;
    }

    .rotate-180 {
      transform: rotate(180deg);
    }

    .q-table__linear-progress {
      color: #256eff !important;
    }

    &--no-data {
      left: 0;
      position: absolute;
      width: 100%;
      margin-top: 300px;
      text-align: center;
      z-index: 0;
    }

    .deleted, a.deleted {
      color: #666666;
      font-style: italic;
    }

    .contact-name {
      font-weight: 500;
      color: #256eff;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .count {
    font-size: 14px;
    font-weight: 400;
  }

  .filters {
    margin-top: 14px;
    display: flex;
    align-items: center;
    width: 100%;

    .search {
      width: 50%;
    }

    .setting {
      display: flex;
      justify-content: flex-end;
      width: 50%;
    }
  }

  .status-icon {
    display: flex;
    align-items: center;
    gap: 4px;

    i {
      font-size: 20px;

      &.material-icons {
        &.text-indigo-500 { color: #6366f1; }
        &.text-green-500 { color: #22c55e; }
        &.text-purple-500 { color: #a855f7; }
        &.text-red-500 { color: #ef4444; }
        &.text-red-A400 { color: #ff1744; }
        &.text-orange-500 { color: #f97316; }
      }
    }
  }

  .teams-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .team-item {
      margin-bottom: 2px;
      font-size: 14px;
    }

    .see-more {
      cursor: pointer;
      margin-top: 2px;
      font-weight: 600;
      > button > span {
        text-transform: capitalize !important;
      }
    }
  }

  .q-table__card {
    box-shadow: none !important;
  }

  .q-table__control {
    display: none !important;
  }
}

.talk-table__tooltip {
  .tooltip-inner {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 2px 8px !important;
  }

  &--md {
    .tooltip-inner {
      max-width: 350px;
    }
  }
}

.talk-table__popover {
  background-color: #000;
  color: #fff;
  font-size: 13px;

  &[x-placement="bottom"] {
    .arrow::before, .arrow::after {
      border-bottom-color: #000;
    }
  }

  &[x-placement="top"] {
    .arrow::before, .arrow::after {
      border-top-color: #000;
    }
  }

  &[x-placement="left"] {
    .arrow::before, .arrow::after {
      border-left-color: #000;
    }
  }

  &[x-placement="right"] {
    .arrow::before, .arrow::after {
      border-right-color: #000;
    }
  }

  .popover-body {
    color: #fff;
  }

  &--long-text {
    white-space: pre-line;
    word-break: break-word;

    .popover-body {
      margin-top: -20px;
    }
  }
}

.column-headers-modal {
  width: 900px !important;
  max-width: 90vw !important;

  .columns-container {
    .columns-list {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      height: 400px;
      overflow-y: auto;

      &.available {
        background-color: #f5f5f5;
      }
    }

    .selected-column-item,
    .available-column-item {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      background-color: white;

      &:last-child {
        border-bottom: none;
      }

      &:not(.fixed-column) {
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }

      .drag-icon {
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.54);
      }

      .column-name {
        flex: 1;
        font-size: 14px;
      }

      .remove-icon {
        color: rgba(0, 0, 0, 0.54);

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
      }

      &.fixed-column {
        background-color: #fafafa;
        cursor: not-allowed;

        .drag-icon {
          opacity: 0.5;
        }

        .column-name {
          opacity: 0.7;
        }
      }
    }

    .available-column-item {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .q-icon {
        color: rgba(0, 0, 0, 0.54);

        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }

  .selected-column-item {
    transition: transform 0.2s ease, margin 0.2s ease;

    &.dragging {
      opacity: 0.5;
      background: #f5f5f5;
    }

    &.drag-over-top {
      transform: translateY(10px);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--q-primary);
        border-radius: 1px;
      }
    }

    &.drag-over-bottom {
      transform: translateY(-10px);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--q-primary);
        border-radius: 1px;
      }
    }

    &:not(.fixed-column) {
      cursor: move;

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }

    &:not(.fixed-column) {
      cursor: move;

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }
  }

  .no-columns-available {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 599px) {
  .column-headers-modal {
    width: 500px !important;

    .columns-list {
      height: 300px !important;
    }
  }
}

.communication-details-sidebar {
  .b-sidebar {
    top: 60px;
    height: calc(100vh - 110px); // Mobile default: 60px for top + 50px for bottom

    @media (min-width: 785px) {
      height: calc(100vh - 60px); // Desktop: only account for top bar
    }

    @media (min-width: 1280px) {
      width: 480px;
    }
  }

  // fix sidebar when the notification banner is visible
  body.has-notification-banner & {
    .b-sidebar {
      @media (min-width: 785px) {
        top: 110px;
        height: calc(100vh - 110px);
      }
    }
  }

  .communication-details-card {
    .comm-type-container {
      &.sticky-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        margin-bottom: .25rem;
        border-bottom: 1px solid #EBEBEB;
      }
    }
  }
}

.blue-scroll {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: all 0.2s ease;

  &:hover {
    scrollbar-color: $blue transparent;
  }
}

.lists-management {
  .talk-table {
    max-height: calc(100% - 136px);

    td {
      padding: 8px 16px;
    }
  }

  .dropdown-btn-text {
    font-size: 12px;
    font-weight: 400;
  }

  #cols-actions {
    max-width: initial !important;
  }

  .loading-spinner {
    margin-top: -80px;
  }

  .scrollableArea .datatable {
    z-index: 4;
  }
}

.attempting-users-container {
  height: 65px;
  overflow: hidden;
  position: relative;

  .show-more-icon {
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -15px;
    color: #555;
    background: #f9f9f9;
    padding: 1px 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0, .2)
  }

  &:hover {
    overflow: visible;
    z-index: 2;

    .expand-on-hover {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0,0,0, .2)
    }

    .show-more-icon {
      display: none;
    }
  }
}